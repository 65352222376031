import isNil from 'lodash/isNil';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import { formatRatio } from 'UI/utils';

import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from '../constants';

import { createQueryParams } from './utils';

export const getProductivitySummary = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivitySummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getProductivityTeam = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityTeam}?${queryParams}`
  ).then(({ data }) => data.shift());
};
export const getRecruiterInfo = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityRecruiter}?${queryParams}`
  ).then(({ data }) => data.shift());
};

export const getWeekProductivity = async ({ groupBy = 'team', recruiter, team } = {}) => {
  const defaultParams = {
    groupBy,
    metrics: 'sendouts,sendovers,sentFeeAgreements,signedFeeAgreements',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityWeek}?${queryParams}`
  ).then(({ data }) => {
    const { goals, stats } = Object.keys(data).reduce(
      (acc, key) => {
        if (key.includes('Goal')) {
          acc.goals[key] = data[key];
        } else {
          acc.stats[key] = data[key];
        }
        return acc;
      },
      { goals: {}, stats: {} }
    );

    return Object.keys(stats).map(key => {
      const value = Number(stats[key]);
      const goal = goals[`${key}Goal`];
      return {
        title: strings.commandCenter.metricsTitles[key],
        value: !isNil(goal) ? formatRatio(value, goal, 'scalar') : value
      };
    });
  });
};
