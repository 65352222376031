// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';

import { LABEL_COLORS_AVAILABLE, POSITIONS, SIZES } from './StastsItem.constants';
import { useStyles } from './styles';

export interface StatsItemProps {
  size?: 'large' | 'medium' | 'small';
  subtitle?: string;
  title?: string;
  value: string | number;
  valueColor?: 'black' | 'green' | 'yellow' | 'red' | 'blue' | 'purple';
  valuePosition?: 'top' | 'bottom';
}

export const StatsItem = ({
  size = SIZES.large,
  subtitle,
  title,
  value,
  valueColor = LABEL_COLORS_AVAILABLE.black,
  valuePosition = POSITIONS.bottom
}: StatsItemProps) => {
  const classes = useStyles({ size, valueColor, valuePosition });

  const valueLabel = <Typography className={classes.valueLabel}>{value}</Typography>;

  return (
    <div>
      {valuePosition === POSITIONS.top && !!title && valueLabel}
      <Typography className={classes.titleLabel}>{title}</Typography>
      {valuePosition === POSITIONS.bottom && !!title && valueLabel}
      {subtitle && <Typography className={classes.subtitleLabel}>{subtitle}</Typography>}
    </div>
  );
};
