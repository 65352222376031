// @flow
import React from 'react';
import { THEME as theme } from 'GlobalStyles';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { useUserContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { canUserEditEntity, userHasRoles } from 'services/Authorization';
import strings from 'strings';
import FPChip from 'UI/components/atoms/FPChip';
import { When } from 'UI/components/atoms/When';
import { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import InfoLabelsGrid from 'UI/components/organisms/inventoryProfiles/InfoLabelsGrid';
import InventoryOverviewLayout from 'UI/components/organisms/inventoryProfiles/InventoryOverviewLayout';
import { DateFormats, YesNo } from 'UI/constants/defaults';
import { AutoWidthGrid, SharedFullGridLayout } from 'UI/constants/dimensions';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { CANDIDATES_PROFILE_HASHES } from 'UI/constants/hashIds';
import { Roles } from 'UI/constants/roles';
import { UIStatus } from 'UI/constants/status';
import useFetchCandidateDataSheet from 'UI/pages/EditCandidateDataSheet/hooks/useFetchCandidateDataSheet';
import { currencyFormatter, localTimeFormatter, nestTernary, phoneNumberFormatter } from 'UI/utils';
import { buildLocationInfoLabel } from 'UI/utils/inventory';

import { buildEmailLabels, getEmailValidationAlerts, getPhonesLabelsForProfile } from '../utils';

import ProfileAlert from './ProfileAlert/ProfileAlert';
import { useCandidateScrollToSection } from './CandidateOverview.hooks';
import { DataSheetWrapper } from './components';
import {
  candidateBackgroundLabels,
  establishingUrgencyLabels,
  professionalInsights,
  workPreferencesLabels
} from './labels';
import { useStyles } from './styles';

const {
  shared: {
    fields: { currentCompany: currentCompanyString }
  }
} = strings;

type CandidateOverviewProps = {
  profile?: Object,
  loading: boolean,
  blueSheet?: Object,
  onWriteUpClick: () => {},
  onEditClick: () => void
};

const sharedWorkPreferencesConfig = {
  md: 4,
  sm: 4,
  xs: 6
};

const CandidateOverview = ({
  loading: isProfileLoading,
  profile,
  onWriteUpClick,
  blueSheet,
  onEditClick
}: CandidateOverviewProps) => {
  const classes = useStyles();
  const contactData = {
    phone: profile?.personalInformation?.contact?.phone,
    otherPhone: profile?.personalInformation?.contact?.mobile
  };

  const hasMultiplePhones = !!profile.phone_id;

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const isMultiplePhoneEnabled = checkIfFeatureFlagEnabled(FeatureFlags.CandidatesMultiplePhones);
  const canExecuteCandidateAssignmentSheet = checkIfFeatureFlagEnabled(
    FeatureFlags.CandidateDataSheetFlow
  );

  const [currentUser] = useUserContext();

  const userHasRightsToEdit = canUserEditEntity(currentUser, profile, {
    includeCollaborator: true
  });

  const canEdit = userHasRightsToEdit || userHasRoles([Roles.Operations, Roles.DataCoordinator]);

  const { dataSheet, status } = useFetchCandidateDataSheet(
    canExecuteCandidateAssignmentSheet ? profile.id : null
  );

  const loading = isProfileLoading || status === UIStatus.Loading;
  const success = status === UIStatus.Success;

  useCandidateScrollToSection({ success, loading });

  const legacyPhoneLabels = [
    {
      title: 'Phone - Ext',
      description: ` ext: ${profile?.personalInformation?.contact?.ext ?? ''} `,
      mode: InfoLabelModes.RING_CENTRAL,
      linkProps: {
        url: phoneNumberFormatter(contactData?.phone) ?? '',
        id: profile?.id,
        role: EntityType.Candidate
      }
    },
    {
      title: 'Other Phone',
      mode: contactData?.otherPhone ? InfoLabelModes.RING_CENTRAL : InfoLabelModes.TEXT,
      linkProps: {
        url: phoneNumberFormatter(contactData?.otherPhone) ?? '',
        id: profile?.id,
        role: EntityType.Candidate
      }
    }
  ];

  const generalLabels = [
    {
      title: 'Nickname',
      description: profile?.personalInformation?.nickname
    },
    {
      title: 'Title',
      description: profile?.title
    },
    {
      title: 'Functional Title',
      description: profile?.position?.title
    },
    {
      title: 'Industry · Specialty',
      description: `${profile?.specialty?.industry?.title} · ${profile?.specialty?.title}`
    },
    {
      title: 'Subspecialty',
      description: profile?.subspecialty?.title
    },
    {
      title: currentCompanyString,
      mode: profile?.company_id ? InfoLabelModes.LOCAL_LINK : InfoLabelModes.TEXT,
      description: profile?.current_company,
      itemId: profile?.company_id,
      linkProps: profile?.company_id
        ? {
            url: EntityRoutes.CompanyProfile
          }
        : ''
    },
    buildLocationInfoLabel({
      city: profile?.personalInformation?.address?.city?.title,
      state: profile?.personalInformation?.address?.city?.state?.slug,
      country: profile?.personalInformation?.address?.city?.state?.country?.slug
    }),
    {
      title: 'Zip Code',
      description: profile?.personalInformation?.address?.zip
    },

    {
      title: 'Source',
      description: profile?.sourceType?.title
    },
    {
      title: 'Linkedin Profile',
      mode: InfoLabelModes.EXTERNAL_LINK,
      linkProps: {
        url: profile?.link_profile ?? ''
      },
      config: AutoWidthGrid
    },
    ...buildEmailLabels({ profile, entityType: EntityType.Candidate })
  ];

  const writeUp = blueSheet ?? [{}];
  const lookingFor = writeUp?.time_looking && moment(writeUp?.time_looking).fromNow(true);

  const workPreferences = [
    {
      title: 'Open to relocation',
      description: capitalize(blueSheet?.relocation ? YesNo.Yes : YesNo.No),
      config: { ...sharedWorkPreferencesConfig }
    },
    {
      title: 'Preferred destinations',
      customValueRender: writeUp?.relocations?.map(item => {
        const chipLabel = item?.city?.is_state
          ? item?.city?.title
          : `${item?.city?.title}, ${item?.city?.state?.slug}`;
        return (
          <FPChip
            key={item.id}
            className={classes.writeUpChips}
            textColor={theme.palette.grey[600]}
            label={chipLabel}
          />
        );
      }),
      config: { ...sharedWorkPreferencesConfig }
    },
    {
      title: 'Work preference',
      description: writeUp?.workTypeOption?.title,
      config: { ...sharedWorkPreferencesConfig }
    },
    {
      title: 'High Compensation',
      description: currencyFormatter(writeUp?.good_salary),
      config: { ...sharedWorkPreferencesConfig }
    },
    {
      title: 'Ideal Compensation',
      description: currencyFormatter(writeUp?.no_brainer_salary),
      config: { ...sharedWorkPreferencesConfig }
    },
    {
      title: 'Low Compensation',
      description: currencyFormatter(writeUp?.minimum_salary),
      config: { ...sharedWorkPreferencesConfig }
    }
  ];

  const jobSituationLabels = [
    {
      title: 'Reason for leaving',
      description: writeUp?.reason_leaving,
      cropped: false,
      config: {
        sm: 12,
        md: 12
      }
    },
    {
      title: 'Time Looking for Job',
      description: lookingFor
    },
    { title: 'Ideal time to start', description: writeUp?.timeToStart?.title }
  ];

  const achievementsAndSkillsLabels = [
    {
      title: 'Achievements',
      description: (
        <>
          <span className={classes.achievementText}>
            <strong>1. </strong> {writeUp?.achievement_one}
          </span>
          <span className={classes.achievements}>
            <strong>2. </strong>
            {writeUp?.achievement_two}
          </span>
          <span className={classes.achievementText}>
            <strong>3. </strong>
            {writeUp?.achievement_three}
          </span>
        </>
      ),
      cropped: false,
      config: SharedFullGridLayout
    },
    {
      title: 'Skills',
      description: writeUp?.experience,
      cropped: false,
      config: SharedFullGridLayout
    }
  ];

  const dateInterviewsLabel = [
    {
      description: writeUp?.interview_dates?.[0] ? (
        <>
          <span>
            <strong>1. </strong>
            {localTimeFormatter(writeUp?.interview_dates?.[0], DateFormats.SendoutTemplateDate)}
          </span>
        </>
      ) : null,
      cropped: false,
      config: { sm: 4, md: 4 }
    },
    {
      description: writeUp?.interview_dates?.[1] ? (
        <>
          <strong>2. </strong>
          {localTimeFormatter(writeUp?.interview_dates?.[1], DateFormats.SendoutTemplateDate)}
        </>
      ) : null,
      cropped: false,
      config: { sm: 4, md: 4 }
    },
    {
      description: writeUp?.interview_dates?.[2] ? (
        <>
          <strong>3. </strong>
          {localTimeFormatter(writeUp?.interview_dates?.[2], DateFormats.SendoutTemplateDate)}
        </>
      ) : null,
      cropped: false,
      config: { sm: 4, md: 4 }
    }
  ];

  const phonesLabels = !isMultiplePhoneEnabled
    ? legacyPhoneLabels
    : nestTernary(
        hasMultiplePhones,
        getPhonesLabelsForProfile(profile, EntityType.Candidate),
        legacyPhoneLabels
      );

  const alerts = getEmailValidationAlerts({
    profile,
    loading
  }).map(item => ({
    componentProps: item,
    Component: ProfileAlert
  }));

  const createDefaultCards = () => {
    return [
      ...alerts,
      {
        Component: () => (
          <When condition={!loading}>
            <DataSheetWrapper
              progress={dataSheet?.progress}
              canExecuteCandidateAssignmentSheet={canExecuteCandidateAssignmentSheet}
              onWriteUpClick={onWriteUpClick}
              onEditClick={canEdit && onEditClick}
            />
          </When>
        )
      },
      {
        cardProps: { headerProps: { title: 'General Information' } },
        Component: InfoLabelsGrid,
        componentProps: {
          labels: [...generalLabels, ...phonesLabels],
          loading
        },
        className: 'generalCard'
      },
      {
        cardProps: {
          headerProps: {
            title: 'Work Preferences'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: workPreferences
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Current Job Situation'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: jobSituationLabels
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Achievements & Skills'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: achievementsAndSkillsLabels
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Available Date Interviews'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: dateInterviewsLabel
        }
      },
      writeUp?.notes
        ? {
            cardProps: {
              headerProps: {
                title: 'Additional Details'
              }
            },
            Component: InfoLabelsGrid,
            componentProps: {
              loading,
              isFlexibleGrid: true,
              labels: [
                {
                  description: writeUp?.notes,
                  config: { sm: 12, md: 12, style: { whiteSpace: 'pre-line' } }
                }
              ]
            }
          }
        : {
            cardProps: {
              headerProps: {
                title: 'Additional Details'
              }
            },
            Component: InfoLabelsGrid,
            componentProps: {
              loading,
              labels: [
                {
                  description: '',
                  config: { xs: 12, md: 12 }
                }
              ]
            }
          }
    ];
  };

  const createDataSheetCards = () => {
    return [
      ...alerts,
      {
        Component: () => (
          <When condition={!loading}>
            <DataSheetWrapper
              progress={dataSheet?.progress}
              canExecuteCandidateAssignmentSheet={canExecuteCandidateAssignmentSheet}
              onWriteUpClick={onWriteUpClick}
              onEditClick={canEdit && onEditClick}
            />
          </When>
        )
      },
      {
        cardProps: { headerProps: { title: 'General Information' } },
        Component: InfoLabelsGrid,
        componentProps: {
          labels: [...generalLabels, ...phonesLabels],
          loading
        },
        className: 'generalCard'
      },
      {
        cardProps: {
          headerProps: {
            title: 'Establishing Urgency'
          }
        },
        id: CANDIDATES_PROFILE_HASHES.EstablishingUrgency,
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: establishingUrgencyLabels(dataSheet?.data?.establishingUrgency)
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Candidate Background'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: candidateBackgroundLabels(dataSheet?.data?.candidateBackground)
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Work Preferences'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: workPreferencesLabels(dataSheet?.data?.workPreferences)
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Professional Insights'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: professionalInsights(dataSheet?.data?.professionalInsights)
        }
      },
      {
        cardProps: {
          headerProps: {
            title: 'Notes'
          }
        },
        Component: InfoLabelsGrid,
        componentProps: {
          loading,
          isFlexibleGrid: true,
          labels: [
            {
              title: 'Notes',
              description: dataSheet?.data?.qualification?.notes,
              config: { sm: 9, md: 9, xs: 9, style: { whiteSpace: 'pre-line' } }
            }
          ]
        }
      }
    ];
  };

  const finalCards =
    canExecuteCandidateAssignmentSheet && dataSheet && success
      ? createDataSheetCards()
      : createDefaultCards();

  return <InventoryOverviewLayout items={finalCards} loading={loading} />;
};

CandidateOverview.defaultProps = {
  profile: {},
  blueSheet: {}
};

export default CandidateOverview;
