// @flow
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { THEME as theme } from 'GlobalStyles';
import type { FPIconButtonProps } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import { iconSizes } from 'UI/constants/dimensions';

import ConditionalActionWrapper from '../ConditionalWrapper';

import { defaultStyles, iconDefinitions, useStyles } from './styles';

const {
  customColors: { grey }
} = theme.palette;

const FPIconButton = ({
  arrow,
  children,
  className,
  disabled,
  icon,
  iconMode,
  iconProps,
  size,
  showTooltipWhenDisabled,
  title,
  tooltipPlacement,
  tooltipProps,
  variant,
  ...rest
}: FPIconButtonProps) => {
  const classes = useStyles({ size: iconSizes.iconButon[size] });
  const defaultClasses = defaultStyles({ variant });

  const determineColorByType = type => (iconMode === type ? iconDefinitions.color[variant] : null);

  const iconComponent = (
    <IconButton
      disabled={disabled}
      classes={defaultClasses}
      className={clsx(classes[variant], className)}
      {...rest}
    >
      {children || (
        <FPIcon
          size={
            iconMode === iconDefinitions.mode.streamline
              ? iconSizes.streamline.bold[size]
              : iconSizes.custom[size]
          }
          color={disabled ? grey : determineColorByType(iconDefinitions.mode.streamline)}
          fill={disabled ? grey : determineColorByType(iconDefinitions.mode.custom)}
          component={icon}
          {...iconProps}
        />
      )}
    </IconButton>
  );

  const isTooltipActive = (!disabled || showTooltipWhenDisabled) && !!tooltipProps?.title;

  return (
    <ConditionalActionWrapper
      condition={isTooltipActive}
      wrapper={(c = children) => (
        <Tooltip
          arrow={arrow}
          title={title}
          aria-label={title}
          placement={tooltipPlacement}
          {...tooltipProps}
        >
          <span
            role="button"
            aria-label={title || tooltipProps.title}
            className={classes.buttonWrapper}
          >
            {c}
          </span>
        </Tooltip>
      )}
    >
      {iconComponent}
    </ConditionalActionWrapper>
  );
};

FPIconButton.defaultProps = {
  arrow: true,
  className: undefined,
  disabled: false,
  icon: null,
  iconMode: 'streamline',
  iconProps: undefined,
  size: 'md',
  showTooltipWhenDisabled: false,
  title: '',
  tooltipPlacement: 'bottom',
  tooltipProps: {},
  variant: 'default'
};

export default FPIconButton;
