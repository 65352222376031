import { EntityRoutes } from 'routes/constants';

// TODO: adjust this columns, virtual props, options and filters according the endpoint info
export const COLUMNS = [
  {
    name: 'id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiter',
    label: 'Recruiter',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: false,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          idKey: 'id',
          route: EntityRoutes.CompanyProfile,
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: false,
      display: true,
      renderer: 'status'
    }
  },
  {
    name: 'industry_title',
    label: 'Industry',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'validated_date',
    label: 'Sent at',
    options: {
      sort: false,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: 'MM/DD/YYYY hh:mm A'
        }
      }
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
