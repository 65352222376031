import { Box, styled, Typography } from '@material-ui/core';

export const InlineFiltersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  '& > * > div:not(:has(> div.MuiFormControl-root):has(> div.MuiAutocomplete-root))': {
    margin: '0 !important'
  },
  '& .MuiCustomDatePicker-root': {
    width: 'auto',
    margin: '0 !important'
  }
}));

export const LabelMultipleTags = styled(Typography)(({ theme }) => ({
  // 50px is the width of the clear icon to the right
  width: 'calc(100% - 50px)',
  paddingLeft: theme.spacing(1)
}));
