// @flow

import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';
import { hasFeatureFlag } from 'UI/utils';

import useFetchWithStatus from './fetchWithStatus';

const CONVERSION_ENDPOINT_API_VERSION = 2;

export const useContactConversion = ({ nameId }) => {
  const enableConvertedContacts = hasFeatureFlag(FeatureFlags.ConvertedContacts);

  const conversionStatusEndpoint = enableConvertedContacts
    ? Endpoints.NameConvertedStatus.replace(':id', nameId)
    : null;

  const { state: entityConversionState } = useFetchWithStatus(
    conversionStatusEndpoint,
    null,
    UIStatus.Default,
    CONVERSION_ENDPOINT_API_VERSION
  );

  const { status } = entityConversionState;
  const isLoading = status === UIStatus.Loading;
  const isSuccess = status === UIStatus.Success;

  const shouldRenderProfileContent =
    !enableConvertedContacts || entityConversionState?.results?.converted === false;

  return {
    isLoading,
    isSuccess,
    status,
    entityConversionState,
    shouldRenderProfileContent,
    enableConvertedContacts
  };
};
