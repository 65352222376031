import { EntityRoutes } from 'routes/constants';

// TODO: adjust this columns, virtual props, options and filters according the endpoint info
export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'hiring_authority',
    label: 'Hiring Authority',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'hiringAuthority',
        config: {
          idKey: 'id',
          route: EntityRoutes.HiringAuthorityProfile,
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'job_order',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'event_log',
    label: 'Last Activity',
    options: {
      sort: false,
      display: true,
      renderer: 'smartTagsSendout'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};

export const VIRTUAL_PROPS = [
  {
    propName: 'event_log',
    attributes: ['event', 'event_details', 'event_user_name']
  }
];
