// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import { confirm, showAlert } from 'actions/app';
import {
  hideReferenceReleaseComposer as hideReferenceReleaseComposerAction,
  showReferenceReleaseComposer
} from 'actions/joborder';
import { useDrawer } from 'hooks/drawerHandler';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { useProfile } from 'hooks/profileActions';
import { useSearchProjectInProfiles } from 'hooks/searchProject';
import { useVerifyUrgencyDialogManager } from 'hooks/useVerifyUrgencyDialogManager';
import { useUserContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import {
  canUserEditEntity,
  Permissions,
  userHasPermission,
  userHasRoles
} from 'services/Authorization';
import { CandidateIncludes } from 'services/includes';
import strings from 'strings';
import CandidateEdit from 'UI/components/organisms/CandidateEdit';
import AddHiringAuthority from 'UI/components/organisms/candidates/HiringAuthority/AddHiringAuthority';
import LinkHiringAuthority from 'UI/components/organisms/candidates/HiringAuthority/LinkHiringAuthority';
import ReferenceRelease from 'UI/components/organisms/candidates/ReferenceRelease';
import CandidateSheet from 'UI/components/organisms/CandidateSheet';
import { getSendoutActions } from 'UI/components/organisms/inventoryProfiles/SendoutsTab/sendoutsActions';
import ToggleProfileModeButton from 'UI/components/organisms/inventoryProfiles/ToggleProfileModeButton';
import {
  getOptOutDate,
  handleEntityEditionCompleted,
  handleWriteUpActions
} from 'UI/components/organisms/inventoryProfiles/utils';
import LinkCompanyDialog from 'UI/components/organisms/LinkCompanyDialog';
import ReassignRecruiterForm from 'UI/components/organisms/ReassignRecruiter';
import SendoutManager from 'UI/components/organisms/sendouts';
import InventoryProfileLayout from 'UI/components/templates/InventoryProfileLayout';
import ProfileStatusWrapper from 'UI/components/templates/ProfileStatusWrapper';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  CandidateEntity,
  EntityType,
  entityTypes,
  SearchProjectEntities,
  TabKeys
} from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { UIStatus } from 'UI/constants/status';
import { SvgCheck } from 'UI/res';
import { getErrorMessage, localTimeFormatter, toLocalTime } from 'UI/utils';
import { completeReferenceRelease } from 'UI/utils/inventory';
import { updateUiState } from 'UI/utils/uiHelpers';

import { VerifyUrgencyDialog } from '../../components/molecules/VerifyUrgencyDialog';

import { useCandidateProfileFeatureFlags } from './CandidateProfile.hooks';

type FPCandidateProfileProps = {
  enableUrlNavigation: boolean,
  id: number,
  layoutClassName: Object,
  match: any,
  profileModeProps: Object,
  onEditionCompleted: () => void,
  onVerificationCompleted: () => void
};

const profileCopies = strings.candidates.profile;

const createTabsProps = ({
  blueSheet,
  candidateId,
  endpoint,
  handleRefreshActivityStatus,
  handleSendoutClick,
  handleWriteUpClick,
  isLoading,
  itemName,
  results,
  searchProjectsProps,
  tabKeys,
  triggerTabRefresh,
  uiState
}) => [
  {
    id: TabKeys.Overview,
    tabProps: {
      profile: results,
      onWriteUpClick: handleWriteUpClick,
      blueSheet
    }
  },
  {
    id: TabKeys.JobOrders,
    tabProps: {
      profileId: results?.id,
      isLoading,
      itemName,
      mode: EntityType.Candidate,
      endpoint
    }
  },
  {
    id: TabKeys.Sendouts,
    tabProps: {
      profile: results,
      profileModule: EntityType.Candidate,
      onSendoutClick: handleSendoutClick,
      shouldRefresh: uiState.shouldRefreshTableData
    }
  },
  {
    id: TabKeys.Placements,
    tabProps: {
      profileId: candidateId,
      key: tabKeys.placements,
      search: false,
      profileModule: EntityType.Candidate,
      isLoading,
      onTriggerTabRefresh: () => triggerTabRefresh(TabKeys.Placements)
    }
  },
  searchProjectsProps,
  {
    id: TabKeys.ActivityLogs,
    tabProps: {
      itemName,
      onActivityComplete: handleRefreshActivityStatus,
      profileId: results?.id,
      profileModule: EntityType.Candidate,
      endpoint
    }
  },
  {
    id: TabKeys.Notes,
    tabProps: {
      onActivityComplete: handleRefreshActivityStatus,
      type: 'notes',
      profileId: results?.id,
      itemName,
      search: false,
      endpoint,
      profileModule: EntityType.Candidate
    }
  },
  {
    id: TabKeys.ReferenceCheck,
    tabProps: {
      candidateId,
      candidateName: itemName ?? '',
      recruiter: results?.recruiter,
      additionalRecruiters: results?.additionalRecruiters
    }
  }
];

const FPCandidateProfile = ({
  enableUrlNavigation,
  id,
  layoutClassName,
  match,
  onEditionCompleted,
  onVerificationCompleted,
  profileModeProps
}: FPCandidateProfileProps) => {
  const location = useLocation();

  const candidateId = match?.params?.id || id;
  const [currentUser] = useUserContext();
  const endpoint = `${Endpoints.Candidates}/${candidateId}`;
  const entityType = entityTypes.find(each => each.id === EntityType.Candidate);
  const history = useHistory();
  const editionDrawer = useDrawer(location, history);
  const dispatch = useDispatch();
  const { referenceRelease } = useSelector(state => state.jobOrder);

  const { canExecuteCandidateAssignmentSheet, canDisplayChangeLogs, canUseUrgencyVerification } =
    useCandidateProfileFeatureFlags();

  const {
    isOpen: isOpenVerifyUrgencyDialog,
    closeDialog: closeVerifyUrgencyDialog,
    openDialog: openVerifyUrgencyDialog
  } = useVerifyUrgencyDialogManager();

  /** To keep track of tabs that should refresh after an event.
   * Remember that a component unmount when key changes */
  const [tabKeys, setTabKeys] = useState({
    [TabKeys.Sendouts]: 0,
    [TabKeys.Placements]: 1,
    [TabKeys.SearchProjects]: 2
  });

  const {
    handleEditClick,
    handleEditClosed,
    handleEditCompleted,
    handleProfileStateUpdate,
    handleReassignClick,
    handleReassignClosed,
    handleReassignCompleted,
    isLoading,
    refreshData,
    results,
    setUiState,
    state,
    status,
    uiState
  } = useProfile({
    entity: EntityType.Candidate,
    profileId: candidateId,
    includes: CandidateIncludes,
    shouldLoadTitle: enableUrlNavigation
  });

  const itemName = results?.personalInformation?.full_name;
  const lastActivity = results?.last_activity_date;

  const { blueSheets, relation } = results;
  const blueSheet = blueSheets?.[0];
  const candidateRelatedWithHiringAuthority = relation?.hiring_authority_id || false;

  const handleRefreshActivityStatus = data =>
    handleProfileStateUpdate({ last_activity_date: data?.updated_at });
  const formattedDate = localTimeFormatter(lastActivity, DateFormats.SimpleDateTime);

  const triggerTabRefresh = (nameTab: string) =>
    setTabKeys(prevState => ({ ...prevState, [nameTab]: new Date().getTime() }));

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   P L A C E M E N T   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const referenceReleaseHandler = () => dispatch(hideReferenceReleaseComposerAction());

  const { handleReferenceReleaseComplete } = completeReferenceRelease({
    referenceRelease,
    referenceReleaseHandler
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E A R C H   P R O J E C T S   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const { SearchProjectForms, SearchProjectsMenuItems, searchProjectTabPropsDefinition } =
    useSearchProjectInProfiles({
      endpoint,
      entity: SearchProjectEntities.Candidate,
      profile: {
        id: results?.id,
        name: itemName
      },
      tabKey: tabKeys.searchProjects,
      triggerTabRefresh,
      shouldRedirectToSearchProjectPreview: enableUrlNavigation
    });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E N D O U T S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const {
    handleSendoutClick,
    handleSendoutNavigation,
    handleSendoutCompleted,
    handleSendoutClosed
  } = getSendoutActions({ setUiState, history, triggerTabRefresh });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *  B L U E   S H E E T
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleWriteUpClick = () => {
    if (canExecuteCandidateAssignmentSheet) {
      history.push(EntityRoutes.CandidateDataSheet.replace(':id', candidateId));
      return;
    }
    updateUiState({ isWriteUpReadOnly: false, isWriteUpOpen: true }, setUiState);
  };

  const handleBluesheetClosed = () =>
    uiState.isWriteUpOpen && updateUiState({ isWriteUpOpen: false }, setUiState);

  const handleWriteUpdate = () =>
    handleWriteUpActions({
      onWriteUpClose: handleBluesheetClosed,
      onRefreshData: refreshData
    });

  const tabsProps = createTabsProps({
    blueSheet,
    candidateId,
    endpoint,
    handleRefreshActivityStatus,
    handleSendoutClick,
    handleWriteUpClick,
    isLoading,
    itemName,
    results,
    tabKeys,
    triggerTabRefresh,
    uiState,
    searchProjectsProps: searchProjectTabPropsDefinition
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   R E F E R E N C E   R E L E A S E
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const { state: referenceState, refreshData: refreshReferenceReleases } = useFetchWithStatus(
    !isLoading && results?.id
      ? `${Endpoints.Candidates}/${candidateId}/${Endpoints.ReferenceReleases}`
      : null
  );

  const userHasRightsToEdit = canUserEditEntity(currentUser, results, {
    includeCollaborator: true
  });

  const canEdit = userHasRightsToEdit || userHasRoles([Roles.Operations, Roles.DataCoordinator]);

  const referenceReleasePermission = userHasPermission(Permissions.Placements.CanUseModule);
  const sentEmails = referenceState.results;
  const referenceReleaseStatus = referenceState.status;
  const lastEmailSent =
    referenceReleaseStatus === UIStatus.Success && sentEmails?.length && sentEmails[0];
  const sentDate = lastEmailSent && toLocalTime(lastEmailSent?.created_at);
  const formattedSentDate = sentDate && sentDate.format(DateFormats.SimpleDateTime);
  const canViewIfEmailsExist = !userHasRightsToEdit && sentEmails?.length > 0;
  const showReferenceReleaseButton = userHasRightsToEdit || canViewIfEmailsExist;

  const handleReferenceClick = () =>
    dispatch(
      showReferenceReleaseComposer({
        candidateId,
        onReferenceReleaseComplete: async () => {
          await refreshReferenceReleases();
        }
      })
    );

  const headerProps = {
    title: results?.personalInformation?.full_name,
    sectionName: 'Candidate'
  };

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   LINK HIRING AUTHORITY TO CANDIDATE
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleHiringAuthorityClick = type =>
    setUiState(prevState => ({ ...prevState, [type]: true }));

  const goToHAProfile = idProfile =>
    history.push(EntityRoutes.HiringAuthorityProfile.replace(':id', idProfile));

  const handleAddHiringAuthorityCompleted = async addHiringAuthorityData => {
    setUiState(prevState => ({ ...prevState, isAddHiringAuthorityOpen: false }));
    addHiringAuthorityData && goToHAProfile(addHiringAuthorityData.id);
  };

  const handleLinkHiringAuthorityCompleted = async linkHiringAuthorityData => {
    setUiState(prevState => ({ ...prevState, isLinkHiringAuthorityOpen: false }));
    linkHiringAuthorityData && goToHAProfile(linkHiringAuthorityData.relation.hiring_authority_id);
  };

  const handleHiringAuthorityClosed = type =>
    uiState[type] && setUiState(prevState => ({ ...prevState, [type]: false }));

  const handleUnlinkHiringAuthorityClick = () => {
    const hiringId = relation?.hiring_authority_id;
    const hiringName = relation?.hiringAuthority?.full_name;

    if (hiringId) {
      dispatch(
        confirm({
          severity: 'warning',
          title: strings.inventoryProfiles.sections.candidates.unlinkHiringDialog.title,
          message: strings.formatString(
            strings.inventoryProfiles.sections.candidates.unlinkHiringDialog.message,
            {
              hiringName
            }
          ),
          confirmButtonText: 'Unlink Profiles',
          cancelButtonText: 'Keep Profiles',
          onConfirm: async ok => {
            try {
              if (!ok) return;

              setUiState(prevState => ({ ...prevState, isLoading: true }));
              const response = await API.delete(
                `${Endpoints.Candidates}/${candidateId}/hiring-authorities/${hiringId}/unlink`
              );
              if (response && response.status === 204) {
                dispatch(
                  showAlert({
                    severity: 'success',
                    title: 'Hiring Authority Unlinked Successfully',
                    body: `${hiringName} was unlinked from the Candidate Profile`
                  })
                );

                handleProfileStateUpdate({ relation: null });
              }
            } catch (error) {
              dispatch(
                showAlert({
                  severity: 'error',
                  title: 'Unlink Hiring Authority',
                  autoHideDuration: 5000,
                  body: getErrorMessage(error)
                })
              );
            } finally {
              setUiState(prevState => ({ ...prevState, isLoading: false }));
            }
          }
        })
      );
    }
  };

  const writeUpActions = [
    {
      title: canExecuteCandidateAssignmentSheet
        ? strings.candidates.editDataSheet.contentPageTitle
        : profileCopies.dataSheet.editButton.old,
      action: handleWriteUpClick,
      visible: canEdit
    },
    {
      title: 'Verify Urgency',
      action: openVerifyUrgencyDialog,
      visible:
        canUseUrgencyVerification &&
        userHasRoles([Roles.RegionalDirector, Roles.AssistantRegionalDirector, Roles.Leadership])
    }
  ];

  const customMenuItems = [
    ...SearchProjectsMenuItems,
    {
      title: 'Reference Release',
      action: handleReferenceClick,
      visible: showReferenceReleaseButton && referenceReleasePermission
    },
    {
      title: strings.inventoryProfiles.common.haAddition,
      action: () => handleHiringAuthorityClick('isAddHiringAuthorityOpen'),
      visible: !candidateRelatedWithHiringAuthority
    },
    {
      title: 'Link with existing Hiring Authority',
      action: () => handleHiringAuthorityClick('isLinkHiringAuthorityOpen'),
      visible: !candidateRelatedWithHiringAuthority
    },
    {
      title: 'Unlink Hiring Authority Profile',
      action: () => handleUnlinkHiringAuthorityClick(),
      visible: candidateRelatedWithHiringAuthority
    }
  ];

  const handleRefreshProfile = () => refreshData();

  const { optOutDate } = getOptOutDate(results);

  const summaryLabels = [
    {
      title: strings.inventoryProfiles.common.referenceRelease,
      icon: lastEmailSent && SvgCheck,
      content: lastEmailSent ? `Sent on ${formattedSentDate}` : 'No email sent yet'
    },
    { title: strings.inventoryProfiles.common.optOut.creation, content: optOutDate }
  ];

  return (
    <ProfileStatusWrapper
      status={status}
      responseStatusCode={state.responseStatusCode}
      entityRoute={EntityRoutes.Candidates}
      onRefreshProfileClick={handleRefreshProfile}
      entity={entityType}
      profileStatusMode={{
        loading: (
          <InventoryProfileLayout
            entityType={CandidateEntity}
            contentPageLayoutProps={{ className: layoutClassName }}
            isLoading={isLoading}
            isChangeLogsTabEnabled={canDisplayChangeLogs}
            results={results}
            enableUrlNavigation={enableUrlNavigation}
            ProfileTabsProps={{
              endpoint,
              profileTabProps: tabsProps
            }}
          />
        ),
        success: (
          <>
            <InventoryProfileLayout
              activityLabel={formattedDate}
              contentPageLayoutProps={{ className: layoutClassName }}
              additionalHeaderActions={
                <ToggleProfileModeButton
                  results={results}
                  mode={EntityType.Candidate}
                  {...profileModeProps}
                />
              }
              entityType={CandidateEntity}
              enableUrlNavigation={enableUrlNavigation}
              headerCardProps={headerProps}
              inventoryType={blueSheet?.candidateType}
              isLoading={isLoading}
              isChangeLogsTabEnabled={canDisplayChangeLogs}
              results={results}
              summaryLabels={summaryLabels}
              profileMenuProps={{
                onReassignClick: handleReassignClick,
                onEditClick: handleEditClick,
                customMenuItems,
                writeUpActions
              }}
              ProfileTabsProps={{
                endpoint,
                profileTabProps: tabsProps
              }}
            />
            {!isLoading && results?.id && (
              <>
                {results.shouldAskRecruiterAddEmployer && (
                  <LinkCompanyDialog
                    type="candidate"
                    entity={results}
                    onSetProfile={newProfile => handleProfileStateUpdate({ ...newProfile })}
                  />
                )}
                {uiState.isSendoutOpen && uiState.selectedSendoutId && (
                  <Drawer open onClose={handleSendoutClosed}>
                    <SendoutManager
                      id={uiState.selectedSendoutId}
                      onEdit={handleSendoutCompleted}
                      onDelete={handleSendoutCompleted}
                      onClose={handleSendoutClosed}
                      onNavigate={handleSendoutNavigation}
                    />
                  </Drawer>
                )}
                <Drawer open={uiState.isEditOpen} onClose={handleEditClosed}>
                  <div role="presentation">
                    <CandidateEdit
                      candidate={results}
                      onEditCompleted={handleEntityEditionCompleted({
                        handleEditCompleted,
                        enableUrlNavigation,
                        onEditionCompleted
                      })}
                      onEditClosed={handleEditClosed}
                    />
                  </div>
                </Drawer>
                <Drawer
                  open={uiState.isReassignOpen}
                  onClose={editionDrawer.toggleDrawer('isReassignOpen', false)}
                >
                  <div role="presentation">
                    <ReassignRecruiterForm
                      baseEndpoint={Endpoints.Candidates}
                      item={results}
                      entityType={entityType}
                      onReassignCompleted={handleReassignCompleted}
                      onReassignClosed={handleReassignClosed}
                    />
                  </div>
                </Drawer>
                <Drawer
                  open={uiState.isWriteUpOpen}
                  onClose={editionDrawer.toggleDrawer('isWriteUpOpen', false)}
                >
                  <div role="presentation">
                    <CandidateSheet
                      candidateId={candidateId}
                      drawerTitle={
                        canExecuteCandidateAssignmentSheet
                          ? profileCopies.dataSheet.title
                          : profileCopies.dataSheet.editButton.old
                      }
                      bluesheet={blueSheet}
                      profile={results}
                      isReadOnly={uiState.isWriteUpReadOnly}
                      onBluesheetCompleted={handleWriteUpdate}
                      onBluesheetClosed={handleBluesheetClosed}
                    />
                  </div>
                </Drawer>
                {referenceRelease?.candidateId && (
                  <Drawer open onClose={referenceReleaseHandler}>
                    <ReferenceRelease
                      candidateId={referenceRelease.candidateId}
                      onClose={referenceReleaseHandler}
                      onCompleted={handleReferenceReleaseComplete}
                    />
                  </Drawer>
                )}
                <SearchProjectForms />
                {uiState.isAddHiringAuthorityOpen && candidateId && (
                  <Drawer
                    open
                    onClose={() => handleHiringAuthorityClosed('isAddHiringAuthorityOpen')}
                  >
                    <AddHiringAuthority
                      candidate={results}
                      onClose={() => handleHiringAuthorityClosed('isAddHiringAuthorityOpen')}
                      onCompleted={handleAddHiringAuthorityCompleted}
                    />
                  </Drawer>
                )}
                {isOpenVerifyUrgencyDialog && (
                  <VerifyUrgencyDialog
                    entityId={candidateId}
                    entityType={EntityType.Candidate}
                    open={isOpenVerifyUrgencyDialog}
                    onClose={closeVerifyUrgencyDialog}
                    onSaveSucceeded={() => {
                      closeVerifyUrgencyDialog();
                      onVerificationCompleted && onVerificationCompleted();
                    }}
                  />
                )}
                {uiState.isLinkHiringAuthorityOpen && candidateId && (
                  <Drawer
                    open
                    onClose={() => handleHiringAuthorityClosed('isLinkHiringAuthorityOpen')}
                  >
                    <LinkHiringAuthority
                      candidateId={candidateId}
                      onClose={() => handleHiringAuthorityClosed('isLinkHiringAuthorityOpen')}
                      onCompleted={handleLinkHiringAuthorityCompleted}
                    />
                  </Drawer>
                )}
              </>
            )}
          </>
        )
      }}
    />
  );
};

export default FPCandidateProfile;
