import React from 'react';
import { Widget } from 'features/command-center';
import { getFinanceHealthDistribution } from 'features/command-center/services';
import strings from 'strings';

import { renderContent } from './HealthDistribution.utils';

export const HealthDistribution = ({ requestParams }) => {
  return (
    <Widget
      header={strings.commandCenter.finance.healthDistribution.title}
      subheader={strings.commandCenter.finance.healthDistribution.subtitle}
      promises={[() => getFinanceHealthDistribution(requestParams)]}
    >
      {renderContent}
    </Widget>
  );
};
