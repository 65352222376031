// @flow
import React, { useMemo, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { getPhonesWithId, PHONE_TYPE_MAP } from 'UI/components/organisms/PhoneNumbersForm/utils';
import { RingCentralOriginActions } from 'UI/constants/defaults';
import { SvgArrowDown } from 'UI/res';

import PhoneActionRow from './PhoneActionRow';
import PhoneItem from './PhoneItem';
import { useStyles } from './styles';

type PhoneNumbersDropdownProps = {
  contactValues: Object,
  onPhoneClick: () => void,
  data: Object,
  phonesKey: string,
  phoneKey: string
};

const PhoneNumbersDropdown = ({
  contactValues,
  data,
  onPhoneClick,
  phonesKey = 'phones',
  phoneKey = 'phone'
}: PhoneNumbersDropdownProps) => {
  const phones = contactValues[phonesKey] || [];
  const phone = contactValues[phoneKey] || null;

  const minPhonesToDisplayDropdown = phonesKey === 'phones' ? 1 : 0;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const uniquePhonesWithIds = useMemo(() => getPhonesWithId(phones), [phones]);

  const mainPhone =
    uniquePhonesWithIds.length > 0 ? uniquePhonesWithIds[0] : { phone, use: null, type_id: null };

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOnPhoneClick = phoneItem => {
    onPhoneClick({
      rowData: [...data?.rowData, phoneItem],
      origin: RingCentralOriginActions.MultiplePhonesDropdown
    });
    handleClose();
  };

  if (!mainPhone?.phone) return null;

  return (
    <div>
      <div className={classes.mainPhone}>
        <PhoneItem
          phone={mainPhone.phone}
          type={PHONE_TYPE_MAP[mainPhone?.type_id]?.title}
          use={mainPhone?.use}
        />
        {uniquePhonesWithIds.length > minPhonesToDisplayDropdown && (
          <IconButton aria-controls="phones-menu" aria-haspopup="true" onClick={handleClick}>
            <SvgArrowDown size={8} />
          </IconButton>
        )}
      </div>
      <Menu
        id="phones-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {uniquePhonesWithIds.map(item => (
          <MenuItem key={item.id}>
            <PhoneActionRow
              phone={item?.phone}
              type={PHONE_TYPE_MAP[item?.type_id]}
              isDefault={item?.is_default}
              use={item?.use}
              onPhoneClick={() => handleOnPhoneClick(item)}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PhoneNumbersDropdown;
