import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';

const shareProps = {
  animation: 'wave',
  variant: 'rounded',
  height: 300
};

export const LayoutSkeleton = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Skeleton {...shareProps} height={200} />
      </Grid>

      <Grid item xs={6}>
        <Skeleton {...shareProps} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton {...shareProps} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton {...shareProps} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton {...shareProps} />
      </Grid>
    </Grid>
  );
};
