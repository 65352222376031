import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter, flexAlignStart } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    mainContainer: {
      ...flexAlignCenter
    },
    mainText: {
      color: theme.palette.grey.black,
      letterSpacing: theme.spacing(0)
    },
    iconButton: {
      margin: theme.spacing(0.5, 1, 0, 0)
    },
    detailsContainer: {
      marginTop: theme.spacing(0.5)
    },
    actionButtonContainer: {
      marginLeft: theme.spacing(1)
    },
    mainTitleContainer: flexAlignCenter,
    titleContainer: {
      ...flexAlignStart,
      flexDirection: 'column'
    }
  }),
  { classNamePrefix: 'titleLabel' }
);
