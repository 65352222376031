import { THEME } from 'GlobalStyles';

export const DEFAULT_OPTIONS = {
  chartArea: { width: '85%', height: '80%' },
  fontName: THEME.typography.fontFamily,
  legend: { position: 'none' },
  series: {
    0: { lineWidth: 4, pointSize: 6 },
    1: { lineDashStyle: [4, 4], color: 'black' } // Average Line
  },
  vAxis: {
    viewWindow: {
      min: 0
    },
    baseline: 0,
    gridlines: { count: 4 },
    format: 'short'
  },
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1000
  }
};
