import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'fullName',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'chipBuilder',
        config: {
          labelKey: 'title',
          colorKey: 'color'
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'chipBuilder',
        config: {
          labelKey: 'title',
          colorKey: 'color',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'verifiedAt',
    label: 'Verified At',
    options: {
      display: true,
      sort: true,
      renderer: 'date'
    }
  },
  {
    name: 'verifiedBy',
    label: 'Verified By',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'assignedTo',
    label: 'Assigned To',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastActivity',
    label: 'Last Activity',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastActivityDate',
    label: 'Last Activity Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'lastActivityBy',
    label: 'Last Activity By',
    options: {
      sort: true,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'lastActivityDate',
  direction: 'desc'
};

export const VIRTUAL_PROPS = [
  {
    propName: 'fullName',
    attributes: ['id', 'fullName']
  }
];
