import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  HealthDistribution,
  InMailsGauge,
  LayoutSkeleton,
  PDAByTenure,
  PeopleReachedGauge,
  PhoneTimeGauge,
  PlacementsStatsSummary,
  TeamLayout,
  WeeklyActivityStatsSummary,
  WeeklyPerformance,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';

export const TeamOverview = ({ id, match }) => {
  const teamId = id ?? match?.params?.id;
  const requestParams = { team: teamId };
  const { refreshData, refreshKey } = useRefreshStats();

  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  const weeks = weeksCatalogState?.results;

  return (
    <TeamLayout teamId={teamId} onRefresh={refreshData}>
      {weeksCatalogState.status === UIStatus.Loading ? (
        <LayoutSkeleton />
      ) : (
        <Grid key={refreshKey} container spacing={4}>
          <Grid item xs={12}>
            <HealthDistribution requestParams={requestParams} />
          </Grid>

          <Grid item xs={12}>
            <PlacementsStatsSummary requestParams={requestParams} />
          </Grid>

          <Grid item xs={12}>
            <WeeklyActivityStatsSummary requestParams={requestParams} />
          </Grid>

          <Grid item xs={6}>
            <WeeklyProductivityStatsSummary requestParams={requestParams} />
          </Grid>

          <Grid item xs={12}>
            <PDAByTenure requestParams={requestParams} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PeopleReachedGauge
              requestParams={{ ...requestParams, groupBy: 'team' }}
              weeks={weeks}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InMailsGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CallsGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PhoneTimeGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
          </Grid>

          <Grid item xs={12}>
            <WeeklyPerformance teamId={teamId} />
          </Grid>
        </Grid>
      )}
    </TeamLayout>
  );
};
