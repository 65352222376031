// @flow

import { ContactRole, EntityType, SearchProjectItemType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';
import { getPropertyByPath } from 'UI/utils/inventory';

export const entitiesRoles = {
  [ContactRole.Name]: EntityType.Contact,
  [ContactRole.Candidate]: EntityType.Candidate,
  [ContactRole.HiringAuthority]: EntityType.HiringAuthority
};

export const searchProjectRoles = {
  [SearchProjectItemType.Candidate]: EntityType.Candidate,
  [SearchProjectItemType.HiringAuthority]: EntityType.HiringAuthority,
  [SearchProjectItemType.Name]: EntityType.Contact,
  [SearchProjectItemType.NameCandidate]: EntityType.Contact,
  [SearchProjectItemType.NameHA]: EntityType.Contact,
  [SearchProjectItemType.Company]: EntityType.Company
};

export const handleAssignEntityRole = ({ quickViewEntityRoleProps, item, onSetCurrentEntity }) => {
  let role = '';

  const { roleKey, pathToEntityRoleId } = quickViewEntityRoleProps;

  const hasMultipleRolesEntities = pathToEntityRoleId || roleKey;
  const entityRole = hasMultipleRolesEntities && getPropertyByPath(item, hasMultipleRolesEntities);

  Object.hasOwnProperty.call(item, roleKey) && (role = entitiesRoles[entityRole]);

  Object.hasOwnProperty.call(item, 'item_search_project_type') &&
    (role = searchProjectRoles[item?.item_search_project_type]);

  role && onSetCurrentEntity(role);
};

export const isProfileQuickViewActive = hasFeatureFlag(FeatureFlags.QuickViewProfiles);
