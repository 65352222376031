// @flow
import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getYTDActivityPlacementsSummary } from 'features/command-center/services';
import strings from 'strings';

export const PlacementsStatsSummary = ({ requestParams }) => {
  const { recruiter } = requestParams;
  const groupBy = recruiter ? 'recruiter' : 'team';
  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.placementsStatsSummary.title}
      promise={() => getYTDActivityPlacementsSummary({ ...requestParams, groupBy })}
      textAlign="center"
    />
  );
};
