import { useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { AllFiltersDefinition } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats, FilterType } from 'UI/constants/defaults';

import { InlineFiltersConfig } from './InlineFilters.constants';
import { formatFilters, PersistenceFiltersManager } from './InlineFilters.utils';

export const useInlineFilters = ({ filters, extraFilters, persistenceKey, onFiltersChange }) => {
  const previousFilters = useRef({});
  const persistenceFiltersManager = useRef(
    new PersistenceFiltersManager({
      persistenceKey: persistenceKey ? `inline-filters-${persistenceKey}` : null
    })
  );
  const [filterValues, setFilterValues] = useState(
    persistenceFiltersManager.current.loadPersistedFilters()
  );

  const filtersConfig = useMemo(() => {
    const cleanInlineFiltersConfig = InlineFiltersConfig.filter(
      filter => !filters.find(f => f.name === filter.name)
    );

    const filteredFiltersConfig = filters
      .map(
        filterName =>
          cleanInlineFiltersConfig.find(filter => filter.name === filterName) ||
          AllFiltersDefinition.find(filter => filter.name === filterName)
      )
      .filter(Boolean);

    return Object.keys(extraFilters || {}).reduce((acc, key) => {
      const filter = extraFilters[key];
      const foundIndex = acc.findIndex(f => f.name === filter.name);

      if (foundIndex === -1) {
        return [...acc, filter];
      }

      acc[foundIndex] = filter;
      return acc;
    }, filteredFiltersConfig);
  }, [filters, extraFilters]);

  useEffect(() => {
    if (isEqual(previousFilters.current, filterValues)) return;
    previousFilters.current = filterValues;
    persistenceFiltersManager.current.persistFilters(filterValues);

    const filtersToSend = formatFilters(filterValues, filtersConfig);

    onFiltersChange(filtersToSend);
  }, [filterValues, onFiltersChange, filtersConfig]);

  const activeFilters = Object.keys(filterValues).reduce((acc, key) => {
    const filterConfig = filtersConfig.find(item => item.name === key);

    if (filterValues[key]?.type === FilterType.DateRange) {
      return acc;
    }
    if (filterConfig?.hideActiveFilter) {
      return acc;
    }

    if (filterValues[key]?.type === FilterType.Date && filterValues[key]?.value) {
      return {
        ...acc,
        [key]: filterValues[key]?.value.format(DateFormats.SimpleDate)
      };
    }

    return {
      ...acc,
      [key]: filterValues[key]?.value
    };
  }, {});

  const handleFilterChange = type => (name, value) => {
    setFilterValues(prevState => {
      return {
        ...prevState,
        [name]: {
          type,
          value
        }
      };
    });
  };

  const handleDateRangeChange = name => period => {
    setFilterValues(prevState => {
      return {
        ...prevState,
        [name]: {
          type: FilterType.DateRange,
          value: period
        }
      };
    });
  };

  const handleCheckToggle = event => {
    const { checked, name, title } = event.target;
    handleFilterChange(FilterType.Switch)(name, checked ? title : null);
  };

  const handleFilterRemove = (name, value) => {
    setFilterValues(prevState => {
      if (Array.isArray(prevState[name].value)) {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: prevState[name].value.filter(val => val.id !== value)
          }
        };
      }

      const { [name]: _, ...rest } = prevState;

      return rest;
    });
  };

  const handleReset = () => {
    setFilterValues({});
    onFiltersChange({});
  };

  return {
    activeFilters,
    filterValues,
    filtersConfig,
    handleFilterChange,
    handleDateRangeChange,
    handleCheckToggle,
    handleFilterRemove,
    handleReset
  };
};
