import { THEME } from 'GlobalStyles';

const FONT = THEME.typography.fontFamily;

export const DEFAULT_OPTIONS = {
  chartArea: { width: '80%' },
  hAxis: {
    title: '',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal !important' }
  },
  vAxis: {
    title: '',
    minValue: 0,
    maxValue: 1,
    format: 'percent'
  },

  fontName: FONT,
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1500
  },

  tooltip: { isHtml: true },
  legend: { position: 'top' },
  colors: [
    THEME.palette.common.black,
    THEME.palette.primary.main,
    THEME.palette.success.main,
    THEME.palette.warning.main,
    THEME.palette.error.main
  ],
  pointSize: 5
};

// TODO: this fake response data will be receive by backend
export const RESPONSE_DATA = [
  ['Month', 'InMails', 'Posts', 'Bulks', 'People Reached', 'Calls'],
  ['Jan', 0.3, 0.5, 0.6, 0.8, 0.2],
  ['Feb', 0.2, 0.45, 0.55, 0.75, 0.15],
  ['Mar', 0.25, 0.5, 0.5, 0.7, 0.18],
  ['Apr', 0.4, 0.6, 0.7, 0.85, 0.25],
  ['May', 0.35, 0.55, 0.65, 0.8, 0.22],
  ['Jun', 0.3, 0.5, 0.6, 0.75, 0.2],
  ['Jul', 0.45, 0.6, 0.7, 0.85, 0.28],
  ['Aug', 0.4, 0.55, 0.65, 0.8, 0.25],
  ['Sep', 0.35, 0.5, 0.6, 0.75, 0.22],
  ['Oct', 0.3, 0.45, 0.55, 0.7, 0.2],
  ['Nov', 0.4, 0.5, 0.6, 0.8, 0.25],
  ['Dec', 0.35, 0.55, 0.65, 0.85, 0.22]
];
