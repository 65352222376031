import { THEME } from 'GlobalStyles';
import { currencyFormatter } from 'UI/utils';

import { createCustomTooltip } from '../finance.utils';

const FONT = THEME.typography.fontFamily;
export const MAIN_COLOR = THEME.palette.grey[700];
export const SECONDARY_COLOR = THEME.palette.primary.main;

export const LABELS = {
  Month: 'Month',
  Production: 'Production'
};

export const refactorData = ({ monthLabels, cashInSeries, productionSeries }) => {
  const data = [
    [
      LABELS.Month,
      LABELS.Production,
      { role: 'tooltip', type: 'string', p: { html: true } },
      // { role: 'annotation' }, // Annotation role for Production
      'Cash in',
      { role: 'tooltip', type: 'string', p: { html: true } }
      // { role: 'annotation' } // Annotation role for Cash in
    ]
  ];

  return Object.keys(monthLabels).reduce((acc, key) => {
    return [
      ...acc,
      [
        monthLabels[key],
        productionSeries[key],
        createCustomTooltip({
          category: monthLabels[key],
          title: 'Production',
          value: currencyFormatter(productionSeries[key])
        }),
        // `${(productionSeries[key] / 1_000_000).toFixed(2)}M`, // Annotation for Production
        cashInSeries[key],
        createCustomTooltip({
          category: monthLabels[key],
          title: 'Cash in',
          value: currencyFormatter(cashInSeries[key])
        })
        // `${(cashinSeries[key] / 1_000_000).toFixed(2)}M` // Annotation for Cash in
      ]
    ];
  }, data);
};

export const DEFAULT_OPTIONS = {
  chartArea: { width: '80%' },
  hAxis: {
    title: 'Month',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal !important' }
  },
  vAxis: {
    title: '',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal' },
    format: 'short'
  },
  colors: [MAIN_COLOR, SECONDARY_COLOR],
  fontName: FONT,
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1500
  },
  legend: 'none',
  tooltip: { isHtml: true },
  bar: { groupWidth: '50%' },
  isStacked: false
};

export const createSeries = data => {
  return Object.keys(data).reduce(
    (acc, key) => {
      return {
        monthLabels: [...acc.monthLabels, data[key].month],
        productionSeries: [...acc.productionSeries, data[key].production],
        cashInSeries: [...acc.cashInSeries, data[key].cashIn]
      };
    },
    {
      monthLabels: [],
      productionSeries: [],
      cashInSeries: []
    }
  );
};
