// @flow
import React, { useCallback, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SimplifiedTable } from 'features/command-center/';
import { ENDPOINTS } from 'features/command-center/constants';
import strings from 'strings';
import type { Filters } from 'types/app';
import { RowActions } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';

import { COLUMNS, ORDER_BY_OPTIONS, VIRTUAL_PROPS } from './HotJobOrders.constants';
import { formatResponse } from './HotJobOrders.utils';
import { useStyles } from './styles';

interface HotJobOrdersProps {
  filters: Filters[];
}

const COPIES = strings.commandCenter.dominateSheet.hotJobOrders;
const ENDPOINT = ENDPOINTS.hotInventoryJobOrders;
const TABLE_KEY = 'hot-job-orders-list';

export const HotJobOrders = ({ filters }: HotJobOrdersProps) => {
  const classes = useStyles();
  const [showPendingVerification, setShowPendingVerification] = useState(false);

  const handleCheckToggle = (event, checked) => setShowPendingVerification(checked);

  const extendParams = useCallback(() => {
    return {
      showPendingToVerify: showPendingVerification
    };
  }, [showPendingVerification]);

  return (
    <SimplifiedTable
      columns={COLUMNS}
      endpoint={ENDPOINT}
      entityType={EntityType.Joborder}
      extendParams={extendParams}
      filters={filters}
      formatResponse={formatResponse}
      header={COPIES.title}
      headerAction={
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={showPendingVerification}
              onChange={handleCheckToggle}
              name="showPendingVerification"
            />
          }
          label={COPIES.headerAction}
          classes={{ label: classes.labelHeaderActions }}
          onClick={e => e.stopPropagation()}
        />
      }
      orderByOptions={ORDER_BY_OPTIONS}
      pageKey={TABLE_KEY}
      virtualProps={VIRTUAL_PROPS}
      rowActions={[
        RowActions.View,
        {
          action: 'viewContact',
          name: 'viewContact',
          value: 'contactData'
        }
      ]}
    />
  );
};
