// @flow
import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { InlineFilters, SimplifiedTable } from 'features/command-center/';
import {
  COMMAND_CENTER_BASE_URL,
  ENDPOINTS,
  INLINE_FILTERS_KEYS
} from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { FilterType } from 'UI/constants/defaults';
import { SvgInformationCircle } from 'UI/res';

import {
  COLUMNS,
  DATE_RANGE_FILTER_DEF,
  INLINE_FILTERS,
  ORDER_BY_OPTIONS
} from './WeeklyPerformance.constants';
import { useStyles } from './WeeklyPerformance.styles';
import { extendParams, formatResponse, getFinalParams } from './WeeklyPerformance.utils';

const COPIES = strings.commandCenter.activity.weeklyPerformance;

const extraFilters = [
  DATE_RANGE_FILTER_DEF,
  {
    name: 'tenure',
    paramName: 'tenure',
    title: 'Tenure',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    api: 'secondary',
    url: `${COMMAND_CENTER_BASE_URL}/catalog/tenures`,
    disableListWrap: true,
    multiple: false,
    idKey: 'title'
  }
];

export const WeeklyPerformance = ({ teamId = null }) => {
  const [filters, setFilters] = useState(DATE_RANGE_FILTER_DEF);
  const classes = useStyles();

  const handleFiltersChange = selectedFilters => {
    const tempFilters = Object.keys(selectedFilters).includes('dateRange')
      ? selectedFilters
      : {
          ...selectedFilters,
          dateRange: { ...DATE_RANGE_FILTER_DEF, value: DATE_RANGE_FILTER_DEF.defaultRange }
        };

    const finalFilters = getFinalParams(tempFilters);

    setFilters(finalFilters);
  };

  const persistenceKey = teamId
    ? INLINE_FILTERS_KEYS.activity.weeklyPerformanceByTeam
    : INLINE_FILTERS_KEYS.activity.weeklyPerformance;

  const extendParamsByTeam = useCallback(() => extendParams(teamId), [teamId]);

  const footer = (
    <div className={classes.footer}>
      <FPIcon
        role="img"
        aria-label="Info"
        component={SvgInformationCircle}
        onSetColor={palette => palette.info.main}
        size={18}
      />
      <Typography className={classes.label}>{COPIES.footer.infoLabel}</Typography>
    </div>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <InlineFilters
          filters={INLINE_FILTERS}
          extraFilters={extraFilters}
          onFiltersChange={handleFiltersChange}
          persistenceKey={persistenceKey}
        />
      </Grid>
      <Grid item xs={12}>
        <SimplifiedTable
          pageKey={persistenceKey}
          apiInstance={SECONDARY_API_CLIENT}
          columns={COLUMNS}
          endpoint={ENDPOINTS.weeklyPerformance}
          filters={filters}
          apiVersion={2}
          header={COPIES.title}
          footer={footer}
          orderByOptions={ORDER_BY_OPTIONS}
          formatResponse={formatResponse}
          extendParams={extendParamsByTeam}
        />
      </Grid>
    </Grid>
  );
};
