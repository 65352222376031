import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import isNil from 'lodash/isNil';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import { currencyFormatter, formatPercentage, formatRatio } from 'UI/utils';

import { createQueryParams } from './utils';

const FORMATTERS = {
  avgFee: value => currencyFormatter(value, 1, { notation: 'compact', forceCompactNotation: true }),
  fallOffRatePercent: value => formatPercentage(value)
};

export const getActivitySummary = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activitySummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivityHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getWeekActivity = async ({ groupBy = 'team', recruiter, team }) => {
  const defaultParams = {
    groupBy,
    metrics: 'inMails,bulks,peopleReached,posts,calls,phoneTime',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityWeek}?${queryParams}`
  ).then(({ data }) => {
    const { goals, stats } = Object.keys(data).reduce(
      (acc, key) => {
        if (key.includes('Goal')) {
          acc.goals[key] = data[key];
        } else {
          acc.stats[key] = data[key];
        }
        return acc;
      },
      { goals: {}, stats: {} }
    );

    const finalData = Object.keys(stats).map(key => {
      const value = Number(stats[key]);
      const goal = goals[`${key}Goal`];
      return {
        color: value < goal ? 'purple' : 'black',
        title: strings.commandCenter.metricsTitles[key],
        value: formatRatio(value, goal, 'scalar')
      };
    });

    return finalData;
  });
};

export const getYTDActivityPlacementsSummary = async ({ groupBy = 'team', recruiter, team }) => {
  const defaultParams = {
    groupBy,
    metrics: 'placements,fallOffs,placedRatio,avgFee',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityPlacementsSummary}?${queryParams}`
  ).then(({ data }) => {
    const keyOrder = [
      'totalPlacements',
      'placedRatio',
      'totalFallOffs',
      'fallOffRatePercent',
      'avgFee'
    ];

    const orderedData = keyOrder.reduce((acc, key) => {
      if (!isNil(data[key])) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    return Object.keys(orderedData).map(key => {
      const value = FORMATTERS[key] ? FORMATTERS[key](orderedData[key]) : data[key];
      return {
        title: strings.commandCenter.metricsTitles[key],
        value
      };
    });
  });
};

export const getActivityPlacementsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityPlacementsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivitySendoutsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activitySendoutsHistory}?${queryParams}`
  ).then(({ data }) => data);
};
