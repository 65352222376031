// @flow
import React, { useState } from 'react';
import {
  LineSteppedAreaChart,
  SimplifiedDateRange,
  Widget
} from 'features/command-center/components/shared';
import { fakePromise } from 'features/command-center/components/shared/GaugeStats/GaugeStats.constants';
import { CURRENT_YEAR, TIME_FRAMES } from 'features/command-center/constants';
import moment from 'moment';
import strings from 'strings';

import { getChartDayLabels, getChartMonthLabels } from '../activity.utils';

import { RESPONSE_DATA } from './ProductionTrends.utils';

const [YEARLY] = TIME_FRAMES;

export const ProductionTrends = () => {
  const [dateRange, setDateRange] = useState(CURRENT_YEAR);
  const [week, setWeek] = useState(null);
  const [timeFrame, setTimeFrame] = useState(YEARLY);

  const handleSelectDateRange = (name, dateRangeValue) => {
    setDateRange(dateRangeValue);
  };

  const handleSelectWeek = (event, weekValue) => {
    setWeek(weekValue);
  };

  const handleTimeFrameChange = (name, value) => {
    setTimeFrame(value);
    setDateRange(CURRENT_YEAR);
    setWeek(null);
  };

  const getChartOptions = () => {
    const isCurrentYear = moment(dateRange.startDate).isSame(moment(), 'year');

    return {
      hAxis: {
        ticks:
          timeFrame.title === TIME_FRAMES[1].title
            ? getChartDayLabels()
            : getChartMonthLabels(isCurrentYear)
      }
    };
  };

  return (
    <Widget
      header={strings.commandCenter.activity.productionTrends.title}
      promises={[() => fakePromise(RESPONSE_DATA)]}
    >
      {([results]) => {
        return (
          <>
            <SimplifiedDateRange
              dateRange={dateRange}
              onPeriodChange={handleSelectDateRange}
              onTimeFrameChange={handleTimeFrameChange}
              onWeekChange={handleSelectWeek}
              timeFrame={timeFrame}
              week={week}
            />

            <LineSteppedAreaChart data={results} options={getChartOptions()} />
          </>
        );
      }}
    </Widget>
  );
};
