import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';

export const HealthDistributionWrapper = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  '& .MuiGrid-item': {
    padding: 0
  },
  '& svg > g > g.google-visualization-tooltip': {
    pointerEvents: 'none'
  }
}));
