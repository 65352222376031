// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

import { FORM_FIELDS_MAP } from '../fields';

import { useStyles } from './styles';

const SearchProjectExtractOptions = () => {
  const classes = useStyles();
  const { setValue, watch, register, unregister, getValues } = useFormContext();
  const formValues = watch();

  const handleCheckChange = e => {
    const { name, checked } = e.target;

    setValue(name, checked);

    const addEmployees = getValues(FORM_FIELDS_MAP.AddEmployees.key);
    const addHirings = getValues(FORM_FIELDS_MAP.AddHiringAuthorities.key);

    setValue(FORM_FIELDS_MAP.ExcludeCompanies.key, checked || addEmployees || addHirings);
  };

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.AddEmployees.key }, { value: false });
    register({ name: FORM_FIELDS_MAP.AddHiringAuthorities.key }, { value: false });
    register({ name: FORM_FIELDS_MAP.ExcludeCompanies.key }, { value: false });

    return () => {
      unregister(FORM_FIELDS_MAP.AddEmployees.key);
      unregister(FORM_FIELDS_MAP.AddHiringAuthorities.key);
      unregister(FORM_FIELDS_MAP.ExcludeCompanies.key);
    };
  }, [register, unregister]);

  return (
    <Grid>
      <Grid item className={classes.description}>
        <Typography variant="subtitle1">{strings.searchProjects.options.description}</Typography>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              name={FORM_FIELDS_MAP.AddEmployees.key}
              checked={!!formValues[FORM_FIELDS_MAP.AddEmployees.key]}
              onChange={handleCheckChange}
              color="primary"
              disableRipple
            />
          }
          label={strings.searchProjects.options.addEmployees}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              name={FORM_FIELDS_MAP.AddHiringAuthorities.key}
              checked={!!formValues[FORM_FIELDS_MAP.AddHiringAuthorities.key]}
              onChange={handleCheckChange}
              color="primary"
              disableRipple
            />
          }
          label={strings.searchProjects.options.addHiringAuthorities}
        />
      </Grid>
    </Grid>
  );
};

export default SearchProjectExtractOptions;
