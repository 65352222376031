import { THEME } from 'GlobalStyles';

export const COLORS_BY_LABEL = {
  black: THEME.palette.grey[700],
  blue: THEME.palette.info.main,
  green: THEME.palette.success.main,
  purple: '#A38FF4',
  red: THEME.palette.error.main,
  yellow: THEME.palette.warning.main
};
