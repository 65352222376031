// @flow
import React from 'react';
import { styled } from '@material-ui/core';

import { StatsItem } from '../StatsItem';
import { StatsItemProps } from '../StatsItem/StatsItem';

const StatsItemWrapper = styled(({ textAlign: _textAlign, variant: _variant, ...other }) => (
  <div {...other} />
))(({ textAlign, theme, variant }) => ({
  display: 'grid',
  gridTemplateColumns: `${
    variant === 'inline' ? `repeat(auto-fit, minmax(150px, 1fr))` : 'auto auto'
  }`,
  gap: theme.spacing(2),
  '& p': {
    textAlign
  }
}));

type StatsItemContainerProps = {
  items: Array<StatsItemProps>,
  textAlign?: 'left' | 'center',
  variant?: 'inline' | 'stacked'
};

export const StatsItemContainer = ({
  items,
  textAlign = 'left',
  variant = 'inline'
}: StatsItemContainerProps) => {
  return (
    <StatsItemWrapper role="list" textAlign={textAlign} variant={variant}>
      {items.map(item => (
        <StatsItem key={item.title} {...item} />
      ))}
    </StatsItemWrapper>
  );
};
