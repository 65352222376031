export const Endpoints = {
  Industries: 'industries',
  Positions: 'positions',
  States: 'states',
  Cities: 'cities',
  Countries: 'countries',
  /** Retrieves all zip codes for a given city */
  Zips: 'zips',
  /** Search zip codes in the whole city/zip hierarchy */
  ZipCodes: 'zip-codes',
  Digs: 'digs',
  Inventories: 'inventories',
  Specialties: 'specialties',
  /** Retrieves all subspecialties for a given specialty */
  Subspecialties: 'specialties/:id/subspecialties',
  /** Search subspecialties in the industry/specialty/subspecialty hierarchy */
  SubspecialtiesSearch: 'subspecialties',
  PositionsSearch: 'positions/equivalents',
  SourceTypes: 'source-types',
  StartTimes: 'candidates/bluesheets/timetypes',
  CitiesSearch: 'cities/search',
  Search: 'search',
  ChannelPartner: 'channel-partner',
  Pil: 'pil',
  Statuses: 'statuses',
  PossibleEmployees: 'possible-employees',
  CountryCodes: 'phone-country-codes',
  PhoneTypes: 'phone-types',
  EmailVerdicts: 'emails/verdicts',

  /**
   * Dashboard
   */
  DashboardBulkEmailSent: 'bulk-email-dashboard/total-bulk-sent',
  DashboardBulkEmailTrendSent: 'bulk-email-dashboard/trend-bulk-sent',
  DashboardBulkEmailStats: 'bulk-email-dashboard/total-bulk-stats',
  DashboardBulkEmailSentTable: 'bulk-email-dashboard/table-bulk-sent',

  /**
   * Placements
   */
  Placements: 'placements',
  PlacementSuggestions: 'placements/:id/suggest-update',
  PlacementInvoices: 'placements/:id/invoices',
  PlacementPayments: 'placements/:id/payments',
  PlacementPaymentsV2: '/erp-integrations/payments',
  PlacementStatuses: 'placements/statuses',
  PlacementSummary: 'placements/summary',
  PlacementLogs: 'placements/:id/logs',
  PlacementRequestMarkFallOff: 'placements/:id/requestFallOff',
  PlacementRequestRevertFallOff: 'placements/:id/requestRevertFallOff',
  PlacementMarkFallOff: 'placements/:id/markfallOff',
  PlacementRevertFallOff: 'placements/:id/revertFallOff',
  PlacementFallOffReasons: 'placements/fallOffReasons',
  PlacementRequestZip: 'placements/:id/compress-files',
  PlacementEstimate: 'placements/:id/estimate',
  PlacementApproveEstimate: 'placements/:id/approve-estimate',
  PlacementRecreateEstimate: 'placements/:id/mark-for-estimate-process',
  PlacementDeletePermanently: 'placements/:id',
  PaymentTerms: 'payment-terms',

  /**
   * Sendouts
   */
  Sendouts: 'sendouts',
  SendoutsFromChannelPartner: 'sendouts/from-channel-partners',
  SendoutsLogs: 'sendouts/:id/logs',
  SendoutsStatus: 'sendouts/status-types',
  SendoutsTemplate: 'sendouts/template',
  SendoutsTemplates: 'sendouts/templates',
  SendoutsInterviewTypes: 'sendouts/interview-types',
  SendoutsTypes: 'sendouts/types',
  SendoutsFiles: 'sendouts/:id/files',
  SendoutsSummary: 'sendouts/summary',
  SendoutsFromChannelPartnerSummary: 'sendouts/from-channel-partners/summary',
  Sendover: 'sendovers',
  SendoverConvert: 'sendovers/convert/sendout/:id',
  SendoutBoardConfiguration: 'sendouts/board/config',
  SendoutDashBoard: 'sendouts/dashboard',
  SendoutDashBoardWeeks: 'sendouts/dashboard/weeks',
  SendoutDashboardMonths: 'sendouts/dashboard/months',
  SendoutDashboardYears: 'sendouts/dashboard/years',
  SendoutDashboardWeeksByMonth: 'sendouts/dashboard/weeksByMonth',
  SendoutDashBoardRecruiters: 'sendouts/dashboard/recruiters',
  SendoutDashboardLeadersHeaders: 'sendouts/dashboard/leaders/headers',
  SendoutDashboardLeadersDays: 'sendouts/dashboard/leaders/days',
  SendoutDashboardLeadersWeeks: 'sendouts/dashboard/leaders/weeks',
  SendoutDuplicates: 'sendouts/duplicates',
  SendoutResendEmail: 'sendouts/:id/email/resend',
  SendoutInterviewDetails: 'sendouts/interviews/:id',
  SendoutDebriefingCalls: '/sendouts/interviews/:id/debriefing-calls',
  SendoutPreparationCalls: '/sendouts/interviews/:id/preparation-calls',

  /**
   * @method GET - Returns data about company type change request
   * used to show more info to OPS team
   * @method PATCH - Receives company_type_id in body, used to update
   * comany type change when request is in progress (not yet checked by OPS)
   */

  PlacementBySendout: 'sendouts/:id/placements',
  Types: 'types',
  FilesForPlacement: 'sendouts/:id/file-types-to-create-placement',

  /**
   * Candidates
   */
  Candidates: 'candidates',
  CandidateStatuses: 'candidates/statuses',
  CandidateTypes: 'candidates/bluesheets/candidatetypes',
  CandidateCompany: 'candidates/:id/employer-company',
  CandidateSuggestedCompanies: 'candidates/:id/suggested-companies',
  CandidateRecentlyViewed: 'candidates/:id/recently-viewed',
  CandidateSearchProjects: 'candidates/:id/search-projects',
  CandidateSourceTypes: 'candidates/source-types',
  CandidateAssignment: 'assign-candidate',
  CandidateFiles: 'candidates/:id/files',

  /**
   * Company
   */
  Companies: 'companies',
  CompanyTypeReassure: 'companies/:id/type-reassure',
  CompanyTypeReassureOps: 'companies/:id/type-reassure-ops-verification',
  /**
   * @method GET - Returns data about company type change request
   * used to show more info to OPS team
   * @method PATCH - Receives company_type_id in body, used to update
   * comany type change when request is in progress (not yet checked by OPS)
   */
  CompanyTypeReassurePending: 'companies/:id/type-reassure/:referenceId',
  CompanyTypes: 'companies/types',
  CompanyRecentlyViewed: 'companies/:id/recently-viewed',
  CompanySearchProjects: 'companies/:id/search-projects',
  CompanyChangeLogs: 'change-logs',
  CompanyEmployees: 'companies/:id/employees',

  /*
   * Job Orders
   */
  JobOrders: 'job-orders',
  JobOrderStatuses: 'job-orders/statuses',
  JobOrderTypes: 'job-orders/whiteSheets/jobordertypes',
  JobOrderRecentlyViewed: 'job-orders/:id/recently-viewed',
  JobOrderSourceTypes: 'job-orders/source-types',
  JobOrderAssigned: 'assign-job-order',
  JobOrderSendouts: 'job-orders/:id/sendouts',
  JobOrderSendovers: 'job-orders/:id/sendovers',

  /**
   * Contacts
   */
  Names: 'names',
  NameRoles: 'names/types',
  NameStatus: 'names/grouped-statuses',
  SimpleStatuses: 'names/statuses?nameTypeId=:nameTypeId',
  NameTypes: 'names/grouped-types',
  NamesCompany: 'names/:id/employer-company',
  NamesSuggestedCompanies: 'names/:id/suggested-companies',
  NameRecentlyViewed: 'names/:id/recently-viewed',
  NameSearchProjects: 'names/:id/search-projects',
  NameConvertedStatus: 'names/:id/conversion-status',

  /**
   * Search projects
   */
  SearchProjects: 'search-projects',
  SearchProjectsTypes: 'search-projects/types',
  SearchProjectQuickInfo: 'search-projects/:id/quick-info',
  /**
   * @method PUT - Updates sp name and privacy scope
   * @method DELETE - Deletes a SP logically by id param
   */
  SearchProjectDetail: 'search-projects/:id',
  SearchProjectRestore: 'search-projects/:id/restore',
  /**
   * @method GET to preview sp items and data
   * @method DELETE to remove items from SP
   * @method PUT
   */
  SearchProjectsInventory: 'search-projects/:id/inventory',
  SearchProjectsAddPreview: 'search-projects/:id/preview',

  /**
   * Search projects collaborations
   */
  SearchProjectsAddCollaborator: 'search-projects/:id/collaboration',
  SearchProjectsRemoveCollaborator: 'search-projects/:id/collaboration/:collabIds',

  /**
   * Search projects activity log
   */
  SearchProjectHistoryEvents: 'search-projects/:id/history-events',

  /**
   * Search projects splits
   */
  SearchProjectSplit: 'search-projects/:id/inventory/split',

  /**
   * Search projects folders
   */

  /**
   * @method GET
   * @method POST
   */
  SearchProjectFolders: 'search-projects/folders',
  /**
   * @method GET
   * @method PUT
   */
  SearchProjectFolderById: 'search-projects/folders/:id',
  SearchProjectRestoreFolder: 'search-projects/folders/:id/restore',

  /**
   * Bulk Email
   */
  BulkEmails: 'bulk-emails',
  BulkEmailsPreview: 'bulk-emails/preview/body',
  BulkEmailById: 'bulk-emails/:id',
  BulkUploadAttachment: 'bulk-emails/attachment',
  BulkEmailBodyImage: 'bulk-emails/image',
  BulkEmailScope: 'bulk-emails/scopes',
  BulkEmailGeneralCategories: 'bulk-emails/subcategories',
  BulkEmailSmartTags: 'bulk-emails/smartags',
  BulkEmailMetrics: 'bulk-emails/:id/metrics',
  BulkEmailMetricsListing: 'bulk-emails/:id/metrics/listing',

  /**
   * Bulk Email Schedules
   */
  BulkEmailSchedules: 'bulk-emails/schedules',
  /**
   * @method GET
   * @method DELETE
   * @method PUT
   */
  BulkEmailScheduleById: 'bulk-emails/schedules/:id',

  /**
   * Bulk Email Scheduled - Send Now
   * @method POST
   */
  BulkEmailSendScheduledNow: 'bulk-emails/schedules/:id/send-now',
  /**
   * @method DELETE
   */
  BulkEmailDeschedule: 'bulk-emails/schedules/:id/deschedule',
  BulkEmailScheduleUpdateSendDate: 'bulk-emails/schedules/:id/send-date',

  /**
   * Bulk Email Drafts
   */

  BulkEmailsDrafts: 'bulk-emails/drafts',
  /**
   * @method PUT
   * @method DELETE
   */
  BulkEmailsDraftById: 'bulk-emails/drafts/:id',

  /**
   * Bulk Email Folders/Templates
   */
  BulkEmailsTemplates: 'bulk-emails/templates',
  BulkEmailsFolders: 'bulk-emails/folders',
  BulkEmailsFolderById: 'bulk-emails/folders/:id',
  BulkEmailsTemplatesSearch: 'bulk-emails/templates/search',
  BulkTemplateById: 'bulk-emails/templates/:id',
  BulkTemplateArchive: 'bulk-emails/templates/:id/archive',
  BulkTemplateRestore: 'bulk-emails/templates/:id/restore',
  BulkTemplateFolderArchive: 'bulk-emails/folders/:id/archive',
  BulkTemplateFolderRestore: 'bulk-emails/folders/:id/restore',

  /**
   * Bulk Email Opt In/Out
   */
  BulkEmailsOptOuts: 'bulk-emails/opt-outs',
  BulkEmailsOptOutReasons: 'bulk-emails/opt-outs/reasons',

  /**
   * Bulk Email Quota
   */
  BulkEmailOutlookQuota: 'bulk-emails/outlook-quota',

  /**
   * Files
   */
  Files: 'files',
  FileCategories: 'files/types',
  FilesDelete: 'files/:id',

  /**
   * Fee Agreements
   */
  FeeAgreement: 'fee-agreements',
  FeeAgreementOverridableUsers: 'fee-agreements/overridable-users',
  FeeAgreementUnmanaged: 'unmanaged-fee-agreements',
  FeeAgreementById: 'fee-agreements/:id',
  FeeAgreementsByCompany: 'fee-agreements/active-by-company',
  GuaranteeDays: 'fee-agreements/fee-agreement-guarantee-days-options',
  GuaranteeDaysGrouped: 'fee-agreements/fee-agreement-guarantee-days-options-by-payment-scheme',
  FeeAgreementValidationByCoach: ':id/coach-validation',
  FeeAgreementValidationByOperations: ':id/operations-validation',
  FeeAgreementUnmanagedValidationByOperations: ':id/operations-validation-for-unmanaged',

  FeeAgreementDeclinationByCoach: ':id/coach-declination',
  FeeAgreementDeclinationByOperations: ':id/operations-declination',
  FeeAgreementOperationsSendToSign: ':id/operations-send-to-sign',

  FeeAgreementRevalidationToCoach: ':id/send-to-coach-validation',
  FeeAgreementRevalidationToOperations: ':id/send-to-operations-validation',

  FeeAgreementStatuses: 'fee-agreement-statuses',
  FeeAgreementSendReminder: ':id/send-reminder',

  FeeAgreementCreateTemplateDraft: 'create-template-draft',
  FeeAgreementCreateSignatureRequestPreview: ':id/create-signature-request-preview',
  FeeAgreementVoidContract: 'void-contract',
  FeeAgreementCancelValidationRequest: 'cancel-validation-request',
  FeeAgreementUpdateEmails: ':id/emails',
  FeeAgreementTemplate: 'template',
  FeeAgreementTemplates: 'templates',
  FeeAgreementRestoreExpired: 'restore-expired',
  FeeAgreementSwitchProvider: 'switch-to-backup-service',
  FeeAgreementTempFile: 'fee-agreements/temporary-file',
  FeeAgreementPaymentCombinations: 'fee-agreements/payment-combinations',

  FeeAgreementDashboard: 'fee-agreements/dashboard/board',
  FeeAgreementDashboardWeeks: 'fee-agreements/dashboard/weeks',
  FeeAgreementDashboardYears: 'fee-agreements/dashboard/years',
  FeeAgreementDashboardRecruiters: 'fee-agreements/dashboard/recruiters',

  Notes: 'notes',
  Users: 'users',
  UsersRefreshToken: 'users/token/refresh',
  UserRoles: 'users/:id/roles',
  Roster: 'roster',
  Offices: 'users/roster/offices',
  Departments: 'departments',
  Recruiters: 'recruiters',
  UserBasics: 'users/:id/basicInfo',
  Roles: 'roles',
  WorkTypes: 'workTypeOptions',
  Dashboard: 'dashboard',
  AssignmentHistory: 'getAssignmentHistory',
  Reassign: 'reassign',
  AdditionalRecruiters: 'additional-recruiters',
  NotificationHistory: 'notifications/get-notification-history',
  NotificationRegistration: 'notifications/add-registration-token',
  NotificationMarkAsRead: 'notifications/mark-notification-as-read',
  Phone: 'phone',
  Managers: 'users/managers',
  ReferenceReleases: 'reference-releases',
  ReferenceReleaseTemplate: 'reference-releases/template',
  Unlink: 'unlink',
  UserStatuses: 'statuses',
  UserTimeZones: 'users/timezones',

  /**
   * Activities
   */
  Activities: 'activityLogs',
  ActivityTypes: 'activityLogTypes',
  ActivityLogs: 'activity-logs',

  /**
   * Hiringh Authorities
   */
  HiringAuthorities: 'hiring-authorities',
  HiringAuthorityRecentlyViewed: 'hiring-authorities/:id/recently-viewed',
  AvailableHiringAuthorities: 'available-hiring-authorities',
  HiringAuthoritiesStatuses: 'hiring-authorities/statuses',
  HiringAuthoritySearchProjects: 'hiring-authorities/:id/search-projects',

  /**
   * Home
   */
  HomeConfig: 'home/config',
  HomeConfigTabs: 'home/config-tabs',

  /**
   * ----------------------------
   *          VERSION 2
   * ----------------------------
   */

  /**
   * Dashboard
   */
  DashboardBulkEmail: 'bulk-email-dashboard/report',
  DashboardBulkEmailDownloadCSV: 'bulk-email-dashboard/table-bulk-sent-spreadsheet',
  DashboardUserLeaders: 'users/:id/:roleId/leaders',

  /**
   * ----------------------------
   *          VERSION 2
   * ----------------------------
   */

  ToolsUsageReportData: 'tools-usage-report/data',
  ToolsUsageReportWeeks: 'tools-usage-report/weeks',
  ToolsUsageReportRecruiters: 'tools-usage-report/recruiters',
  ToolsUsageReportDownload: 'tools-usage-report/download-report',

  /**
   * Associated Companies
   */

  /**
   * @method GET
   */
  PossibleParentCompany: 'companies/possible-parent-companies-for-new-company',
  PossibleSiblingCompanies: 'companies/possible-sister-companies-for-new-company',
  PossibleChildCompanies: 'companies/possible-child-companies-for-new-company',
  PossibleParentExistingCompany: 'companies/:id/possible-parent-companies',
  PossibleChildOrSiblingExistingCompany: 'companies/:id/possible-child-or-sister-companies',
  AssociatedCompanies: 'associated-companies',

  /**
   * @method PUT
   * @method DELETE
   */
  ParentCompany: 'companies/:id/parent-company',
  SisterCompanies: 'companies/:id/sister-companies',
  ChildCompanies: 'companies/:id/child-companies',

  /**
   * ----------------------------
   *          VERSION 2
   * ----------------------------
   */

  /**
   * ----------------------------
   *          API AWS
   * ----------------------------
   */
  /**
   * @method GET
   */
  ReferenceCheckByCandidate: 'reference-checks/candidate',
  ReferenceCheck: 'reference-checks/reference',
  /**
   * RC EVENT FILTER
   */
  RcEventFilter: 'rc-filter-events/send',

  /**
   * ----------------------------
   *          SECONDARY API AWS
   * ----------------------------
   */
  JSONSchema: `forms-builder/schema?key=:schemaName`,
  /**
   * ----------------------------
   *          DATA SHEET
   * ----------------------------
   */
  DataSheetByJobOrderId: 'dynamic-data-sheet/job-order-data-sheet/by-job-order/:id',
  DataSheetSaveCompleted: 'dynamic-data-sheet/job-order-data-sheet/save-completed',
  DataSheetSaveDraft: 'dynamic-data-sheet/job-order-data-sheet/save-draft',
  /**
   * ----------------------------
   *          CANDIDATE DATA SHEET
   * ----------------------------
   */
  CandidateDataSheetByCandidateId: 'dynamic-data-sheet/candidate-data-sheet/by-candidate/:id',
  CandidateDataSheetSave: 'dynamic-data-sheet/candidate-data-sheet/save',
  CandidateDataSheetSaveDraft: 'dynamic-data-sheet/candidate-data-sheet/draft',
  CandidateDataSheetById: 'dynamic-data-sheet/candidate-data-sheet/:id',

  /**
   * Collections
   */
  Collections: '/erp-integrations/accounts-receivable/v2',
  CollectionsInvoices: '/erp-integrations/accounts-receivable/invoices',
  CollectionsInvoiceTypes: '/erp-integrations/accounts-receivable/invoices/types',
  CollectionsTeams: '/erp-integrations/accounts-receivable/teams',
  CollectionsTeamsOnInvoices: '/erp-integrations/accounts-receivable/teams-on-invoice',

  /**
   * Urgency Verification
   */
  UrgencyVerificationCandidates: '/urgency-verification/candidates/:id/verifications',
  UrgencyVerificationJobOrders: '/urgency-verification/job-orders/:id/verifications'
};
