import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';

export const SkeletonCards = () => {
  const emptyArray = Array.from({ length: 21 }, (_, i) => i);

  return (
    <>
      {emptyArray.map(item => (
        <StatsCard
          key={item}
          header={
            <Skeleton animation="wave" variant="rect" height={160} style={{ borderRadius: 0 }} />
          }
        >
          <Box>
            <Skeleton animation="wave" variant="text" height={80} />
            <Box display="flex" gridGap={10} pt={1}>
              <Skeleton animation="wave" variant="text" width={120} height={40} />
              <Skeleton animation="wave" variant="text" width={120} height={40} />
            </Box>
            <Box display="flex" gridGap={10}>
              <Skeleton animation="wave" variant="text" width={120} height={40} />
              <Skeleton animation="wave" variant="text" width={120} height={40} />
            </Box>
            <Box display="flex" gridGap={10}>
              <Skeleton animation="wave" variant="text" width={120} height={40} />
              <Skeleton animation="wave" variant="text" width={120} height={40} />
            </Box>
          </Box>
          <Box pt={0.5}>
            <Box display="flex" gridGap={10}>
              <Skeleton animation="wave" variant="text" width={120} height={40} />
              <Skeleton animation="wave" variant="text" width={120} height={40} />
            </Box>
            <Box display="flex" gridGap={10}>
              <Skeleton animation="wave" variant="text" width={120} height={40} />
              <Skeleton animation="wave" variant="text" width={120} height={40} />
            </Box>
            <Box display="flex" gridGap={10}>
              <Skeleton animation="wave" variant="text" width={120} height={40} />
              <Skeleton animation="wave" variant="text" width={120} height={40} />
            </Box>
          </Box>
        </StatsCard>
      ))}
    </>
  );
};
