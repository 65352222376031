// @flow
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { confirm, showAlert, showFile, showFileExplorer } from 'actions/app';
import clsx from 'clsx';
import API from 'services/API';
import type { AttachmentCategory, FileExplorerSection } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';
import { FileStatus } from 'UI/constants/status';
import { CloseIcon, colors, SvgUploadButton } from 'UI/res';
import { getErrorMessage, nestTernary } from 'UI/utils';

import { useStyles } from './styles';

type FileFieldProps = {
  /** List of accepted file types */
  acceptFiles: string[],
  /** The related category of the file */
  category: AttachmentCategory,
  /** Additional class names  */
  className: string,
  /** Default entity type assgined to  new files */
  defaultEntityType: string,
  /** Wheter to disable the field */
  disabled?: boolean,
  /** Endpoint to remove the uploaded file. If no specified, by default uses endpoint */
  deleteEndpoint?: string,
  /** The endpoint to which the file is uploaded */
  endpoint: string,
  /** Error text from validation form */
  errorText?: string,
  /** Sections to show in File Explorer */
  fileExplorerSections: FileExplorerSection[],
  /** Initial value for existing files */
  initialValue?: any,
  /** The name of the field */
  name: string,
  /** Event fired when the selected files changes */
  onChange: (name: string, value: any) => any,
  /** Event fired when the upload starts */
  onUploadStarted?: (name: string) => void,
  /** Wheter to only remove a local file reference or completely remove the file from the server   */
  softDeleteForExistingItems?: boolean
};

const FileField = (props: FileFieldProps) => {
  const {
    acceptFiles,
    category,
    className,
    defaultEntityType,
    disabled,
    deleteEndpoint,
    endpoint,
    errorText,
    initialValue,
    name: fieldName,
    onChange,
    onUploadStarted,
    softDeleteForExistingItems,
    fileExplorerSections
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  const [file, setFile] = useState(initialValue);

  const { title, required } = category;
  const { isUploading, uploadError } = file || {};
  const fileName = file?.original_name || file?.file_name || file?.fileName;
  const canUseFileExplorer =
    fileExplorerSections &&
    fileExplorerSections.length > 0 &&
    fileExplorerSections.some(section => section.files?.length > 0);

  const handleFileChange = e => {
    const { files } = e.target;
    files.length && uploadFile(files[0]);
  };

  const uploadFile = async att => {
    const data = new FormData();
    data.append('file', att);

    setFile({
      file_name: att.name,
      isUploading: true,
      uploadError: null,
      type: FileStatus.New
    });

    try {
      onUploadStarted && onUploadStarted(fieldName);
      const uploadResponse = await API.post(endpoint, data);
      const uploadedFile = uploadResponse?.data;

      if (uploadedFile) {
        setFile({ ...uploadedFile, isUploading: false, type: FileStatus.New });
        onChange(fieldName, {
          ...uploadedFile,
          file_type_id: category.id,
          entity: defaultEntityType
        });
      }
    } catch (error) {
      setFile(prevState => ({
        ...prevState,
        isUploading: false,
        uploadError: getErrorMessage(error)
      }));
    }
    fileRef?.current?.value && (fileRef.current.value = '');
  };

  const deleteRemoteFile = async () => {
    setFile(prevState => ({ ...prevState, isUploading: true }));
    try {
      const finalEndpoint = deleteEndpoint || endpoint;
      const response = await API.delete(`${finalEndpoint}/${file.id}`);
      if (response.status === 200) {
        setFile(null);
        onChange(fieldName, null);
      } else {
        setFile(prevState => ({
          ...prevState,
          isUploading: false,
          uploadError: 'The file could not be deleted'
        }));
      }
    } catch (err) {
      setFile(prevState => ({
        ...prevState,
        isUploading: false,
        uploadError: getErrorMessage(err)
      }));
    }
  };

  const confirmDeleteFile = async () => {
    if (!file) return;

    if (file.uploadError) {
      setFile(null);
      return;
    }

    if (
      (softDeleteForExistingItems && file.type !== FileStatus.New) ||
      file.type === FileStatus.Existing
    ) {
      setFile(null);
      onChange(fieldName, null);
      return;
    }

    dispatch(
      confirm({
        severity: 'warning',
        title: 'Please confirm',
        message: `Are you sure you want to delete '${fileName}'?`,
        onConfirm: async ok => {
          try {
            if (!ok) return;
            if (file.type === FileStatus.New) {
              await deleteRemoteFile();
            }
          } catch (err) {
            showAlert({
              severity: 'error',
              title: 'Attachments',
              body: getErrorMessage(err)
            });
          }
        }
      })
    );
  };

  const handleFileSelect = files => {
    const selectedFile = files?.length > 0 ? files[0] : null;
    setFile(selectedFile);
    onChange(fieldName, { ...selectedFile, file_type_id: category.id });
  };

  const selectOrUploadFile = () =>
    canUseFileExplorer
      ? dispatch(
          showFileExplorer({
            endpoint,
            defaultEntityType,
            title: `${title} selection`,
            sections: fileExplorerSections,
            onSelect: handleFileSelect
          })
        )
      : fileRef.current.click();

  const handleClick = async e => {
    e.stopPropagation();
    if (file) {
      await confirmDeleteFile();
    } else {
      selectOrUploadFile();
    }
  };

  const handleInputClick = async () => {
    file?.url
      ? dispatch(
          showFile({
            url: file.url,
            explicitFileName: fileName,
            useProxy: true
          })
        )
      : selectOrUploadFile();
  };

  const tooltipText = file
    ? 'Clear'
    : nestTernary(canUseFileExplorer, 'Select a file', 'Upload a file');

  const iconColor = !disabled ? colors.oxford : colors.lightgray;
  return (
    <>
      <TextBox
        name={fieldName}
        label={`${title} ${required ? '*' : ''}`}
        placeholder="Choose a file"
        value={fileName || ''}
        error={!!(errorText || uploadError)}
        errorText={errorText || uploadError}
        className={clsx(classes.input, className)}
        onClick={handleInputClick}
        InputProps={{
          readOnly: true,
          endAdornment: !disabled && (
            <FPIconButton
              onClick={handleClick}
              disabled={isUploading || disabled}
              tooltipProps={{ title: tooltipText }}
            >
              {isUploading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                nestTernary(
                  file,
                  <CloseIcon fill={iconColor} />,
                  <FPIcon color={iconColor} size={24} component={SvgUploadButton} />
                )
              )}
            </FPIconButton>
          )
        }}
        outPutValue={disabled}
      />
      <input
        ref={fileRef}
        type="file"
        accept={acceptFiles.join(',')}
        hidden
        onChange={handleFileChange}
      />
    </>
  );
};

FileField.defaultProps = {
  acceptFiles: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    '.pdf',
    '.docx',
    '.doc',
    '.pptx',
    '.ppt',
    '.xlsx',
    '.xls',
    '.csv',
    '.zip'
  ],
  disabled: false,
  deleteEndpoint: null,
  errorText: null,
  initialValue: null,
  onChange: () => {},
  onUploadStarted: undefined,
  softDeleteForExistingItems: false,
  fileExplorerSections: [],
  className: null
};

export default FileField;
