import { styled } from '@material-ui/core';

export const CountryName = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.grey[600]
}));

export const StatesNames = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.grey[600]
}));

export const PILMapContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flexGrow: 1
}));

export const CountriesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3)
}));

export const MapContainer = styled('div')({
  width: '100%',
  height: '300px',
  position: 'relative'
});
