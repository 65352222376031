export const INLINE_FILTERS_KEYS = Object.freeze({
  dominate: {
    hotInventory: 'dominate-hot-inventory',
    interviews: 'dominate-interviews',
    outstandingBillings: 'dominate-outstanding-billings'
  },
  activity: {
    weeklyPerformance: 'activity-weekly-performance',
    weeklyPerformanceByTeam: 'activity-weekly-performance-by-team'
  }
});
