// @flow
import React from 'react';
import { Chart } from 'react-google-charts';
import { Widget } from 'features/command-center';
import strings from 'strings';
import { AutocompleteByWeek } from 'UI/components/atoms/AutocompleteByWeek';

import { fakePromise } from '../../shared/GaugeStats/GaugeStats.constants';

import { DEFAULT_OPTIONS, RESPONSE_DATA } from './GoalsProgressInTime.utils';

const STRINGS = strings.commandCenter.activity.goalsProgressInTime;

interface GoalsProgressInTimeProps {
  onSelectWeek?: (week: object) => void;
}

export const GoalsProgressInTime = ({ onSelectWeek }: GoalsProgressInTimeProps) => {
  return (
    <Widget header={STRINGS.title} promises={[() => fakePromise(RESPONSE_DATA)]}>
      {([results]) => {
        return (
          <>
            <AutocompleteByWeek onSelectWeek={onSelectWeek} />

            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={results}
              options={DEFAULT_OPTIONS}
            />
          </>
        );
      }}
    </Widget>
  );
};
