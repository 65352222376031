// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import { connect, useDispatch } from 'react-redux';
import bbox from '@turf/bbox';
import { resetFilters, searchInventory, selectEntity, selectRecruiter } from 'actions/map';
import { useSearchProject } from 'hooks/searchProject';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import multiPoint from 'turf-multipoint';
import FPMap from 'UI/components/atoms/FPMap';
import SaveButton from 'UI/components/atoms/SaveButton';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import PopUpContent from 'UI/components/organisms/PopUpContent';
import { usCenterCoordinates } from 'UI/constants/defaults';
import { SearchProjectItemType } from 'UI/constants/entityTypes';
import { relDiff, roundDecimals } from 'UI/utils';

import { useStyles } from './styles';

type MapBoxProps = {
  markers: Array<any>,
  isDigActive: boolean,
  entityType?: string,
  selectedRecruiter?: any,
  selectedEntity?: any,
  isSideMenuOpen: boolean,
  activeTab: number,
  filters: any,
  isLoading: boolean,
  hasLoaded: boolean
};

type MercatorViewport = {
  width?: number,
  height?: number,
  longitude: number,
  latitude: number,
  zoom?: number,
  pitch?: number,
  bearing?: number,
  altitude?: number,
  nearZMultiplier?: number,
  farZMultiplier?: number
};

const MINIMUM_ZOOM_FOR_SEARCH_AREA = 3.5;

/* MapboxGL crashes if we try to move the viewport to the same coordinates. This function is intended to avoid this behaviour.
 * Also it's necessary to ask for a relative difference because sometimes because of floating point operations the viewport comparisons
 * don't work with strict equality
 */
const isSameViewport = (viewportLat, viewportLong, lat, long) => {
  const numberOfDecimals = 7;
  return (
    relDiff(roundDecimals(lat, numberOfDecimals), roundDecimals(viewportLat, numberOfDecimals)) <
      0.01 &&
    relDiff(roundDecimals(long, numberOfDecimals), roundDecimals(viewportLong, numberOfDecimals)) <
      0.01
  );
};

const MapBox = ({
  entityType,
  isDigActive,
  markers,
  selectedRecruiter,
  selectedEntity,
  isSideMenuOpen,
  activeTab,
  filters,
  isLoading,
  hasLoaded
}: MapBoxProps) => {
  const [viewport, setViewport] = useState({
    latitude: usCenterCoordinates.latitude,
    longitude: usCenterCoordinates.longitude,
    zoom: 4,
    width: '100%',
    height: '100vh'
  });
  const [popupInfo, setPopupInfo] = useState();
  const [visiblePoints, setVisiblePoints] = useState();
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const latestViewport = useRef<MercatorViewport>(usCenterCoordinates);
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const fitToCoordinates = useCallback(() => {
    if (!visiblePoints || !visiblePoints.length) return;

    const newViewport = new WebMercatorViewport(latestViewport.current);
    const pointsFeature = multiPoint(visiblePoints);
    const [minLng, minLat, maxLng, maxLat] = bbox(pointsFeature);

    if (newViewport.width === 1) return;

    const {
      longitude: newLong,
      latitude: newLat,
      zoom: newZoom
    } = newViewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      { padding: 100 }
    );

    if (
      !isSameViewport(
        latestViewport.current.latitude,
        latestViewport.current.longitude,
        newLat,
        newLong
      )
    ) {
      // the map crashes if move to same viewport
      onViewportChange({
        longitude: newLong,
        latitude: newLat,
        zoom: newZoom > 19 ? 10 : newZoom,
        transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
        transitionDuration: 'auto'
      });
    }
  }, [visiblePoints]);

  useEffect(() => {
    setPopupInfo(null);

    if (!markers || !markers.length) return;

    const points = markers.map(mkr => (mkr.longitude ? [mkr.longitude, mkr.latitude] : []));
    setVisiblePoints(points);
  }, [markers]);

  useEffect(() => {
    fitToCoordinates();
  }, [visiblePoints, fitToCoordinates]);

  useEffect(() => {
    setVisiblePoints([]);

    return () => {
      dispatch(selectRecruiter(null));
      dispatch(selectEntity(null));
      dispatch(resetFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedRecruiter) return;

    const recruiterStates = selectedRecruiter.states;

    if (recruiterStates && recruiterStates.length === 1) {
      if (
        !isSameViewport(
          latestViewport.current.latitude,
          latestViewport.current.longitude,
          selectedRecruiter.latitude,
          selectedRecruiter.longitude
        )
      ) {
        onViewportChange({
          longitude: selectedRecruiter.longitude,
          latitude: selectedRecruiter.latitude,
          zoom: 5,
          transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
          transitionDuration: 'auto'
        });
      }
    } else {
      const points = recruiterStates.map(state => [state.longitude, state.latitude]);
      setVisiblePoints(points);
    }

    setPopupInfo(selectedRecruiter);
  }, [selectedRecruiter]);

  useEffect(() => {
    if (!selectedEntity) return;
    setPopupInfo(selectedEntity);
  }, [selectedEntity]);

  const onMarkerEnter = info => {
    setPopupInfo(info);
    dispatch(selectEntity(info));
  };

  const onViewportChange = vp => {
    latestViewport.current = vp;
    setViewport(vp);
  };

  const filtersObj = filtersToParams(filters);

  const entityId = {
    candidate: {
      id: SearchProjectItemType.Candidate,
      name: 'Candidate'
    },
    joborder: {
      id: SearchProjectItemType.HiringAuthority,
      name: 'Job Order'
    },
    company: {
      id: SearchProjectItemType.Company,
      name: 'Company'
    }
  };

  const filteredItems = {
    type: 'include',
    data: [
      {
        id: selectedEntity?.id,
        type_id: entityId[entityType]?.id,
        type: entityId[entityType]?.name
      }
    ],
    count: 1
  };
  const paramsStringified = queryString.stringify(filtersObj, { arrayFormat: 'comma' });
  const queryParams = {
    params: paramsStringified,
    type: 'map'
  };
  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  const closePopup = () => {
    setPopupInfo(null);
    dispatch(selectEntity(null));
  };

  const renderSearchOnThisAreaButton = () => {
    const btnText = 'Search on this area';
    const areaFilters = filters;

    const handleOnSave = () => {
      const { _ne: northEast, _sw: southWest } = mapRef.current.getMap().getBounds();
      const coordinates = {
        ne: northEast,
        sw: southWest
      };
      areaFilters.area = { paramName: 'area', value: JSON.stringify(coordinates) };
      areaFilters.countries = { paramName: 'countryIds', value: [] };
      areaFilters.states = { paramName: 'stateIds', value: [] };
      areaFilters.cities = { paramName: 'cityIds', value: [] };
      areaFilters.cityRadius = { paramName: 'cityRadius', value: '' };
      areaFilters.zips = { paramName: 'zips', value: [] };
      areaFilters.radius = { paramName: 'radius', value: '' };
      dispatch(searchInventory(areaFilters));
    };

    if (!isEmpty(filters) && markers.length && viewport.zoom >= MINIMUM_ZOOM_FOR_SEARCH_AREA) {
      return (
        <div className={classes.searchBtnContainer} id="search-btn-container">
          <SaveButton
            isSaving={isLoading}
            isSuccess={hasLoaded}
            disabled={isLoading}
            initialText={btnText}
            onProgressText="Searching ..."
            onSuccessText={btnText}
            type="button"
            onClick={handleOnSave}
            className={classes.searchBtn}
          />
        </div>
      );
    }
    return null;
  };

  const displayFiltersClass = isSideMenuOpen ? classes.filtersOpen : classes.filtersClosed;

  const onQuickViewClick = () => {
    setIsQuickViewOpen(!isQuickViewOpen);
  };

  const handleClosePopUp = () => setPopupInfo(null);

  return (
    <div className={`${classes.mapContainer} ${displayFiltersClass}`}>
      <FPMap
        viewport={viewport}
        onViewportChange={onViewportChange}
        onMapClick={closePopup}
        mapRef={mapRef}
        onMarkerEnter={onMarkerEnter}
        isDigActive={isDigActive}
        markers={markers}
        entityType={entityType}
        popupInfo={popupInfo}
        onClosePopup={handleClosePopUp}
        renderPopupContent={() => (
          <PopUpContent
            info={popupInfo}
            isDigActive={isDigActive}
            entityType={entityType}
            onQuickViewClick={onQuickViewClick}
            SearchProjectAction={SearchProjectAction}
          />
        )}
      >
        {activeTab === 1 && renderSearchOnThisAreaButton()}
      </FPMap>
      <SearchProjectForms />
      <When condition={entityId && isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={entityType}
          onClose={onQuickViewClick}
          id={selectedEntity?.id}
        />
      </When>
    </div>
  );
};

MapBox.defaultProps = {
  entityType: '',
  selectedRecruiter: undefined,
  selectedEntity: null
};

const mapStateToProps = ({ map }) => {
  return {
    markers: map.domain.markers,
    entityType: map.domain.filters.entityType?.value?.id,
    selectedRecruiter: map.ui.selectedRecruiter,
    selectedEntity: map.ui.selectedEntity,
    isDigActive: map.ui.activeTab === 0,
    isSideMenuOpen: map.ui.isSideMenuOpen,
    activeTab: map.ui.activeTab,
    filters: map.domain.filters,
    isLoading: map.ui.isLoading,
    hasLoaded: map.ui.hasLoaded
  };
};

const MapBoxConnected = connect(mapStateToProps, null)(MapBox);

export default MapBoxConnected;
