import React from 'react';
import GaugeChart from 'react-gauge-chart';
import Typography from '@material-ui/core/Typography';
import { AutocompleteByWeek } from 'UI/components/atoms/AutocompleteByWeek';

import { StatsItemContainer } from '../StatsItemContainer';

import { CHART_COLORS } from './GaugeStats.constants';
import { useStyles } from './GaugeStats.styles';

export const GaugeStats = ({ id, stats, week, onSelectWeek, weeks }) => {
  const classes = useStyles();
  const percent = stats[0]?.percentage ?? 0;

  return (
    <>
      <AutocompleteByWeek value={week} onSelectWeek={onSelectWeek} defaultOptions={weeks} />

      <div className={classes.flexAlignCenterSpaceBetween}>
        <div className={classes.gaugeChartContainer}>
          <Typography className={classes.textCenter}>50%</Typography>
          <GaugeChart
            id={id}
            arcPadding={0.0}
            arcsLength={[0.5, 0.3, 0.2]}
            colors={[CHART_COLORS.red, CHART_COLORS.yellow, CHART_COLORS.green]}
            cornerRadius={0}
            hideText
            needleBaseColor={CHART_COLORS.needle}
            needleColor={CHART_COLORS.needle}
            needleScale={1}
            percent={percent > 1 ? 1 : percent}
          />

          <Typography variant="h6" className={classes.textCenter}>
            {Math.round(percent * 100)}%
          </Typography>
        </div>

        <StatsItemContainer items={stats} />
      </div>
    </>
  );
};
