// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { confirm, showAlert } from 'actions/app';
import { buildMultiplePhonesActionsRenderer } from 'hooks/datatable';
import { useProfileQuickView } from 'hooks/useProfileQuickView';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import LinkEntitiesForm from 'UI/components/organisms/LinkEntitiesForm';
import { CommunicationActionPreset } from 'UI/constants/defaults';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, entityTypes, TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgNoCandidates } from 'UI/res/icons/milano';
import { getErrorMessage } from 'UI/utils';
import { Renderers } from 'UI/utils/renderers';
import { updateUiState } from 'UI/utils/uiHelpers';

import FPQuickView from '../FPQuickView';

import { candidateColumns, CustomCandidateRenders } from './columns';

type CandidatesTabProps = {
  joborderId: string,
  endpoint: string,
  extraRenderers: Object,
  DataTableProps: ProfileDataTableProps
};

const {
  inventoryProfiles: {
    sections: {
      candidates,
      jobOrders: { deleteAlert }
    }
  }
} = strings;

const { emptyState } = componentDimensions;

const CandidatesTab = ({
  endpoint,
  joborderId,
  DataTableProps,
  extraRenderers,
  profileName,
  ...rest
}: CandidatesTabProps) => {
  const dispatch = useDispatch();
  const [uiState, setUiState] = useState({
    isCandidateOpen: false,
    shouldRefreshTableData: false
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C A N D I D A T E    A C T I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const toggleCandidateOpen = () =>
    updateUiState(
      { isCandidateOpen: !uiState.isCandidateOpen, shouldRefreshTableData: false },
      setUiState
    );

  const handleCandidateCompleted = () => {
    updateUiState({ shouldRefreshTableData: true }, setUiState);
    toggleCandidateOpen();
  };

  const handleDeleteCandidateClick = (item: any, getData) => {
    dispatch(
      confirm({
        severity: 'warning',
        title: 'Please confirm',
        message: `Are you sure you want to remove this candidate from the Job Order?`,
        onConfirm: async ok => {
          if (!ok) return;

          try {
            const response = await API.delete(`${endpoint}/remove-candidate/${item.id}`);

            if (response.status === 200) {
              dispatch(showAlert(deleteAlert));
              getData();
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Error',
                body: getErrorMessage(error)
              })
            );
          }
        }
      })
    );
  };

  const finalActions = [
    ...CommunicationActionPreset,
    { name: 'delete', callback: handleDeleteCandidateClick }
  ];

  const getSpecificTabColumns = handleAction => [
    buildMultiplePhonesActionsRenderer({
      customName: 'phoneNumbers',
      handleAction,
      featureFlagToCheck: FeatureFlags.CandidatesTabMultiplePhones
    })
  ];

  const {
    entityId,
    handleQuickViewPreview,
    itemsToNavigate,
    quickViewEntity,
    quickViewState,
    toggleQuickViewPreview
  } = useProfileQuickView({ quickViewEntityRoleProps: null });

  const handleRefreshTab = async () => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
  };

  const handleTableDataLoaded = () => async () => {
    await updateUiState({ shouldRefreshTableData: false }, setUiState);
  };

  return (
    <>
      <ProfileTableLayout
        actionText="Match Candidate"
        tabKey={TabKeys.Candidates}
        initialColumns={candidateColumns}
        customColumnsExtender={getSpecificTabColumns}
        profileModule={EntityType.Candidate}
        onNewItemClick={toggleCandidateOpen}
        extraRenderers={CustomCandidateRenders}
        DataTableProps={{
          onFetchedData: uiState.shouldRefreshTableData && handleTableDataLoaded,
          ...DataTableProps
        }}
        finalActions={finalActions}
        shouldRefresh={uiState.shouldRefreshTableData}
        customEmptyState={<SvgNoCandidates size={emptyState.width} />}
        hasProfileLoaded={joborderId}
        enableQuickView
        onQuickViewClick={handleQuickViewPreview}
        {...rest}
      />

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          id={entityId}
          itemsToNavigate={itemsToNavigate}
          onEditionCompleted={handleRefreshTab}
        />
      </When>

      <Drawer open={uiState.isCandidateOpen} onClose={toggleCandidateOpen}>
        <div role="presentation">
          <LinkEntitiesForm
            title="MATCH CANDIDATE"
            placeholder={candidates.drawerPlaceholder}
            sourceEntity={entityTypes.find(each => each.id === EntityType.Candidate)}
            idFieldName="candidateId"
            additionalSearchColumns={['pst.title', 'can.email', 'can.title']}
            endpointToSaveEntity={`${Endpoints.JobOrders}/${joborderId}/${Endpoints.CandidateAssignment}`}
            onCompleted={handleCandidateCompleted}
            onClosed={toggleCandidateOpen}
            displayTemplate={Renderers.displayBasicInfoEntity}
            profileName={profileName}
            externalLink={option =>
              Renderers.externalLink(
                EntityRoutes.CandidateProfile.replace(':id', option.id),
                'View Candidate'
              )
            }
          />
        </div>
      </Drawer>
    </>
  );
};

export default CandidatesTab;
