import React from 'react';
import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import { getFilterDefinitionByName } from 'UI/components/templates/SideFiltersLayout/filters';
import { FilterType } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import { CollectionDateFilters } from 'UI/pages/Collections/Collections.columns';
import { INITIAL_DATE_FILTER } from 'UI/pages/Collections/hooks/useCollectionsListing';
import { Groupers, Selectors } from 'UI/utils/renderers';

import { LabelMultipleTags } from './InlineFilters.styled';

const renderMultipleTags = (selected, displayKey = 'title') => {
  if (!selected) return null;

  const tagsValue = selected.map(item => item[displayKey]).join(', ');

  return (
    <LabelMultipleTags variant="body1" noWrap>
      {tagsValue}
    </LabelMultipleTags>
  );
};

export const InlineFiltersConfig = [
  {
    type: 'dateRange',
    name: 'dateRange',
    placeholder: 'Date Range'
  },
  {
    type: 'date',
    name: 'startDate',
    title: 'Start Date'
  },
  {
    name: 'state',
    paramName: 'stateIds',
    title: 'State',
    type: FilterType.Autocomplete,
    url: `${Endpoints.States}`,
    groupBy: Groupers.byCountry,
    getOptionSelected: Selectors.byId,
    multiple: true,
    renderTags: renderMultipleTags,
    disableListWrap: true
  },
  {
    name: 'industries',
    paramName: 'industryIds',
    title: 'Industry',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Industries}?standalone=true`,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: true
  },
  {
    ...getFilterDefinitionByName('specialties'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('subspecialties'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    name: 'recruiters',
    paramName: 'recruiterIds',
    title: 'Recruiter',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Recruiter}`,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: true
  },
  {
    name: 'tenures',
    paramName: 'tenures',
    title: 'Tenure',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    url: ENDPOINTS.tenures,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: true,
    idKey: 'title'
  },
  {
    name: 'healthStatus',
    paramName: 'status',
    title: 'Health Status',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    api: 'secondary',
    url: `${COMMAND_CENTER_BASE_URL}/catalog/health-statuses`,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: false,
    idKey: 'title'
  },
  {
    name: 'dueCommitCollectionsDate',
    type: FilterType.Autocomplete,
    options: CollectionDateFilters,
    title: '',
    defaultValue: INITIAL_DATE_FILTER,
    disableClearable: true,
    hideActiveFilter: true
  },
  {
    ...getFilterDefinitionByName('collectionsCurrentTeam'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('collectionsTeam'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  }
];
