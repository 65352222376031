import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getWeekActivity } from 'features/command-center/services';
import strings from 'strings';

export const WeeklyActivityStatsSummary = ({ requestParams }) => {
  const { recruiter } = requestParams;
  const groupBy = recruiter ? 'recruiter' : 'team';
  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyActivityStatsSummary.title}
      promise={() => getWeekActivity({ ...requestParams, groupBy })}
      subheader={strings.commandCenter.activity.weeklyActivityStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
