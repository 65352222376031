import { SIZES } from 'features/command-center/components/shared/StatsItem/StastsItem.constants';
import { METRICS, METRICS_MAP, METRICS_TITLE } from 'features/command-center/constants/metrics';
import {
  getFinanceHealthSummaryByRegionalDirector,
  getFinanceSummaryByTeam,
  getFinanceSummaryByTeamByRegionalDirector
} from 'features/command-center/services';
import strings from 'strings';
import { EnvApiUrls } from 'UI/constants/defaults';
import { currencyFormatter, formatPercentage } from 'UI/utils';

const { financeHealthLabels } = strings.commandCenter;

export const getRegionalCardsData = async () => {
  const gottenData = await Promise.all([
    getFinanceSummaryByTeamByRegionalDirector(),
    getFinanceHealthSummaryByRegionalDirector(),
    getFinanceSummaryByTeam()
  ]);

  const [summaryByTeam, healthSummary, prodAndCashIn] = gottenData;

  const cleanData = healthSummary.data.reduce((acc, health) => {
    const teamSummary = summaryByTeam.data.find(
      team => health.managerEmpNo === team.managerEmpNo
    ) || {
      production: 0,
      cashIn: 0,
      perDeskAverage: 0,
      adjustedAverage: 0
    };

    const teamProdAndCashIn = prodAndCashIn.data.find(
      team => health.managerEmpNo === team.managerEmpNo
    ) || {
      production: 0,
      cashIn: 0
    };

    const createdItem = {
      id: teamSummary.managerEmpNo,
      imgURL: `${EnvApiUrls.GPAC_CDN_URL}/common/images/${teamSummary.managerEmpNo}.png`,
      title: String(teamSummary.managerName).toLowerCase(),
      healthStats: [
        {
          size: SIZES.small,
          title: 'Recruiters',
          value: `${health[METRICS_MAP.Headcount].total} (${
            health[METRICS_MAP.Headcount].percentage
          }%)`,
          valueColor: METRICS[METRICS_MAP.Headcount].color
        },
        {
          size: SIZES.small,
          title: METRICS_TITLE[METRICS_MAP.OnTrack],
          value: `${health[METRICS_MAP.OnTrack].total} (${
            health[METRICS_MAP.OnTrack].percentage
          }%)`,
          valueColor: METRICS[METRICS_MAP.OnTrack].color
        },
        {
          size: SIZES.small,
          title: METRICS_TITLE[METRICS_MAP.AtRisk],
          value: `${health[METRICS_MAP.AtRisk].total} (${health[METRICS_MAP.AtRisk].percentage}%)`,
          valueColor: METRICS[METRICS_MAP.AtRisk].color
        },
        {
          size: SIZES.small,
          title: METRICS_TITLE[METRICS_MAP.Behind],
          value: `${health[METRICS_MAP.Behind].total} (${health[METRICS_MAP.Behind].percentage}%)`,
          valueColor: METRICS[METRICS_MAP.Behind].color
        },
        {
          size: SIZES.small,
          title: METRICS_TITLE[METRICS_MAP.InTraining],
          value: `${health[METRICS_MAP.InTraining].total} (${
            health[METRICS_MAP.InTraining].percentage
          }%)`,
          valueColor: METRICS[METRICS_MAP.InTraining].color
        },
        {
          size: SIZES.small,
          title: METRICS_TITLE[METRICS_MAP.Under3Months],
          value: `${health[METRICS_MAP.Under3Months].total} (${
            health[METRICS_MAP.Under3Months].percentage
          }%)`,
          valueColor: METRICS[METRICS_MAP.Under3Months].color
        }
      ],
      financeSummary: [
        {
          size: SIZES.small,
          title: financeHealthLabels.monthProduction,
          value: currencyFormatter(teamProdAndCashIn.production, 1, { notation: 'compact' })
        },
        {
          size: SIZES.small,
          title: financeHealthLabels.ytdProduction,
          value: currencyFormatter(teamSummary.production, 1, { notation: 'compact' })
        },
        {
          size: SIZES.small,
          title: financeHealthLabels.monthCashIn,
          value: currencyFormatter(teamProdAndCashIn.cashIn, 1, { notation: 'compact' })
        },
        {
          size: SIZES.small,
          title: financeHealthLabels.ytdCashIn,
          value: currencyFormatter(teamSummary.cashIn, 1, { notation: 'compact' })
        },
        {
          size: SIZES.small,
          title: financeHealthLabels.pdaYtd,
          value: currencyFormatter(teamSummary.perDeskAverage, 1, { notation: 'compact' })
        },
        {
          size: SIZES.small,
          title: financeHealthLabels.adjustedAverage,
          value: formatPercentage(teamSummary.adjustedAverage, 1)
        }
      ]
    };
    acc.push(createdItem);

    return acc;
  }, []);

  return cleanData;
};
