export const colors = {
  active: '#27AE60',
  appBackground: '#F0F3F7',
  appBackgroundContrast: '#ECEFF5',
  black: '#1A1C21',
  blueStatus: '#50C9F5',
  borderColor: '#ECEDF0',
  candidate: { background: '#E2D9FB', icon: '#A38FF4' },
  completeBlack: '#000000',
  componentFill: '#CDD8E6',
  darkGrey: '#525764',
  disabledIcon: '#abacae',
  error: '#B00020',
  glipMessage: '#F95B5C',
  greenStatus: '#B0F461',
  grey: '#9CA6B5',
  greyFocus: '#a9a9a9',
  iconInactive: '#A3A8B4',
  inactive: '#9B9EA7',
  inactiveInputText: '#00000099',
  inactiveSideBarTab: '#E1E4EB',
  lightBackground: '#FBFCFD',
  lightButtonHover: '#e5eafa9e',
  lightgray: '#bbbbbb',
  lightgrey: '#f2f2f2',
  lightHover: '#F6F7F9',
  lightText: '#969BA5',
  linkWater: '#E5EAFA',
  middleGrey: '#D4D7DD',
  navLink: '#4B5EB0',
  navyBlue: '#25307C',
  notificationRead: '#FAFBFD',
  notificationUnRead: '#F0F3F7',
  orange: '#F39C12',
  oxford: '#707070',
  placeholderGrey: '#9395A8',
  purple: '#6950F5',
  red: '#E9250C',
  ringCentral: '#fe8000',
  secondaryText: '#777777',
  sideBar: '#FAFBFD',
  silverGrey: '#F7F7F7',
  skeletonBar: '#D7DFEA',
  softBack: '#F7FBFF',
  success: '#4056F4',
  transparent: 'transparent',
  treeItem: {
    selected: '#4056f414'
  },
  user: {
    purple: {
      background: '#E2D9FB',
      text: '#5C40F4'
    },
    red: {
      text: '#F2453D'
    }
  },
  white: '#FFFFFF',
  wolfImage: '#F6F8FA'
};
