import moment from 'moment';

export const getChartDayLabels = () => {
  return [
    { v: 0, f: 'Mon' },
    { v: 1, f: 'Tue' },
    { v: 2, f: 'Wed' },
    { v: 3, f: 'Thu' },
    { v: 4, f: 'Fri' }
  ];
};

export const getChartMonthLabels = isCurrentYear => {
  const monthLabels = [
    { v: 0, f: 'Jan' },
    { v: 1, f: 'Feb' },
    { v: 2, f: 'Mar' },
    { v: 3, f: 'Apr' },
    { v: 4, f: 'May' },
    { v: 5, f: 'Jun' },
    { v: 6, f: 'Jul' },
    { v: 7, f: 'Aug' },
    { v: 8, f: 'Sep' },
    { v: 9, f: 'Oct' },
    { v: 10, f: 'Nov' },
    { v: 11, f: 'Dec' }
  ];

  const totalMonths = isCurrentYear ? moment().month() : 12;
  return monthLabels.slice(0, totalMonths + 1);
};

export const formatTrendsResponse = ({ response, key, label }) => {
  if (!response) return [];

  const total = response.reduce((sum, item) => sum + item[key], 0);
  const average = total / response.length;

  const formattedData = [
    ['Days', label, 'Average'],
    ...response.map(item => {
      const [year, month, day] = item.date.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.toLocaleDateString('default', { weekday: 'short' });

      return [dayOfWeek, item[key], average];
    })
  ];

  return formattedData;
};
