// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import {
  CreatedFromVariants,
  createSearchProject,
  ParamsTypes,
  updateSearchProject
} from 'services/searchProjects';
import strings from 'strings';
import type { SearchProjectFormDrawerPropTypes } from 'types/app';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { SearchProjectPrivacyScopes } from 'UI/constants/defaults';
import { getErrorMessage } from 'UI/utils';

const {
  createForm: {
    visibility,
    nameInput,
    sectionLabels,
    title: createTitle,
    errors: createErrorCopies
  },
  updateForm: { title: updateTitle }
} = strings.searchProjects;
const radioOptions = [SearchProjectPrivacyScopes.private, SearchProjectPrivacyScopes.public];

const titleMap = {
  create: createTitle,
  update: updateTitle
};
const getPrivacyScope = scope => SearchProjectPrivacyScopes[scope];

const SearchProjectFormDrawer = ({
  bulkId = null,
  createdFrom = null,
  itemsToAdd = null,
  mode = 'create',
  moveCopiedItems = false,
  onClose,
  onSubmitSearchProject = null,
  open,
  queryParams = null,
  searchProject = null
}: SearchProjectFormDrawerPropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const { handleSubmit, register, setValue, watch, errors } = useForm({
    defaultValues:
      searchProject && typeof searchProject === 'object'
        ? {
            projectName: searchProject.name,
            scope: SearchProjectPrivacyScopes.getPrivacyState(searchProject.isPrivate).id
          }
        : {
            scope: SearchProjectPrivacyScopes.private.id
          }
  });
  const scopeWatcher = watch('scope');

  useEffect(() => {
    register({ name: 'scope' });
  }, [register]);

  const handleRadioChange = event => setValue('scope', event.target.value);
  const paramsType =
    createdFrom === CreatedFromVariants.BulkMetrics
      ? ParamsTypes.MetricsParams
      : ParamsTypes.SearchParams;
  const searchProjectActions = {
    create: async formData => {
      try {
        const response = await createSearchProject({
          ...formData,
          itemsToAdd,
          queryParams,
          searchProjectId: searchProject?.id,
          moveCopiedItems,
          paramsType,
          bulkId
        });
        const { success, data, alert } = response;
        dispatch(showAlert(alert));
        setUiState({ isSaving: false, isSuccess: success });

        if (!success) return;
        onSubmitSearchProject && onSubmitSearchProject(true, mode);
        setTimeout(() => {
          history.push(EntityRoutes.SearchProjectPreview.replace(':id', data.id), {
            createdFrom: createdFrom || 'default'
          });
        }, 1);
      } catch (error) {
        dispatch(
          showAlert({
            severity: 'error',
            title: createErrorCopies.unhandled.title,
            body: getErrorMessage(error)
          })
        );
      }
    },
    update: async formData => {
      const { data, success, alert } = await updateSearchProject({
        ...formData,
        id: searchProject?.id
      });

      dispatch(showAlert(alert));
      setUiState({ isSaving: false, isSuccess: success });
      onSubmitSearchProject && onSubmitSearchProject(success, mode, data);
    }
  };

  const onSubmit = async formData => {
    setUiState({ isSaving: true, isSuccess: false });
    const formattedFormData = {
      name: formData.projectName.trim(),
      isPrivate: getPrivacyScope(formData.scope).value
    };
    searchProjectActions[mode] && (await searchProjectActions[mode](formattedFormData));
  };

  return (
    <DrawerContentLayout
      title={titleMap[mode]}
      drawerProps={{ open }}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      uiState={uiState}
    >
      <Text text={sectionLabels.details} />
      <TextBox
        label={nameInput.label}
        name="projectName"
        inputRef={register({
          required: nameInput.requiredMessage,
          minLength: {
            value: 5,
            message: nameInput.minLengthMessage
          }
        })}
        error={!!errors.projectName}
        errorText={errors?.projectName?.message}
      />
      <FPRadioGroup
        options={radioOptions}
        onChange={handleRadioChange}
        value={scopeWatcher}
        adviceText={visibility[getPrivacyScope(scopeWatcher).id]}
      />
    </DrawerContentLayout>
  );
};

export default SearchProjectFormDrawer;
