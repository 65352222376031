import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getWeekProductivity } from 'features/command-center/services';
import strings from 'strings';

export const WeeklyProductivityStatsSummary = ({ requestParams }) => {
  const { recruiter } = requestParams;
  const groupBy = recruiter ? 'recruiter' : 'team';
  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyProductivityStatsSummary.title}
      promise={() => getWeekProductivity({ ...requestParams, groupBy })}
      subheader={strings.commandCenter.activity.weeklyProductivityStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
