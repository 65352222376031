import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const loaderHeight = '200px';

export const useStyles = makeStyles(() => ({
  container: {
    ...flexAlignCenterJustifyCenter,
    width: '100%',
    height: loaderHeight
  }
}));
