// @flow
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { THEME } from 'GlobalStyles';
import { PHONE_TYPES } from 'UI/components/organisms/PhoneNumbersForm/utils';
import { SvgMessageFilled, SvgPhoneFilled, SvgStar } from 'UI/res';

import PhoneItem from './PhoneItem';
import { useStyles } from './styles';

type PhoneActionRowProps = {
  phone: string,
  type: { id: number, title: string },
  onPhoneClick: () => void
};

const ICON_COLOR = THEME.palette.grey[600];
const DISABLED_COLOR = THEME.palette.grey[300];

const PhoneActionRow = ({ phone, type, use, isDefault, onPhoneClick }: PhoneActionRowProps) => {
  const classes = useStyles();

  const isSmsEnabled = type?.title !== PHONE_TYPES.Land;

  return (
    <div className={classes.phoneActionRowContainer}>
      <span className={classes.defaultIcon}>
        {isDefault && <SvgStar size={16} fill={ICON_COLOR} />}
      </span>
      <PhoneItem phone={phone} type={type?.title} use={use} />
      <div className={classes.actionsContainer}>
        <Tooltip title="Call" placement="bottom" arrow>
          <div>
            <a
              href={`tel:${phone}`}
              className={classes.actionButton}
              onClick={onPhoneClick}
              aria-label="call"
            >
              <SvgPhoneFilled size={14} fill={ICON_COLOR} />
            </a>
          </div>
        </Tooltip>
        <Tooltip title={isSmsEnabled ? 'Text' : ''} placement="bottom" arrow>
          <div>
            <a
              href={`sms:${phone}`}
              className={clsx(classes.actionButton, !isSmsEnabled && classes.disabledLink)}
              onClick={isSmsEnabled ? onPhoneClick : null}
              aria-label="text"
            >
              <SvgMessageFilled size={14} fill={isSmsEnabled ? ICON_COLOR : DISABLED_COLOR} />
            </a>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default PhoneActionRow;
