// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useGlobalStyles } from 'GlobalStyles';
import useDebounce from 'hooks/debounce';
import { useCheckEmail } from 'hooks/useCheckEmail';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { userHasRoles } from 'services/Authorization';
import strings from 'strings';
import { FPCard, FPCardContent, FPCardHeader } from 'UI/components/atoms/FPCard';
import FPIcon from 'UI/components/atoms/FPIcon';
import {
  DuplicateCandidateOverview,
  DuplicateCandidateSkeleton
} from 'UI/components/molecules/DuplicateCandidateOverview';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import NameForm from 'UI/components/organisms/NameForm';
import { FormFieldsMap } from 'UI/components/organisms/NameForm/fields';
import {
  formatPhoneNumbers,
  PHONE_FORMAT_MODES
} from 'UI/components/organisms/PhoneNumbersForm/utils';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import {
  DELAY_BOUNCE_TIME,
  drawerAnchor,
  FORTPAC_SUPPORT_LINK,
  PageTitles,
  waitingTimeBeforeRedirect
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { ContactRoleStrings } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { SeverityBySimilarity } from 'UI/constants/status';
import { SvgWarning } from 'UI/res';
import { getErrorMessage, hasFeatureFlag } from 'UI/utils';
import { decryptId } from 'UI/utils/encrypt';
import { prepareFormToSubmit, selectValidation } from 'UI/utils/forms';

const NewContact = ({ history, location }: NewContactProps) => {
  const dispatch = useDispatch();
  const isOpsOrDc = userHasRoles([Roles.Operations, Roles.DataCoordinator]);

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });
  const globalClasses = useGlobalStyles();

  const { firstName, lastName, currentPosition, currentCompany, email, phone, linkProfile } =
    queryString.parse(location.search);

  const { companyId, cName } = useMemo(() => {
    try {
      const { company, cName: name } = queryString.parse(location.search);
      return {
        companyId: company ? decryptId(company, true)[0] : null,
        cName: name
      };
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Invalid Company Reference.',
          body: error.message
        })
      );
      return {};
    }
  }, [dispatch, location.search]);

  const [initialValues, setInitialValues] = useState({
    source_type_id: { id: 1, title: 'LinkedIn' },
    name_status_id: {
      id: 0,
      name_type_id: 0,
      title: 'Undefined'
    }
  });

  const form = useForm(
    location.search
      ? {
          defaultValues: {
            [FormFieldsMap.FIRST_NAME.key]: firstName,
            [FormFieldsMap.LAST_NAME.key]: lastName,
            [FormFieldsMap.PHONE.key]: phone,
            [FormFieldsMap.COMPANY.key]: {
              id: companyId || null,
              name: cName || null
            },
            [FormFieldsMap.EMAIL.key]: email,
            [FormFieldsMap.TITLE.key]: currentPosition,
            current_company: currentCompany,
            [FormFieldsMap.LINKEDIN_URL.key]: linkProfile,
            [FormFieldsMap.NAME_STATUS.key]: initialValues.name_status_id.id,
            [FormFieldsMap.SOURCE.key]: initialValues.source_type_id.id
          }
        }
      : {}
  );
  const { handleSubmit, watch } = form;
  const watchValues = watch([FormFieldsMap.EMAIL.key, FormFieldsMap.OTHER_EMAIL.key]);
  const debouncedEmail = useDebounce(watchValues.email ?? '', DELAY_BOUNCE_TIME).trim();
  const debouncedOtherEmail = useDebounce(
    watchValues.personalEmail ?? '',
    DELAY_BOUNCE_TIME
  ).trim();

  const { validation: emailVerification, initialData } = useCheckEmail({
    email: debouncedEmail,
    shouldCheckContacts: true,
    hasEmail: !!watchValues.email
  });
  const { validation: otherEmailVerification } = useCheckEmail({
    email: debouncedOtherEmail,
    shouldCheckContacts: true,
    hasEmail: !!watchValues.personalEmail
  });
  const emailValidation = selectValidation(
    { ...emailVerification, email: debouncedEmail },
    { ...otherEmailVerification, email: debouncedOtherEmail },
    initialData
  );
  const { registeredData: registeredEmail } = emailValidation;

  useEffect(() => {
    document.title = PageTitles.ContactCreate;
  }, []);

  useEffect(() => {
    if (!Number(companyId) || !cName) return;
    const company = { id: Number(companyId), name: cName };
    setInitialValues(prev => ({ ...prev, company_id: company }));
  }, [cName, companyId]);

  const handleCancelClick = () => {
    history.goBack();
  };

  const enableSave = !uiState.isSaving && !emailValidation.isValidating;

  const shouldEnableSave = isOpsOrDc ? enableSave : enableSave && !emailValidation.isRegistered;

  const isMultiplePhonesEnabled = hasFeatureFlag(FeatureFlags.ContactsMultiplePhones);

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const compId =
        typeof formData.company_id === 'object' ? formData.company_id.id : formData.company_id;
      const nameData = { ...formData, company_id: compId };
      const preparedNameData = prepareFormToSubmit(nameData, FormFieldsMap);
      const preparedPhones = isMultiplePhonesEnabled
        ? formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit)
        : null;
      const dataToSend = {
        ...preparedNameData,
        phones: preparedPhones
      };
      const response = await API.post(Endpoints.Names, dataToSend);
      if (response.data && response.status === HTTPStatusCodes.Created) {
        setUiState(prevState => ({
          ...prevState,
          isSuccess: true
        }));
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.contacts.creation.alert.title,
            body: strings.contacts.creation.alert.success.body
          })
        );
        setTimeout(() => {
          history.push(EntityRoutes.ContactProfile.replace(':id', response.data.data.id));
        }, waitingTimeBeforeRedirect);
      }
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false
      }));
      dispatch(
        showAlert({
          severity: 'error',
          title: strings.contacts.creation.alert.title,
          autoHideDuration: 5000,
          body: getErrorMessage(err)
        })
      );
    }

    setUiState(prevState => ({
      ...prevState,
      isSaving: false
    }));
  };

  return (
    <ContentPageLayout
      text={strings.contacts.creation.title}
      titleLabelProps={{ backNavigation: true }}
    >
      <React.Fragment key={drawerAnchor}>
        <FormContext {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={globalClasses.itemCreationDataWrapper}>
              <div className={globalClasses.newItemsSection}>
                <FPCard>
                  <FPCardHeader
                    title={strings.contacts.creation.form.title}
                    subheader={strings.contacts.creation.form.subheader}
                    variant="section"
                  />
                  <FPCardContent variant="relaxed">
                    {emailValidation.severity !== SeverityBySimilarity.None && (
                      <>
                        <Alert
                          data-testid="alert-duplicate"
                          severity={emailValidation.severity}
                          action={
                            <Button
                              href={FORTPAC_SUPPORT_LINK}
                              rel="noopener noreferrer"
                              target="_blank"
                              variant="outlined"
                              color="inherit"
                              classes={{ root: globalClasses.alertActionButton }}
                            >
                              {strings.shared.app.support}
                            </Button>
                          }
                          icon={
                            <FPIcon
                              component={SvgWarning}
                              onSetColor={palette => palette[emailValidation.severity].light}
                            />
                          }
                        >
                          <>
                            {strings.formatString(
                              strings.candidates.creation.duplicateEmail.alert,
                              {
                                email: `"${emailValidation.email}"`,
                                entity:
                                  registeredEmail.entity === null
                                    ? ''
                                    : `as a ${ContactRoleStrings[registeredEmail.entity]}`
                              }
                            )}
                          </>
                        </Alert>
                        {emailValidation.isValidating ? (
                          <DuplicateCandidateSkeleton />
                        ) : (
                          <DuplicateCandidateOverview
                            key={registeredEmail.id}
                            data={registeredEmail}
                          />
                        )}
                        <Divider />
                      </>
                    )}

                    <NameForm initialValues={location.search ? initialValues : {}} />
                  </FPCardContent>
                </FPCard>
              </div>
              <div className={globalClasses.newItemsActions}>
                <FooterActionsControlsLegacy
                  secondaryAction={handleCancelClick}
                  primaryProps={{
                    isSaving: uiState.isSaving,
                    isSuccess: uiState.isSuccess,
                    disabled: !shouldEnableSave
                  }}
                />
              </div>
            </div>
          </form>
          {(uiState.isLoading || emailValidation.isValidating) && (
            <Grid container justify="flex-end">
              <CircularProgress size={30} />
            </Grid>
          )}
        </FormContext>
      </React.Fragment>
    </ContentPageLayout>
  );
};

export default NewContact;
