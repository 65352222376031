// @flow
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import useMultipleSelection, { SearchProjectBar } from 'hooks/multipleSelection';
import {
  CreatedFromVariants,
  ParamsTypes,
  removeItemsFromSearchProject
} from 'services/searchProjects';
import strings from 'strings';
import AddToSearchProjectDrawer from 'UI/components/organisms/AddToSearchProjectDrawer';
import DataTable from 'UI/components/organisms/DataTable';
import SearchProjectFormDrawer from 'UI/components/organisms/SearchProjectFormDrawer';
import { SeverityOptions } from 'UI/constants/defaults';

import SearchProjectsAction from './SearchProjectsAction';

const { menuItems: menuItemsCopies } = strings.searchProjects;

const FormModes = {
  Create: 'create',
  Update: 'update',
  Add: 'add'
};

const Forms = {
  AddToSearchProject: 'AddToSearchProject',
  CreateSearchProject: 'CreateSearchProject'
};

/**
 * @typedef {Object} Props
 * @property {Array<Object>} data - An array of objects representing the data to be displayed in the table. Each object must have an `id` property of type `number`, and can have any other properties.
 * @property {number} count - The total number of items in the data set.
 * @property {number} perPage - The number of items to display per page.
 * @property {string} entity - A string representing the type of entity being displayed in the table.
 * @property {string} createdFrom - A string representing the source of the data being displayed in the table.
 * @property {function} [setSelectionToInitial] - An optional function to reset the selection state of the table.
 * @property {string} [bulkId] - An optional string representing the ID of the bulk operation.
 * @property {Object} [queryParams] - An optional object representing the query parameters for the data set.
 * @property {...*} [props] - Any additional props to be passed to the underlying `DataTable` component.
 */
type TableWithSearchProjectsProps = {
  data: Array<{
    id: number,
    [key: string]: any
  }>,
  count: number,
  perPage: number,
  entity: string,
  createdFrom: string,
  bulkId: string,
  queryParams: Object,
  [key: string]: any,
  setSelectionToInitial: () => void
};

const ValidFormModes = [FormModes.Create, FormModes.Update];
const TableWithSearchProjects = ({
  data,
  count,
  perPage,
  entity,
  createdFrom,
  setSelectionToInitial,
  bulkId,
  queryParams,
  ...props
}: TableWithSearchProjectsProps) => {
  const dispatch = useDispatch();
  const [uiState, setUiState] = useState({
    [Forms.AddToSearchProject]: false,
    [Forms.CreateSearchProject]: false,
    type: FormModes.Create,
    fetchingRemoveDialog: false,
    moveItemsFromSearchProject: false
  });

  const dataForSelection = useMemo(
    () => data.map(({ id }) => ({ id, type_id: 1, type: entity })),
    [data, entity]
  );

  const {
    handleRowSelection,
    selectedIndexesInPage,
    totalSelected,
    filteredItems,
    multiSelectComponents
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const toggleSPForm =
    (type = FormModes.Create, isOpen = false, { moveItems = false } = {}) =>
    () => {
      const isFormToCreateOpen = ValidFormModes.includes(type);
      const chosenForm = isFormToCreateOpen ? Forms.CreateSearchProject : Forms.AddToSearchProject;
      setUiState(prev => ({
        ...prev,
        type,
        [chosenForm]: isOpen,
        moveItemsFromSearchProject: moveItems
      }));
    };

  /**
   * @param {'create' | 'add'} event
   * @param {boolean} success - If the event was executed without errors 'success' will
   * have a truthy value
   */
  const handleFormEvent = (success, event) => {
    if (success) {
      setSelectionToInitial && setSelectionToInitial();
      toggleSPForm(event, false)();
    }
  };

  const handleRemoveClick =
    ({ id: searchProjectId, dialogCopies, onRemove }) =>
    () => {
      const handleRemoveItemsFromSP = async ok => {
        if (!ok) return;
        const paramsType =
          createdFrom === CreatedFromVariants.BulkMetrics
            ? ParamsTypes.MetricsParams
            : ParamsTypes.SearchParams;
        const response = await removeItemsFromSearchProject({
          searchProjectId,
          itemsToRemove: filteredItems,
          queryParams,
          paramsType,
          bulkId
        });
        if (response.success) {
          onRemove && onRemove();
        } else {
          dispatch(showAlert(response.alert));
        }
      };

      dispatch(
        confirm({
          severity: SeverityOptions.warning,
          ...dialogCopies,
          mustComplete: true,
          closeDialogOnConfirm: false,
          onConfirm: handleRemoveItemsFromSP
        })
      );
    };

  const menuItems = {
    add: {
      title: menuItemsCopies.add.title,
      action: toggleSPForm(FormModes.Add, true),
      visible: true
    },
    create: {
      title: menuItemsCopies.create.title,
      action: toggleSPForm(FormModes.Create, true),
      visible: true
    },
    remove: {
      title: menuItemsCopies.remove.title,
      action: handleRemoveClick,
      visible: true
    },
    move: {
      toExisting: {
        title: menuItemsCopies.move.toExisting.title,
        action: toggleSPForm(FormModes.Add, true, { moveItems: true }),
        visible: true
      },
      toNew: {
        title: menuItemsCopies.move.toNew.title,
        action: toggleSPForm(FormModes.Create, true, { moveItems: true }),
        visible: true
      }
    }
  };

  const defaultItems = [menuItems.add, menuItems.create];
  const openCreateForm = ValidFormModes.includes(uiState.type);
  const formMode = openCreateForm ? FormModes[uiState.type] : FormModes.Add;

  const formCommonProps = useMemo(
    () => ({
      itemsToAdd: filteredItems,
      createdFrom,
      queryParams
    }),
    [queryParams, filteredItems, createdFrom]
  );

  return (
    <>
      <DataTable
        {...props}
        data={data}
        count={count}
        rowsPerPage={perPage}
        customToolbar={() => (
          <SearchProjectBar total={totalSelected}>
            <SearchProjectsAction menuItems={defaultItems} />
          </SearchProjectBar>
        )}
        components={multiSelectComponents}
        rowsSelected={selectedIndexesInPage}
        onRowSelectionChange={handleRowSelection}
      />
      <SearchProjectFormDrawer
        open={uiState[Forms.CreateSearchProject]}
        onClose={toggleSPForm(FormModes.Create, false)}
        onSubmitSearchProject={handleFormEvent}
        mode={formMode}
        moveCopiedItems={uiState.moveItemsFromSearchProject}
        {...formCommonProps}
      />
      <AddToSearchProjectDrawer
        open={uiState[Forms.AddToSearchProject]}
        onClose={toggleSPForm(FormModes.Add, false)}
        onAddItemsToSP={handleFormEvent}
        moveCopiedItems={uiState.moveItemsFromSearchProject}
        {...formCommonProps}
      />
    </>
  );
};

export default TableWithSearchProjects;
