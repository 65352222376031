// @flow
import React from 'react';
import { SearchProjectBar } from 'hooks/multipleSelection';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgDeleteFilled } from 'UI/res';
import { getFeatureFlags } from 'UI/utils';

import { useStyles } from '../styles';

type ActionsToolbarProps = {
  children: React.ReactNode,
  onRemoveClick: () => void,
  searchProjectIdInPreview: number,
  searchProjectNameInPreview: string,
  onRemoveSuccess: () => void,
  totalSelected: number
};

const ActionsToolbar = ({
  children,
  onRemoveClick,
  searchProjectIdInPreview,
  searchProjectNameInPreview,
  onRemoveSuccess,
  totalSelected
}: ActionsToolbarProps) => {
  const styles = useStyles();

  const isRemoveEnabled = getFeatureFlags().includes(FeatureFlags.EnableRemoveInBulkTables);

  if (!totalSelected) return null;

  return (
    <SearchProjectBar total={totalSelected}>
      <div className={styles.ActionsToolbar}>
        {children}
        {isRemoveEnabled && (
          <FPIconButton
            onClick={onRemoveClick({
              id: searchProjectIdInPreview,
              dialogCopies: {
                title: 'Remove Selected Items',
                message: strings.formatString(
                  strings.searchProjects.preview.dialogs.removeItems.message,
                  {
                    spName: searchProjectNameInPreview
                  }
                ),
                confirmButtonText: 'Remove',
                cancelButtonText: 'Keep'
              },
              onRemove: () => {
                onRemoveSuccess();
              }
            })}
            tooltipProps={{
              title: strings.searchProjects.preview.buttons.removeItems,
              placement: 'bottom'
            }}
            icon={SvgDeleteFilled}
            iconProps={{
              onSetColor: palette => palette.primary.main
            }}
          />
        )}
      </div>
    </SearchProjectBar>
  );
};

export default ActionsToolbar;
