// @flow
import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { hideAlert, showAlert } from 'actions/app';
import {
  addNotification as addNotificationAction,
  getTotal as getTotalAction,
  markNotificationAsRead as markNotificationAsReadAction
} from 'actions/notification';
import { removeContact } from 'actions/ringCentral';
import { RingCentralEventHandler } from 'classes/RingCentralEventHandler';
import { useSnackbar } from 'notistack';
import { NotificationProviderContext } from 'providers/NotificationProvider';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import CustomSnackbar from 'UI/components/molecules/CustomSnackbar';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import { NOTIFICATIONS_SERVICE_TYPES, VALIDATION_REGEXS } from 'UI/utils';

import { useButtonStyle, useStyles } from './styles';

let displayed = [];

const Notifier = ({
  addNotification,
  alerts,
  confirmation,
  getTotal,
  history,
  markNotificationAsRead,
  onHideAlert,
  onShowAlert,
  removeRcContact,
  showVersionBar,
  trackedContacts,
  version
}) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyle();
  const [, notificationToken] = useContext(NotificationProviderContext);
  const [currentUser] = useContext(UserProviderContext);

  // TO LISTEN EVENTS FROM RING CENTRAL WIDGET
  const contactsRef = useRef({
    contacts: trackedContacts,
    callback: removeRcContact
  });

  useEffect(() => {
    contactsRef.current.contacts = trackedContacts;
  }, [trackedContacts]);

  useEffect(() => {
    window.addEventListener('message', async e => {
      RingCentralEventHandler.handleEvent(
        e,
        contactsRef.current.contacts,
        contactsRef.current.callback
      );
    });
  }, []);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { content, ...confirmationProps } = confirmation || {};

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    const displayNotifications = () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.onmessage = (event: any) => {
          const { data, type } = event.data;

          if (type === NOTIFICATIONS_SERVICE_TYPES.BACKGROUND_MESSAGE) {
            addNotification(data);
          } else if (type === NOTIFICATIONS_SERVICE_TYPES.CLICK_BACKGROUND_MESSAGE) {
            getTotal();
          } else if (
            event?.data?.firebaseMessaging?.type === NOTIFICATIONS_SERVICE_TYPES.PUSH_RECEIVED
          ) {
            const { data: payload } = event.data.firebaseMessaging.payload;
            const { title, body, icon, color, click_action } = payload;

            addNotification(payload);

            onShowAlert({
              isNotification: true,
              title,
              body,
              icon,
              color,
              autoHideDuration: 4000,
              onClick: () => {
                markNotificationAsRead(payload);
                /* TODO this could be extracted fo reuse with NotificationPreview */
                const isFullUrl = VALIDATION_REGEXS.URL.test(click_action);
                if (isFullUrl) {
                  window.open(click_action, '_blank');
                } else {
                  history.push(click_action);
                }
              }
            });
          }
        };
      }
    };

    if (notificationToken && currentUser) {
      displayNotifications();
      getTotal();
    }
  }, [
    addNotification,
    onShowAlert,
    markNotificationAsRead,
    getTotal,
    history,
    notificationToken,
    currentUser
  ]);

  useEffect(() => {
    alerts.forEach(
      ({
        key,
        title,
        body,
        autoHideDuration,
        severity,
        options = {},
        isNotification = false,
        color,
        icon,
        onClick
      }) => {
        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        enqueueSnackbar(body, {
          key,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          autoHideDuration: autoHideDuration || 4000,
          onExited: (event, myKey) => {
            onHideAlert && onHideAlert(myKey);
            removeDisplayed(myKey);
          },
          content: (myKey, message) => (
            <CustomSnackbar
              id={myKey}
              body={message}
              severity={severity}
              title={title}
              isNotification={isNotification}
              color={color}
              icon={icon}
              onClick={onClick}
            />
          ),
          ...options
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [alerts, closeSnackbar, enqueueSnackbar, onHideAlert]);

  const handleRefreshClick = () => window.location.reload();

  return (
    <>
      {showVersionBar && (
        <Alert
          severity="info"
          classes={classes}
          action={
            <FPActionButton
              variant="outlined"
              size="small"
              text="Update"
              classes={buttonClasses}
              onClick={handleRefreshClick}
            />
          }
        >
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: strings.formatString(strings.navBar.versionBar, {
                version
              })
            }}
          />
        </Alert>
      )}
      {confirmation && (
        <DecisionDialog withButtons="YesNo" {...confirmationProps}>
          {content}
        </DecisionDialog>
      )}
    </>
  );
};

const mapStateToProps = ({ app, ringCentral }) => {
  return {
    alerts: app.ui.alerts,
    confirmation: app.ui.confirmation,
    showVersionBar: app.ui.showVersionBar,
    version: app.ui.version,
    trackedContacts: ringCentral.trackedContacts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideAlert: key => dispatch(hideAlert(key)),
    onShowAlert: alert => dispatch(showAlert(alert)),
    getTotal: () => dispatch(getTotalAction()),
    markNotificationAsRead: notification => dispatch(markNotificationAsReadAction(notification)),
    addNotification: notification => dispatch(addNotificationAction(notification)),
    removeRcContact: contact => dispatch(removeContact(contact))
  };
};

const NotifierConnected = connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifier));

export default NotifierConnected;
