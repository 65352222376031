import React from 'react';
import { Chart } from 'react-google-charts';
import { Widget } from 'features/command-center';
import { getProductionAndCashInByMonth } from 'features/command-center/services';
import strings from 'strings';

import { ChartLegend } from '../ChartLegend';

import {
  createSeries,
  DEFAULT_OPTIONS,
  MAIN_COLOR,
  refactorData,
  SECONDARY_COLOR
} from './ProductionCashInByMonth.utils';

const STRINGS = strings.commandCenter.finance.productionCashInByMonth;

export const ProductionCashInByMonth = () => {
  const renderChart = ([results]) => {
    const series = createSeries(results);
    const chartData = refactorData(series);

    return (
      <>
        <ChartLegend
          mainTitle="Production"
          mainColor={MAIN_COLOR}
          secondaryTitle="Cash In"
          secondaryColor={SECONDARY_COLOR}
        />
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={chartData}
          options={DEFAULT_OPTIONS}
        />
      </>
    );
  };

  return (
    <Widget
      header={STRINGS.title}
      subheader={STRINGS.subtitle}
      promises={[getProductionAndCashInByMonth]}
    >
      {renderChart}
    </Widget>
  );
};
