import { Permissions, userHasPermission } from 'services/Authorization';
import CandidatesTab from 'UI/components/organisms/inventoryProfiles/CandidatesTab';
import {
  candidateColumns,
  candidateVirtualProps,
  CustomCandidateRenders
} from 'UI/components/organisms/inventoryProfiles/CandidatesTab/columns';
import HiringAuthorityTab from 'UI/components/organisms/inventoryProfiles/HiringAuthorityTab';
import {
  hiringAuthorityColumns,
  HiringAuthorityVirtualProps
} from 'UI/components/organisms/inventoryProfiles/HiringAuthorityTab/columns';
import PlacementsTab from 'UI/components/organisms/inventoryProfiles/PlacementsTab';
import {
  ColumnsByMode,
  IncludesByMode
} from 'UI/components/organisms/inventoryProfiles/PlacementsTab/columns';
import { handleHiringAuthorityResponse } from 'UI/components/organisms/inventoryProfiles/responseChange';
import SendoutsTab from 'UI/components/organisms/inventoryProfiles/SendoutsTab';
import {
  SENDOUTS_COLUMNS,
  SENDOUTS_RENDERERS,
  SENDOUTS_VIRTUAL_PROPS
} from 'UI/components/organisms/inventoryProfiles/SendoutsTab/columns';
import { Endpoints } from 'UI/constants/endpoints';
import { TabKeys } from 'UI/constants/entityTypes';

export const jobOrdersTabDefinition = [
  {
    id: TabKeys.Sendouts,
    label: 'Sendouts',
    endpoint: Endpoints.Sendouts,
    Component: SendoutsTab,
    visible: () => userHasPermission(Permissions.Sendouts.CanUseModule),
    props: {
      extraRenderers: SENDOUTS_RENDERERS,
      initialColumns: SENDOUTS_COLUMNS,
      DataTableProps: {
        apiVersion: 2,
        virtualProps: SENDOUTS_VIRTUAL_PROPS,
        includes:
          'type,status,interviews,hiringAuthorithies,jobOrderAccountable,candidateAccountable,joborder,candidate,jobOrderAccountableCoach,candidateAccountableCoach'
      }
    }
  },
  {
    id: TabKeys.Placements,
    label: 'Placements',
    endpoint: Endpoints.Placements,
    Component: PlacementsTab,
    visible: () => userHasPermission(Permissions.Placements.CanUseModule),
    props: {
      initialColumns: ColumnsByMode.joborder,
      DataTableProps: {
        includes: IncludesByMode.joborder,
        apiVersion: 2
      }
    }
  },
  {
    id: TabKeys.Candidates,
    label: 'Candidates',
    endpoint: Endpoints.Candidates,
    Component: CandidatesTab,
    props: {
      initialColumns: candidateColumns,
      extraRenderers: CustomCandidateRenders,
      DataTableProps: {
        includes: 'personalInformation,specialty,blueSheets',
        virtualProps: candidateVirtualProps
      }
    }
  },
  {
    id: TabKeys.HiringAuthorities,
    label: 'Hiring Authorities',
    endpoint: Endpoints.HiringAuthorities,
    Component: HiringAuthorityTab,
    props: {
      initialColumns: hiringAuthorityColumns,
      DataTableProps: {
        virtualProps: HiringAuthorityVirtualProps,
        apiVersion: 1,
        includes: 'lastActivityLog,specialty,subspecialty,position,phones',
        formatResponse: handleHiringAuthorityResponse
      }
    }
  }
];
