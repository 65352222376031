// @flow
import React from 'react';
import { extractObjectFromDataTable } from 'hooks/datatable';
import { EntityRoutes } from 'routes/constants';
import FPLink from 'UI/components/atoms/FPLink';
import { ContactEntityMapping } from 'UI/constants/entityTypes';
import { PhoneColumns } from 'UI/constants/sharedColumns';

export const EmployeesCustomRender = {
  contactName: value => (
    <FPLink
      itemId={value?.id}
      linkProps={{
        type: 'local',
        url: value?.is_name ? EntityRoutes.ContactProfile : EntityRoutes.CandidateProfile
      }}
    >
      {value?.full_name}
    </FPLink>
  )
};

export const getEmployeeRole = ({ columns, rowData }) => {
  const item = extractObjectFromDataTable(columns, ['roleId'], rowData);
  const roleId = item?.roleId;
  const itemRole = ContactEntityMapping[roleId];
  return itemRole;
};

export const EmployeesColumns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'full_name',
    label: 'Name',
    options: {
      display: true,
      renderer: 'contactName'
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: true,
      renderer: 'default'
    }
  },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  {
    name: 'mobile',
    label: 'Other phone',
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      display: true,
      renderer: 'email'
    }
  },
  {
    name: 'personal_email',
    label: 'Other Email',
    options: {
      display: false,
      renderer: 'email'
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'position',
    label: 'Functional Title',
    options: {
      display: true,
      renderer: 'default'
    }
  },
  {
    name: 'roleTitle',
    label: 'Role',
    options: {
      renderer: 'default',
      display: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: false,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-candidate-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      display: false,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          baseClass: 'chip-candidate-type',
          labelKey: 'title'
        }
      }
    }
  },
  {
    name: 'created_at',
    label: 'Added date',
    options: {
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'last_activity_title',
    label: 'Last Activity',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity Date',
    options: {
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'last_activity_by',
    label: 'Last Activity By',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'roleId',
    options: {
      display: 'excluded',
      sort: false
    }
  }
];

export const EmployeeColumnsWithoutLegacyPhones = EmployeesColumns.filter(
  column =>
    column.name !== PhoneColumns.PHONE_LEGACY.name &&
    column.name !== PhoneColumns.OTHER_PHONE_LEGACY.name
);

export const EmployeesVirtualProps = [
  {
    propName: 'full_name',
    attributes: ['full_name', 'id', 'is_name']
  },
  {
    propName: 'phoneNumbers',
    attributes: ['phone', 'phones']
  }
];
