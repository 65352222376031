// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid, { GridProps } from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toggleSidebar } from 'actions/app';
import clsx from 'clsx';
import type { ContentPageLayoutProps, TitleLabelProps } from 'types/app';
import Text from 'UI/components/atoms/Text';
import TitleLabel from 'UI/components/atoms/TitleLabel';

import { useStyles } from './styles';

type PageHeadlineProps = {
  customTitleGridConfig: Object,
  pageHeadlineGrid?: GridProps,
  titleLabelProps: TitleLabelProps,
  actionsBar: React.Node,
  actionsBarConfig: Object,
  text: string,
  sectionName?: string
};

export const PageHeadline = ({
  customTitleGridConfig,
  titleLabelProps,
  actionsBar,
  text,
  actionsBarConfig,
  pageHeadlineGrid,
  sectionName
}: PageHeadlineProps) => {
  const isWithoutBackNavigation = !titleLabelProps?.backNavigation;

  const classes = useStyles({ isWithoutBackNavigation });

  return (
    <Grid container justify="space-between" {...pageHeadlineGrid}>
      <Grid item {...customTitleGridConfig}>
        {!titleLabelProps?.loading && sectionName && (
          <span className={classes.sectionNameWrapper}>
            <Text
              variant="overline"
              text={sectionName.toUpperCase()}
              className={classes.sectionName}
            />
          </span>
        )}
        <TitleLabel
          text={text}
          textTransform="uppercase"
          gridLoadingProps={actionsBar && { md: 6 }}
          backNavigationProps={{ className: classes.backNavigation }}
          {...titleLabelProps}
        />
      </Grid>
      {actionsBar && (
        <Grid item xs={12} sm={6} lg={3} container justify="flex-end" {...actionsBarConfig}>
          {actionsBar}
        </Grid>
      )}
    </Grid>
  );
};

PageHeadline.defaultProps = {
  sectionName: '',
  pageHeadlineGrid: {},
  customTitleGridConfig: {
    xs: 12,
    sm: 6,
    lg: 6
  }
};

export const ContentPageLayoutModes = {
  Main: 'mainLayout',
  Custom: 'customLayout'
};

const ContentPageLayout = ({
  children,
  className,
  customTitleGridConfig,
  titleLabelProps,
  text,
  layoutClassName,
  height,
  actionsBar,
  mode,
  actionsBarConfig,
  cardContent,
  sectionName,
  statusBar,
  pageHeadlineGrid,
  ...rest
}: ContentPageLayoutProps) => {
  const { isSidebarOpen } = useSelector(({ app }) => app.ui);
  const dispatch = useDispatch();
  const isSmallViewPort = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const classes = useStyles({ mode, height, isSidebarOpen });

  const gridModes = {
    mainLayout: {
      xs: 12,
      sm: 6,
      lg: 6
    },
    simplyTitle: {
      xs: 12
    },
    custom: customTitleGridConfig
  };

  const titleGrid = actionsBar ? gridModes[mode] : gridModes.simplyTitle;

  const layoutModes = {
    mainLayout: (
      <>
        {statusBar}
        <div className={clsx(classes.mainLayout, layoutClassName)}>
          <PageHeadline
            actionsBar={actionsBar}
            actionsBarConfig={actionsBarConfig}
            customTitleGridConfig={gridModes.custom ?? titleGrid}
            pageHeadlineGrid={{ spacing: 4 }}
            sectionName={sectionName}
            text={text}
            titleLabelProps={titleLabelProps}
          />
          {children}
        </div>
      </>
    ),
    customLayout: children // useful when text prop isn't provided or needed
  };

  const handleMouseDown = () => {
    if (!isSmallViewPort || !isSidebarOpen) return;
    dispatch(toggleSidebar(false));
  };

  return (
    <div
      id="content_wrapper"
      onMouseDown={handleMouseDown}
      role="presentation"
      className={clsx(
        classes.wrapperPageContainer,
        className,
        !isSmallViewPort && classes.marginLeftLargeView
      )}
      {...rest}
    >
      {layoutModes[mode]}
    </div>
  );
};

ContentPageLayout.defaultProps = {
  className: undefined,
  text: '',
  layoutClassName: undefined,
  height: undefined,
  actionsBar: undefined,
  mode: 'mainLayout',
  customTitleGridConfig: null,
  titleLabelProps: null,
  actionsBarConfig: null,
  cardContent: null,
  statusBar: undefined,
  sectionName: ''
};

export default ContentPageLayout;
