// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import { createSearchProjectsWithContactsFromCompanies } from 'services/searchProjects';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { SearchProjectPrivacyScopes } from 'UI/constants/defaults';
import { getErrorMessage } from 'UI/utils';

import SearchProjectExtractOptions from './SearchProjectExtractOptions/SearchProjectExtractOptions';
import { FORM_FIELDS_MAP } from './fields';

const {
  createForm: {
    visibility,
    nameInput,
    sectionLabels,
    title,
    name: { extractedSufix },
    extractTitle,
    errors: createErrorCopies
  }
} = strings.searchProjects;

const RADIO_OPTIONS = [SearchProjectPrivacyScopes.private, SearchProjectPrivacyScopes.public];

type SearchProjectDrawerProps = {
  open: boolean,
  onClose: () => void,
  baseSearchProjectId?: string,
  baseSearchProjectName?: string,
  shouldExtractContacts?: boolean
};

const SearchProjectDrawer = ({
  baseSearchProjectId,
  baseSearchProjectName,
  onClose,
  open,
  shouldExtractContacts
}: SearchProjectDrawerProps) => {
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const drawerTitle = `${extractTitle} ${title}`;

  const projectName = shouldExtractContacts ? `${baseSearchProjectName} - ${extractedSufix}` : '';

  const form = useForm({
    defaultValues: {
      projectName,
      scope: SearchProjectPrivacyScopes.public.id
    }
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, register, setValue, watch, errors } = form;

  const formValues = watch();

  const privacyScope = SearchProjectPrivacyScopes[formValues[FORM_FIELDS_MAP.Scope.key]];

  const isSubmitDisabled =
    shouldExtractContacts && !formValues[FORM_FIELDS_MAP.ExcludeCompanies.key];

  const handleRadioChange = event => setValue(FORM_FIELDS_MAP.Scope.key, event.target.value);

  const onSubmit = async formData => {
    setUiState({ isSaving: true, isSuccess: false });
    const optionalParams = {};

    if (shouldExtractContacts) {
      optionalParams.baseSearchProjectId = parseInt(baseSearchProjectId, 10);
      optionalParams.excludeCompanies = !!formData[FORM_FIELDS_MAP.ExcludeCompanies.key];
      optionalParams.addEmployees = !!formData[FORM_FIELDS_MAP.AddEmployees.key];
      optionalParams.addHiringAuthorities = !!formData[FORM_FIELDS_MAP.AddHiringAuthorities.key];
    }

    const spData = {
      name: formData.projectName.trim(),
      isPrivate: privacyScope.value,
      ...optionalParams
    };

    try {
      const response = await createSearchProjectsWithContactsFromCompanies(spData);
      const { success, data, alert } = response;
      dispatch(showAlert(alert));
      setUiState({ isSaving: false, isSuccess: success });

      if (!success) return;
      setTimeout(() => {
        history.push(EntityRoutes.SearchProjectPreview.replace(':id', data.id), {
          createdFrom: 'searchProjectPreview' || 'default'
        });
      });
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: createErrorCopies.unhandled.title,
          body: getErrorMessage(error)
        })
      );
    }
  };

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.Scope.key });
  }, [register]);

  return (
    <DrawerContentLayout
      title={drawerTitle}
      drawerProps={{ open }}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      uiState={{ isSaving: uiState.isSaving }}
      primaryProps={{
        disabled: isSubmitDisabled || uiState.isSaving
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography>{sectionLabels.details}</Typography>
          <TextBox
            label={nameInput.label}
            name={FORM_FIELDS_MAP.ProjectName.key}
            inputRef={register({
              required: nameInput.requiredMessage,
              minLength: {
                value: 5,
                message: nameInput.minLengthMessage
              }
            })}
            error={!!errors.projectName}
            errorText={errors?.projectName?.message}
          />
          <FPRadioGroup
            options={RADIO_OPTIONS}
            onChange={handleRadioChange}
            value={formValues[FORM_FIELDS_MAP.Scope.key]}
            adviceText={visibility[privacyScope.id]}
          />
        </Grid>
        {shouldExtractContacts && (
          <FormContext {...form}>
            <SearchProjectExtractOptions />
          </FormContext>
        )}
      </Grid>
    </DrawerContentLayout>
  );
};

SearchProjectDrawer.defaultProps = {
  baseSearchProjectId: null,
  baseSearchProjectName: null,
  shouldExtractContacts: false
};

export default SearchProjectDrawer;
