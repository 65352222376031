import React from 'react';
import { EntityRoutes } from 'routes/constants';
import { RequestStatus } from 'UI/constants/status';

import { ErrorTemplate } from '../../shared/Widget/ErrorTemplate';
import { RegionalDirectorSummaryCard } from '../RegionalDirectorSummaryCard';

import { SkeletonCards } from './components';
import { useRegionalDirectorCards } from './RegionalDirectorCards.hooks';
import { CardWrapper } from './RegionalDirectorCards.styled';

export const RegionalDirectorCards = () => {
  const { cardsState, getData } = useRegionalDirectorCards();

  if (cardsState.status === RequestStatus.Idle) {
    return null;
  }

  if (cardsState.status === RequestStatus.Loading) {
    return (
      <CardWrapper>
        <SkeletonCards />
      </CardWrapper>
    );
  }

  if (cardsState.status === RequestStatus.Error) {
    return <ErrorTemplate onReset={getData} />;
  }

  return (
    <CardWrapper>
      {cardsState.data.map(card => (
        <RegionalDirectorSummaryCard
          key={card.id}
          detailsUrl={EntityRoutes.CommandCenterActivityTeam.replace(':id', card.id)}
          healthStats={card.healthStats}
          financeStats={card.financeSummary}
          urlPhoto={card.imgURL}
          title={card.title}
        />
      ))}
    </CardWrapper>
  );
};
