import { SearchProjectCollaborationsRoles } from 'services/Authorization';
import { Roles } from 'UI/constants/roles';
import { dateFormatter } from 'UI/utils';

/**
 * @param {string} date - Date to be formatted
 * @param {function} callback - Exposes date formats in order to avoid
 * importing const where needed
 * @returns Custom Formatted date with fallback in case there's no date
 */
export const getQueryShortFormatDate = (date, callback) => {
  const callbackHelper = callback || (format => format.SimpleDateHyphen);
  return date ? dateFormatter(date, callbackHelper) : '- -';
};

/**
 * @param {Array} currentUserRoles
 * @param {'Owner'|'Collaborator'|'Guest'} [collaborationRole]
 * @returns {Object}
 */
export const getManagementPermissions = (
  currentUserRoles,
  collaborationRole = SearchProjectCollaborationsRoles.Guest
) => {
  const rolesWithFullPermissions = [
    Roles.DataCoordinator,
    Roles.Operations,
    Roles.ProductionDirector,
    Roles.Leadership
  ];
  const hasSomeRoleWithFullPermission = currentUserRoles.some(userRole =>
    rolesWithFullPermissions.includes(userRole.role_id)
  );

  const isOwner = collaborationRole === SearchProjectCollaborationsRoles.Owner;
  const isCollaborator = collaborationRole === SearchProjectCollaborationsRoles.Collaborator;
  const hasFullPermissions = isOwner || hasSomeRoleWithFullPermission;

  const managementPermissions = {
    searchProject: {
      canEdit: hasFullPermissions,
      canSplit: hasFullPermissions || isCollaborator,
      canArchive: hasFullPermissions,
      canViewHistoryLog: hasFullPermissions || isCollaborator
    },
    canManageCollaborations: hasFullPermissions,
    canManageItems: hasFullPermissions || isCollaborator
  };

  return managementPermissions;
};
