import React, { useState } from 'react';
import { GaugeStats, Widget } from 'features/command-center';
import { getActivitySummary } from 'features/command-center/services';
import { getWeekRange } from 'features/command-center/utils';
import strings from 'strings';

import { formatResponse } from './InMailsGauge.utils';

const STRINGS = strings.commandCenter.activity.inMailsGauge;

export const InMailsGauge = ({ requestParams = {}, weeks }) => {
  const [week, setWeek] = useState(weeks?.[0] ?? null);
  const { startDate, endDate } = getWeekRange(week);

  const handleSelectWeek = (event, selectedWeek) => setWeek(selectedWeek);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getActivitySummary({
            metrics: 'inMailsGoalsReaching',
            startDate,
            endDate,
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const stats = formatResponse(response);

        return (
          <GaugeStats
            id="inmails"
            stats={stats}
            week={week}
            weeks={weeks}
            onSelectWeek={handleSelectWeek}
          />
        );
      }}
    </Widget>
  );
};
