// @flow
import React, { forwardRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Editor } from '@tinymce/tinymce-react';
import clsx from 'clsx';

import { useStyles } from './styles';

type TextEditorProps = {
  config: any,
  onInit: any,
  value: any,
  error?: boolean,
  errorText?: string,
  disabled: boolean,
  colorVariant: 'normal' | 'opaque',
  onChange: () => void,
  onFocus?: () => void,
  containerClassName: Object
};

const tinyApiKey = process.env.REACT_APP_TINYMCE_API_KEY;

const FPTextEditor = forwardRef(
  ({
    error,
    errorText,
    value,
    colorVariant,
    disabled,
    config,
    onInit,
    onFocus,
    onChange,
    containerClassName
  }: TextEditorProps) => {
    const color = disabled ? colorVariant : 'normal';
    const classes = useStyles({ error, color });

    const handleEditorChange = editorValue => onChange && onChange(editorValue);

    return (
      <FormControl error={error} className={clsx(classes.editorContainer, containerClassName)}>
        <FormHelperText className={classes.errorText}>{error && errorText}</FormHelperText>
        <Editor
          onInit={onInit}
          init={config}
          apiKey={tinyApiKey}
          disabled={disabled}
          value={value}
          onFocus={onFocus}
          onEditorChange={handleEditorChange}
        />
      </FormControl>
    );
  }
);

FPTextEditor.defaultProps = {
  value: undefined,
  error: false,
  errorText: '',
  disabled: false,
  colorVariant: 'opaque',
  onChange: undefined,
  onFocus: () => {}
};

export default FPTextEditor;
