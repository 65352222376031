// @flow
import React from 'react';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { createCustomStaticRanges } from 'UI/utils';

import useCollectionsListing, {
  AdditionalPeriodRanges,
  DueDateInputRanges
} from './hooks/useCollectionsListing';
import { CollectionDateFilters, DueDateFilter, FiltersGroups } from './Collections.columns';
import CollectionsListing from './Collections.Listing';

const PAGE_KEY = 'collections-list';

const CollectionsList = () => {
  const {
    closeDrawer,
    dateTypeFilter,
    dataTable,
    defaultPeriod,
    uiState,
    finalData,
    handleDateTypeFilterChange,
    handleExpansion,
    handleFormCancel,
    handleSubmitSucceed,
    handleViewClick,
    expandedRows
  } = useCollectionsListing({ pageKey: PAGE_KEY });

  const {
    filters,
    handleFiltersToggle,
    handleFiltersApply,
    handleFiltersChange,
    handlePeriodChange,
    listState
  } = dataTable;

  const { showWholeSkeleton, isSideMenuOpen } = listState;

  return (
    <FiltersLayout
      title={strings.formatString(strings.collections.title)}
      section="collections"
      filters={filters}
      isLoading={showWholeSkeleton}
      isSideMenuOpen={isSideMenuOpen}
      defaultRange={defaultPeriod}
      disableTimeRange
      enableStore={false}
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(AdditionalPeriodRanges),
        inputRanges: dateTypeFilter === DueDateFilter ? DueDateInputRanges : []
      }}
      groups={FiltersGroups}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      onSearch={handleFiltersApply}
      titleLabelProps={backNavigateListConfig}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder={strings.shared.filters.filterBy}
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={CollectionDateFilters}
          disableClearable
        />
      }
    >
      <CollectionsListing
        closeDrawer={closeDrawer}
        dataTable={dataTable}
        enableCustomFilters
        expandedRows={expandedRows}
        finalData={finalData}
        listState={listState}
        onExpansion={handleExpansion}
        onFormCancel={handleFormCancel}
        onSubmitSucceed={handleSubmitSucceed}
        onViewClick={handleViewClick}
        uiState={uiState}
      />
    </FiltersLayout>
  );
};

export default CollectionsList;
