// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

const LoaderContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress color="inherit" size={40} />
    </div>
  );
};

export default LoaderContent;
