// @flow
import React from 'react';
import type { Stat } from 'features/command-center/types';

import { StatsItemContainer } from '../StatsItemContainer';
import { Widget } from '../Widget';

export interface StatsItemsCardProps {
  header?: string;
  promise: Promise<any>;
  textAlign?: 'left' | 'center';
  subheader?: ReactNode;
}

export const StatsItemsCard = ({ header, promise, textAlign, subheader }: StatsItemsCardProps) => {
  return (
    <Widget header={header} promises={[promise]} subheader={subheader}>
      {([responseData]) => {
        const data: Array<StatsItemProps> = responseData?.data ?? responseData;

        const formattedData = data.map((stat: Stat) => {
          return {
            ...stat,
            valueColor: stat.color
          };
        });

        return <StatsItemContainer items={formattedData} textAlign={textAlign} />;
      }}
    </Widget>
  );
};
