// @flow
import { EntityRoutes } from 'routes/constants';
import { TabKeys } from 'UI/constants/entityTypes';

export const CompanyColumns = [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      print: false,
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Status',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'chipBuilder',
        config: {
          labelKey: 'title',
          colorKey: 'color'
        }
      }
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: `${EntityRoutes.CompanyProfile}?tab=${TabKeys.HiringAuthorities}`,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'specialty.industry.title',
    label: 'Industry',
    options: {
      sort: false,
      display: true,
      renderer: 'default'
    }
  },
  {
    name: 'specialty.title',
    label: 'Specialty',
    options: {
      sort: false,
      display: true,
      renderer: 'default'
    }
  },
  {
    name: 'city.title',
    label: 'City',
    options: {
      sort: false,
      display: true,
      renderer: 'default'
    }
  },
  {
    name: 'address',
    label: 'Address',
    options: {
      sort: false,
      display: true,
      renderer: 'default'
    }
  }
];

export const CompaniesVirtualProps = [
  {
    propName: 'name',
    attributes: ['name', 'id']
  }
];
