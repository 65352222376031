import { EntityType } from 'UI/constants/entityTypes';

import { getPhonesSortedByDefaultPhone } from '../PhoneNumbersForm/utils';

/**
 * This map is used to transform data before submission to the API and also to preload data from the API into a form
 * Entries can have the following props:
 * If only key is present no transformatios is applied in neither direction (to/from the API)
 * If paramKey is present, then paramKey is sent to the API
 * outboundAccesor is a key to extract a value from an object. For instance, an outboundAccesor = 'id' would evaluate to object['id'] and this value would be sent to the API
 * inboundAccesor is a property path used to preload data coming from the API into the form. It's useful for nested objects, for instance: 'city.state' or 'address.zip'. If a more custom object is needed, then inboundAccessor could be a function.
 */
export const FormFieldsMap = {
  Id: {
    key: 'id'
  },
  Name: {
    key: 'name'
  },
  LegalName: {
    key: 'legalName',
    paramKey: 'legal_name'
  },
  Specialty: {
    key: 'specialty',
    paramKey: 'specialty_id',
    outboundAccesor: (_, formValues) => formValues.specialties[0].id
  },
  Subspecialty: {
    key: 'subspecialty',
    paramKey: 'subspecialty_id',
    outboundAccesor: (_, formValues) =>
      formValues.subspecialties.length > 0 ? formValues.subspecialties[0].id : null
  },
  Specialties: {
    key: 'specialties',
    paramKey: 'specialty_ids',
    outboundAccesor: specialties =>
      specialties.length > 0 ? specialties.slice(1).map(({ id }) => id) : null,
    inboundAccesor: rawObject => {
      const specialties = [];
      rawObject.specialty && specialties.push(rawObject.specialty);
      rawObject.specialties && rawObject.specialties.forEach(spec => specialties.push(spec));
      return specialties;
    }
  },
  Subspecialties: {
    key: 'subspecialties',
    paramKey: 'subspecialty_ids',
    outboundAccesor: subspecialties =>
      subspecialties.length > 1 ? subspecialties.slice(1).map(({ id }) => id) : null,
    inboundAccesor: rawObject => {
      const subspecialties = [];
      rawObject.subspecialty &&
        subspecialties.push({ ...rawObject.subspecialty, specialty: rawObject.specialty });
      rawObject.subspecialties &&
        rawObject.subspecialties.forEach(subspec => subspecialties.push(subspec));
      return subspecialties;
    }
  },
  Phones: {
    key: 'phones',
    inboundAccesor: rawObject => getPhonesSortedByDefaultPhone(rawObject, EntityType.Company)
  },
  PhonesValid: {
    key: 'arePhonesValid'
  },
  Phone: {
    key: 'phone',
    inboundAccesor: 'contact.phone'
  },
  Ext: {
    key: 'ext',
    inboundAccesor: 'contact.ext'
  },
  Email: {
    key: 'email'
  },
  AccountsPayableEmail: {
    key: 'accountsPayableEmail',
    paramKey: 'accounts_payable_email'
  },
  Address: {
    key: 'address',
    inboundAccesor: 'address.address'
  },
  Website: {
    key: 'website'
  },
  LinkedinUrl: {
    key: 'linkedinUrl',
    paramKey: 'link_profile'
  },
  Type: {
    key: 'type',
    paramKey: 'company_type_id',
    outboundAccesor: 'id',
    inboundAccesor: 'type'
  },
  State: {
    key: 'state_id',
    paramKey: 'state_id',
    outboundAccesor: 'id',
    inboundAccesor: 'city.state'
  },
  City: {
    key: 'city_id',
    paramKey: 'city_id',
    outboundAccesor: 'id',
    inboundAccesor: 'city'
  },
  Zip: {
    key: 'zip',
    paramKey: 'zip',
    outboundAccesor: 'id',
    inboundAccesor: form => ({ id: form.zip, title: form.zip })
  },
  Recruiter: {
    key: 'recruiter',
    paramKey: 'recruiter_id',
    outboundAccesor: 'id',
    inboundAccesor: 'recruiter'
  }
};

export const FormFieldsMapKeys = Object.values(FormFieldsMap).map(val => val.key);

export const SubspecialtiesCatalogKey = 'subspecialties_catalog';
