import {
  associatedCompaniesColumns,
  VirtualProps as AssociatedCompaniesVirtualProps
} from 'UI/components/organisms/inventoryProfiles/AssociatedCompaniesTab/associatedCompaniesColumns';
import AssociatedCompaniesTab, {
  TAB_INCLUDES
} from 'UI/components/organisms/inventoryProfiles/AssociatedCompaniesTab/AssociatedCompaniesTab';
import EmployeesTab from 'UI/components/organisms/inventoryProfiles/EmployeesTab';
import {
  EmployeesColumns,
  EmployeesCustomRender,
  EmployeesVirtualProps
} from 'UI/components/organisms/inventoryProfiles/EmployeesTab/columns';
import FeeAgreementTab from 'UI/components/organisms/inventoryProfiles/FeeAgreementTab';
import {
  feeAgreementColumns,
  feeAgreementVirtualProps
} from 'UI/components/organisms/inventoryProfiles/FeeAgreementTab/columns';
import HiringAuthorityTab from 'UI/components/organisms/inventoryProfiles/HiringAuthorityTab';
import {
  hiringAuthorityColumns,
  HiringAuthorityVirtualProps
} from 'UI/components/organisms/inventoryProfiles/HiringAuthorityTab/columns';
import JobOrdersTab from 'UI/components/organisms/inventoryProfiles/JobOrdersTab';
import { VirtualProps as jobOrderVirtualProps } from 'UI/components/organisms/inventoryProfiles/JobOrdersTab/candidatesJOcolumns';
import { companyProfileJobOrderColumns } from 'UI/components/organisms/inventoryProfiles/JobOrdersTab/companiesJOcolumns';
import PlacementsTab from 'UI/components/organisms/inventoryProfiles/PlacementsTab';
import {
  ColumnsByMode,
  IncludesByMode
} from 'UI/components/organisms/inventoryProfiles/PlacementsTab/columns';
import {
  handleEmployeesResponse,
  handleHiringAuthorityResponse
} from 'UI/components/organisms/inventoryProfiles/responseChange';
import SendoutsTab from 'UI/components/organisms/inventoryProfiles/SendoutsTab';
import {
  COMPANY_PROFILE_SENDOUTS_COLUMNS,
  SENDOUTS_RENDERERS,
  SENDOUTS_VIRTUAL_PROPS
} from 'UI/components/organisms/inventoryProfiles/SendoutsTab/columns';
import { Endpoints } from 'UI/constants/endpoints';
import { TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

export const companiesTabDefinition = [
  {
    id: TabKeys.JobOrders,
    label: 'Job Orders',
    endpoint: Endpoints.JobOrders,
    Component: JobOrdersTab,
    props: {
      DataTableProps: {
        includes: 'specialty,position,address,whiteSheet,hiringAuthorities,status,vRecruiter',
        apiVersion: 2,
        virtualProps: jobOrderVirtualProps,
        orderByOptions: { column: 'created_at', direction: 'desc' }
      },
      initialColumns: companyProfileJobOrderColumns
    }
  },
  {
    id: TabKeys.Sendouts,
    label: 'Sendouts',
    endpoint: Endpoints.Sendouts,
    Component: SendoutsTab,
    props: {
      extraRenderers: SENDOUTS_RENDERERS,
      DataTableProps: {
        apiVersion: 1,
        includes: 'type,status,candidate,jobOrder,candidateAccountable,jobOrderAccountable',
        virtualProps: SENDOUTS_VIRTUAL_PROPS
      },
      initialColumns: COMPANY_PROFILE_SENDOUTS_COLUMNS
    }
  },
  {
    id: TabKeys.Placements,
    label: 'Placements',
    endpoint: Endpoints.Placements,
    Component: PlacementsTab,
    props: {
      DataTableProps: {
        apiVersion: 1,
        includes: IncludesByMode.company
      },
      initialColumns: ColumnsByMode.company
    }
  },
  {
    id: TabKeys.FeeAgreements,
    label: 'Fee Agreements',
    endpoint: Endpoints.FeeAgreement,
    Component: FeeAgreementTab,
    props: {
      DataTableProps: {
        includes:
          'feeAgreementStatus,hiringAuthority,creator,paymentScheme,hiringAuthority,vRegional,vCreator,vCoach,vProductionDirector',
        apiVersion: 2,
        virtualProps: feeAgreementVirtualProps
      },
      initialColumns: feeAgreementColumns
    }
  },
  {
    id: TabKeys.HiringAuthorities,
    label: 'Hiring Authorities',
    endpoint: Endpoints.HiringAuthorities,
    Component: HiringAuthorityTab,
    props: {
      DataTableProps: {
        apiVersion: 2,
        virtualProps: HiringAuthorityVirtualProps,
        includes: 'lastActivityLog,specialty,subspecialty,position,phones',
        formatResponse: handleHiringAuthorityResponse
      },
      initialColumns: hiringAuthorityColumns
    }
  },
  {
    id: TabKeys.AssociatedCompanies,
    label: 'Associated Companies',
    Component: AssociatedCompaniesTab,
    endpoint: Endpoints.AssociatedCompanies,
    visible: hasFeatureFlag(FeatureFlags.LinkCompanies),
    props: {
      DataTableProps: {
        apiVersion: 2,
        virtualProps: AssociatedCompaniesVirtualProps,
        includes: TAB_INCLUDES.company
      },
      initialColumns: associatedCompaniesColumns
    }
  },
  {
    id: TabKeys.Employees,
    label: 'Employees',
    endpoint: 'employees',
    Component: EmployeesTab,
    props: {
      DataTableProps: {
        virtualProps: EmployeesVirtualProps,
        formatResponse: handleEmployeesResponse
      },
      initialColumns: EmployeesColumns,
      extraRenderers: EmployeesCustomRender
    }
  }
];
