// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import { colors, CopyIcon } from 'UI/res';
import { getInitials } from 'UI/utils/getinitials';

import { useStyles } from './styles';

type DigPopoverProps = {
  titleName?: string,
  initials?: string,
  coach?: string,
  onCopyClick: (text: string) => void,
  backgroundColor?: string,
  recruiterExtension?: string,
  recruiterEmail?: string
};

const DigPopover = ({
  titleName,
  initials,
  coach,
  recruiterExtension,
  recruiterEmail,
  backgroundColor,
  onCopyClick
}: DigPopoverProps) => {
  const classes = useStyles();

  const initialsOrDefault = initials || getInitials(titleName);

  return (
    <Typography component="div">
      <div className={classes.mainContainer}>
        <FPAvatar backgroundColor={backgroundColor}>{initialsOrDefault}</FPAvatar>
        <div className={classes.recruiterInfoContainer}>
          <div className={classes.titleName}>{titleName}</div>
          <span className={classes.coach}>Team: {coach}</span>
        </div>
      </div>
      <div className={classes.contactContainer}>
        <div className={classes.emailContainer}>
          <Typography onClick={() => onCopyClick(recruiterEmail)} className={classes.emailText}>
            {recruiterEmail}
          </Typography>
          <CopyIcon
            fill={colors.navLink}
            size={14}
            className={clsx(classes.copyButton, 'copyButton')}
            title="Copy to Clipboard"
            onClick={() => onCopyClick(recruiterEmail)}
          />
        </div>
        <span>Ext. {recruiterExtension} </span>
      </div>
    </Typography>
  );
};

// NOTE: default props set for display the design please replace with according data

DigPopover.defaultProps = {
  titleName: '',
  initials: '',
  coach: '',
  backgroundColor: '',
  recruiterExtension: '',
  recruiterEmail: ''
};

export default DigPopover;
