import { userHasPermission, userHasRole } from 'services/Authorization';
import strings from 'strings';
import { FormSchema } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { getInitials } from 'UI/utils/getinitials';
import { LabelRenderers } from 'UI/utils/renderers';
import { getQuestionsCounts } from 'utils';

const ANSWER = {
  Positive: 'Yes',
  Negative: 'No'
};

const { skillsError } = strings.inventory.jobOrders.editDataSheet.uiMessages;

export const getFeeAgreementOptions = (catalogs, whiteSheet) => {
  const possibleFeeAgreements = catalogs.feeAgreements.data.map(feeAgreement => ({
    const: feeAgreement.id,
    title: LabelRenderers.feeAgreement(feeAgreement)
  }));

  return whiteSheet.companyFeeAgreement
    ? [
        ...possibleFeeAgreements,
        {
          const: whiteSheet.companyFeeAgreement.id,
          title: LabelRenderers.feeAgreement(whiteSheet.companyFeeAgreement)
        }
      ].filter(
        (feeAgreement, index, self) =>
          self.findIndex(feeAgreementItem => feeAgreementItem.const === feeAgreement.const) ===
          index
      )
    : possibleFeeAgreements;
};

export const createDataSheetFormData = (formData, dataSheet, isFeeAgreementNeeded) => {
  if (!dataSheet || !formData) return null;

  return {
    ...formData,
    section1: {
      ...formData.section1,
      signedFeeAgreement:
        dataSheet.discussing_agreement_complete === 1 ? ANSWER.Positive : ANSWER.Negative,
      preparedToSign: dataSheet.company_prepared_to_sign_service_agreement
        ? ANSWER.Positive
        : ANSWER.Negative,
      preparedToInterview: dataSheet.company_prepared_to_interview_asap
        ? ANSWER.Positive
        : ANSWER.Negative,
      ...(isFeeAgreementNeeded && {
        feeAgreement: dataSheet.company_fee_agreement_id
      }),
      feePercent: String(Number(dataSheet.fee_agreement_percent)),
      feeGuaranteeDays: String(Number(dataSheet.warranty_time_in_days))
    },
    section2: {
      ...formData.section2,
      howLongPositionOpen: String(dataSheet.time_position_open).slice(0, 10),
      personInPlace: String(dataSheet.position_filled).slice(0, 10)
    },
    section3: {
      ...formData.section3,
      idealCandidate: String(dataSheet.background_requirements)
    },
    section4: {
      ...formData.section4,
      salaryRange: {
        lowSalary: String(dataSheet.minimum_compensation),
        idealSalary: String(dataSheet.good_compensation),
        highSalary: String(dataSheet.maximum_compensation)
      },
      otherBenefits: String(dataSheet.benefits)
    },
    section5: {
      ...formData.section5,
      workLocation: dataSheet.work_type_option_id
    },
    section6: {
      ...formData.section6,
      daysForInterviews: {
        dateTime1: dataSheet.preset_interview_dates[0]
          ? String(dataSheet.preset_interview_dates[0])
          : undefined,
        dateTime2: dataSheet.preset_interview_dates[1]
          ? String(dataSheet.preset_interview_dates[1])
          : undefined,
        dateTime3: dataSheet.preset_interview_dates[1]
          ? String(dataSheet.preset_interview_dates[2])
          : undefined
      }
    },
    section9: {
      ...formData.section9,
      jobOrderType: dataSheet.job_order_type_id,
      jobOrderStatus: dataSheet.status.id,
      notes: dataSheet.notes ? String(dataSheet.notes) : undefined
    }
  };
};

export const formatDataSheetSchema = (schema, catalogs, whiteSheet) => {
  if (!catalogs || !schema || !whiteSheet) return undefined;

  const feeAgreementOptions = getFeeAgreementOptions(catalogs, whiteSheet);

  const { feeAgreement, ...remainingSection1Properties } = schema.properties.section1.properties;

  const section1FinalProperties =
    feeAgreementOptions.length > 0
      ? {
          ...schema.properties.section1.properties,
          feeAgreement: {
            ...feeAgreement,
            oneOf: feeAgreementOptions
          }
        }
      : remainingSection1Properties;

  const section1FinalRequiredFields =
    feeAgreementOptions.length > 0
      ? schema.properties.section1.required
      : schema.properties.section1.required.filter(property => property !== 'feeAgreement');

  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: {
          ...section1FinalProperties
        },
        required: section1FinalRequiredFields
      },
      section5: {
        ...schema.properties.section5,
        properties: {
          ...schema.properties.section5.properties,
          workLocation: {
            ...schema.properties.section5.properties.workLocation,
            oneOf: catalogs.workTypes.data.map(workType => ({
              const: workType.id,
              title: workType.title
            }))
          }
        }
      },
      section9: {
        ...schema.properties.section9,
        properties: {
          ...schema.properties.section9.properties,
          jobOrderType: {
            ...schema.properties.section9.properties.jobOrderType,
            oneOf: catalogs.jobOrderTypes.data.map(jobOrderType => ({
              const: jobOrderType.id,
              title: jobOrderType.title
            }))
          },
          jobOrderStatus: {
            ...schema.properties.section9.properties.jobOrderStatus,
            oneOf: catalogs.jobOrderStatuses.data.map(jobOrderStatus => ({
              const: jobOrderStatus.id,
              title: jobOrderStatus.title
            }))
          }
        }
      }
    }
  };
};

export const addFeeAgreementValuesToFormData = (formData, catalogs, whiteSheet) => {
  if (!catalogs || !formData) return formData;

  const feeAgreementOptions = [
    ...catalogs.feeAgreements.data,
    ...(whiteSheet?.companyFeeAgreement ? [whiteSheet.companyFeeAgreement] : [])
  ];

  const feeAgreementSelected = feeAgreementOptions.find(
    feeAgreement => feeAgreement.id === formData.section1.feeAgreement
  );

  if (!feeAgreementSelected) return formData;

  return {
    ...formData,
    section1: {
      ...formData.section1,
      feePercent: feeAgreementSelected.fee_percentage
        ? String(Number(feeAgreementSelected.fee_percentage))
        : null,
      feeGuaranteeDays: String(Number(feeAgreementSelected.guarantee_days))
    }
  };
};

export const getDataSheetCount = (formData, questionsPaths, needsFeeAgreement) => {
  let finalQuestionsPaths = { ...questionsPaths };

  if (formData.section2.coverPosition === 'No') {
    const {
      'section2.coverPositionDetails': coverDetails,
      'section2.resultsYouHad': resultsYouHad,
      ...rest
    } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (!formData.section1.feePercent) {
    const { 'section1.feePercent': feePercent, ...rest } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (!needsFeeAgreement) {
    const { 'section1.feeAgreement': feeAgreement, ...rest } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  return getQuestionsCounts(formData, finalQuestionsPaths);
};

export const createDataToSend = ({
  formData,
  jobOrder,
  user,
  schemaData,
  count,
  whiteSheetId,
  dataSheetId: id
}) => {
  return {
    ...(id && { id }),
    jobOrder: {
      id: jobOrder.id,
      title: jobOrder.title,
      whiteSheetId
    },
    schema: {
      key: FormSchema.JobOrderDataSheet,
      version: schemaData.version
    },
    progress: {
      current: count.answered,
      total: count.total
    },
    data: formData,
    trace: {
      user: {
        id: user.id,
        fullName: user.personalInformation.full_name,
        email: user.email,
        initials: getInitials(user.personalInformation.full_name)
      }
    }
  };
};

export const canChangeFeeAgreementField = (canUserEdit, whiteSheet, fieldPermission) => {
  const isUserCoach = userHasRole(Roles.Coach);
  const isExistingWhiteSheet = !!whiteSheet?.id;
  const hasRightPrivileges = isUserCoach || userHasPermission(fieldPermission);

  return hasRightPrivileges && (!isExistingWhiteSheet || (isExistingWhiteSheet && canUserEdit));
};

export const formatDataSheetUiSchema = (
  uiSchema,
  { canModifyFeeGuarantee, canModifyFeePercentage, isThereFeeAgreement }
) => {
  return {
    ...uiSchema,
    section1: {
      ...uiSchema.section1,
      signedFeeAgreement: {
        ...uiSchema.section1.signedFeeAgreement,
        'ui:disabled': isThereFeeAgreement
      },
      feeGuaranteeDays: {
        ...uiSchema.section1.feeGuaranteeDays,
        'ui:disabled': isThereFeeAgreement || !canModifyFeeGuarantee
      },
      feePercent: {
        ...uiSchema.section1.feePercent,
        'ui:disabled': isThereFeeAgreement || !canModifyFeePercentage
      }
    }
  };
};

export const removeFeePercent = schema => {
  const { feePercent, ...propertiesSkippingFeePercent } = schema.properties.section1.properties;
  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: propertiesSkippingFeePercent,
        required: schema.properties.section1.required.filter(property => property !== 'feePercent')
      }
    }
  };
};

export const addFeePercent = schema => {
  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: {
          ...schema.properties.section1.properties,
          feePercent: {
            title: 'Fee Percent',
            type: 'string',
            pattern: '^\\d+(\\.\\d+)?$'
          }
        },
        required: [
          ...schema.properties.section1.required.filter(property => property !== 'feePercent'),
          'feePercent'
        ]
      }
    }
  };
};

export const customValidate = (formInfo, errors) => {
  const regex = /^[\w\s'-]+(?:,[\w\s'-]+)*(?:\.)?$/;
  const isFieldString = typeof formInfo.section3.whatSkills === 'string';
  const skillsWithoutSpaces = isFieldString ? formInfo.section3.whatSkills.trim() : '';
  const skillsWithoutDotOrCommaAtTheEnd = skillsWithoutSpaces.replace(/(,|\.)$/, '');
  const hasWhatSkillsFieldError = !regex.test(skillsWithoutDotOrCommaAtTheEnd.trim());

  if (hasWhatSkillsFieldError) {
    errors.section3.whatSkills.addError(skillsError);
  }

  return errors;
};
