export const createCustomTooltip = ({ category = '', title = '', value }) => {
  return `
      <div style="padding: 8px; font-family: Roboto; font-size: 14px; color: #FFFFFF; background-color: #000000; border-radius: 6px;">
        <strong>${title}</strong><br/>
        <span style="white-space: nowrap">${category}:</span><br/>
        <strong>${value}</strong>
      </div>
    `;
};

export const activityTrendsTooltip = (value, activity) => {
  return `
      <div style="padding: 8px; font-family: Roboto; font-size: 14px; color: #FFFFFF; background-color: #000000; border-radius: 6px; width: 80px;"
        <strong>${value} ${activity}</strong><br/>
      </div>
    `;
};
