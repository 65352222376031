import { EntityRoutes } from 'routes/constants';
import { DateFormats, defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const defaultColumns = [
  {
    name: 'id',
    label: 'Nº',
    options: {
      display: false
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'status'
    }
  },
  {
    name: 'start_date',
    label: 'Start Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDate }
      }
    }
  },
  {
    name: 'fee_amount',
    label: 'Fee Amount',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'currency'
    }
  },
  {
    name: 'company_recruiter',
    label: 'Company Recruiter',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'candidate_recruiter',
    label: 'Candidate Recruiter',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  }
];

const hiringAuthorityColumn = {
  name: 'hiringAuthority',
  label: 'Hiring Authority',
  options: {
    display: false,
    sort: false,
    renderer: {
      id: 'linkBuilder',
      config: {
        route: EntityRoutes.HiringAuthorityProfile,
        idKey: 'id',
        textKey: 'fullName'
      }
    }
  }
};

export const candidateProfilePlacementsColumns = {
  name: 'candidate',
  label: 'Candidate',
  options: {
    ...defaultProfileColumnsConfig,
    renderer: {
      id: 'linkBuilder',
      config: {
        route: EntityRoutes.CandidateProfile,
        idKey: 'id',
        textKey: 'fullName'
      }
    }
  }
};

export const joborderProfilePlacementColumns = {
  name: 'jobOrder',
  label: 'Job Order',
  options: {
    ...defaultProfileColumnsConfig,
    renderer: {
      id: 'linkBuilder',
      config: {
        route: EntityRoutes.JobOrderProfile,
        idKey: 'id',
        textKey: 'title'
      }
    }
  }
};

export const companyProfilePlacementColumns = [
  {
    name: 'id',
    label: 'Number',
    options: {
      renderer: 'default'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'status'
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  },
  {
    name: 'industry_title',
    label: 'Industry',
    options: {
      renderer: 'default',
      display: true
    }
  },
  {
    name: 'specialty_title',
    label: 'Specialty',
    options: {
      renderer: 'default',
      display: true
    }
  },
  {
    name: 'subspecialty_title',
    label: 'Subspecialty',
    options: {
      renderer: 'default',
      display: true
    }
  },
  {
    name: 'fee_amount',
    label: 'Fee amount',
    options: {
      renderer: 'currency',
      display: true
    }
  },
  {
    name: 'recruiters',
    label: 'Recruiters',
    options: {
      renderer: 'default',
      display: true
    }
  },
  {
    name: 'vRecruiter.coach_name',
    label: 'Team',
    options: {
      renderer: 'default'
    }
  },
  {
    name: 'start_date',
    label: 'Start date',
    options: {
      renderer: 'date',
      display: true
    }
  },
  {
    name: 'created_at',
    label: 'Created at',
    options: {
      renderer: 'date',
      display: true
    }
  },
  {
    name: 'invoice_date',
    label: 'Invoiced Date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'approved_date',
    label: 'Approve date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'fall_off_date',
    label: 'Fall Off Date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'estimate_creation_date',
    label: 'Estimate Creation Date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'estimate_approval_date',
    label: 'Estimate Approval Date',
    options: {
      renderer: 'date'
    }
  }
];

const defaultPlacementIncludes = 'status,invoices,splits,hiringAuthority';

export const IncludesByMode = {
  joborder: `${defaultPlacementIncludes},candidate`,
  candidate: `${defaultPlacementIncludes},jobOrder`,
  company: `${defaultPlacementIncludes},candidate,jobOrder,vRecruiter`
};

export const ColumnsByMode = {
  joborder: [...defaultColumns, hiringAuthorityColumn, candidateProfilePlacementsColumns],
  candidate: [...defaultColumns, hiringAuthorityColumn, joborderProfilePlacementColumns],
  company: [...companyProfilePlacementColumns, hiringAuthorityColumn],
  hiringAuthority: [...defaultColumns]
};
