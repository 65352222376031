import { EntityRoutes } from 'routes/constants';
import { DateFormats } from 'UI/constants/defaults';

export const feeAgreementColumns = [
  {
    name: 'id',
    label: 'Fee Nº',
    options: {
      filter: false,
      sort: false,
      display: true
    }
  },
  {
    name: 'feeAgreementStatus',
    label: 'Status',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'statusBuilder',
        config: {
          labelKey: 'internal_name',
          baseClass: 'fa-status'
        }
      }
    }
  },
  {
    name: 'fee_percentage',
    label: 'Fee',
    options: {
      display: true,
      renderer: 'fee'
    }
  },
  {
    name: 'guarantee_days',
    label: 'Days Under Guarantee',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'hiringAuthority',
        config: {
          idKey: 'id',
          route: EntityRoutes.HiringAuthorityProfile,
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'creator.personalInformation.full_name',
    label: 'Created by',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'created_at',
    label: 'Creation Date',
    options: {
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: DateFormats.SimpleDateTime
        }
      }
    }
  },
  {
    name: 'paymentScheme.title',
    label: 'Type',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'validated_date',
    label: 'Sent at',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'hiring_authority_sign_date',
    label: 'HA signed at',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'signed_date',
    label: 'gpac signed at',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'vProductionDirector.user_name',
    label: 'Validated by',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'vCreator.user_name',
    label: 'Recruiter',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'vCoach.user_name',
    label: 'Coach',
    options: {
      display: false,
      renderer: 'default'
    }
  }
];

export const feeAgreementVirtualProps = [
  {
    propName: 'fee_percentage',
    attributes: ['flat_fee_amount', 'fee_agreement_payment_scheme_id', 'fee_percentage']
  }
];

export const feeAgreementHiringAuthorityProfileColumns = [
  {
    name: 'id',
    label: 'Fee Nº',
    options: {
      filter: false,
      sort: false,
      display: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'statusBuilder',
        config: {
          labelKey: 'name',
          baseClass: 'fa-status'
        }
      }
    }
  },
  {
    name: 'fee',
    label: 'Fee',
    options: {
      display: true,
      renderer: 'fee'
    }
  },
  {
    name: 'guaranteeDays',
    label: 'Guarantee Days',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'creator.userName',
    label: 'Created by',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'createdAt',
    label: 'Created at',
    options: {
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: DateFormats.SimpleDateTime
        }
      }
    }
  },
  {
    name: 'fee.payment.scheme.title',
    label: 'Type',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'validatedDate',
    label: 'Sent Date',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'haSignDate',
    label: 'HA Signature Date',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'signedDate',
    label: 'gpac Signature Date',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'director.userName',
    label: 'Validated by',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'creator.userName',
    label: 'Recruiter',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'coach.userName',
    label: 'Team',
    options: {
      display: false,
      renderer: 'default'
    }
  }
];
