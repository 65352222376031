// @flow

import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags, getId } from 'UI/utils';

export const PHONE_TYPES = {
  Mobile: 'Mobile',
  Land: 'Landline',
  VoIP: 'VoIP',
  Unknown: 'Unknown'
};

export const PHONE_ROW_PREFIX = {
  CountryCode: 'phoneCountryCode_',
  Phone: 'phoneNumber_',
  Ext: 'phoneExt_',
  Use: 'phoneUse_',
  Type: 'phoneType_',
  IsDefault: 'phoneDefaultId'
};

export const PHONE_FORMAT_MODES = {
  submit: 'submit',
  preload: 'preload'
};

export const PHONE_ROW_LABELS = {
  CountryCode: 'Country Code',
  Phone: 'Phone',
  Ext: 'Ext',
  Use: 'Use',
  Type: 'Type'
};

export const PHONE_USE_MAP = {
  Personal: { id: 1, title: 'Personal' },
  Business: { id: 2, title: 'Business' }
};

export const PHONE_TYPE_MAP = {
  1: { id: 1, title: PHONE_TYPES.Land },
  2: { id: 2, title: PHONE_TYPES.Mobile },
  3: { id: 3, title: PHONE_TYPES.VoIP },
  4: { id: 4, title: PHONE_TYPES.Unknown }
};

export const DEFAULT_PHONE_ROW = {
  id: getId(),
  countryCode: {
    id: 229,
    country_iso_2: 'US',
    country_iso_3: 'USA',
    country: 'United States',
    code: '+1'
  },
  isDefault: true,
  phone: '',
  ext: '',
  use: '',
  type: ''
};

/**
 * This function is used in two ways:
 * 1- To format the phone fields into a single array in order to be sent to the back.
 * 2- To format phones when they come from drafts.
 */

export const formatPhoneNumbers = (data, format: string) => {
  const phoneNumbersSufixes = Object.keys(data).filter(key => key.includes(PHONE_ROW_PREFIX.Phone));
  const isDraftWithLegacyPhones = phoneNumbersSufixes.length === 0;

  if (format === PHONE_FORMAT_MODES.preload && isDraftWithLegacyPhones) {
    const formattedLegacyPhones = convertDraftLegacyPhones(data);
    return formattedLegacyPhones;
  }

  const formattedPhoneNumbers = phoneNumbersSufixes.map(sufix => {
    const index = sufix.replace(PHONE_ROW_PREFIX.Phone, '');
    const indexAsNumber = parseInt(index, 10);
    const phone = data[sufix];
    const ext = data[`${PHONE_ROW_PREFIX.Ext}${index}`];
    const use = data[`${PHONE_ROW_PREFIX.Use}${index}`];
    const type = data[`${PHONE_ROW_PREFIX.Type}${index}`];
    const countryCode = data[`${PHONE_ROW_PREFIX.CountryCode}${index}`];

    const isDefault = parseInt(data.phoneDefaultId, 10) === indexAsNumber;

    if (format === PHONE_FORMAT_MODES.submit) {
      return {
        id: indexAsNumber,
        phone,
        extension: ext,
        use: use?.title,
        type_id: type?.id,
        is_default: isDefault,
        country_code_id: countryCode?.id,
        code: countryCode?.code
      };
    }

    return {
      id: indexAsNumber,
      countryCode,
      phone,
      ext,
      use,
      type,
      isDefault
    };
  });

  const sanitizedPhones = formattedPhoneNumbers.filter(item => item.phone);

  return sanitizedPhones;
};

const formatPhoneFromBack = (item, defaultPhone) => ({
  id: item.id,
  phone: item.phone.phone,
  ext: item.phone.extension,
  use: PHONE_USE_MAP[item.use],
  type: PHONE_TYPE_MAP[item.type_id],
  isDefault: item.id === defaultPhone,
  phoneId: item.phone_id,
  countryCode: item.phone.countryCode
});

const createPhoneObject = ({ phone, use, type, isDefault, ext = null }) => ({
  id: getId(),
  phone,
  ext,
  use,
  type,
  isDefault,
  phoneId: getId(),
  countryCode: DEFAULT_PHONE_ROW.countryCode
});

/**
 * Function to convert existing legacy phones into the new phones schema
 */
const formatLegacyPhones = (data, entityType) => {
  const phones = [];

  const commonHiringAuthData = {
    work_phone: {
      use: null,
      type: null,
      isDefault: true
    },
    personal_phone: {
      use: null,
      type: null,
      isDefault: false
    }
  };

  if (entityType === EntityType.HiringAuthority) {
    Object.entries(commonHiringAuthData).forEach(([key, value]) => {
      if (data[key]) {
        const phone = createPhoneObject({
          phone: data[key],
          use: value.use,
          type: value.type,
          isDefault: value.isDefault
        });
        phones.push(phone);
      }
    });
  }

  if (
    entityType === EntityType.Company ||
    entityType === EntityType.Candidate ||
    entityType === EntityType.Contact
  ) {
    const { contact } = entityType === EntityType.Company ? data : data.personalInformation;

    if (contact.phone) {
      const phone = createPhoneObject({
        phone: contact.phone,
        use: null,
        type: null,
        isDefault: true
      });
      phones.push(phone);
    }

    if (contact.mobile) {
      const mobilePhone = createPhoneObject({
        phone: contact.mobile,
        use: null,
        type: null,
        isDefault: !contact.phone
      });
      phones.push(mobilePhone);
    }
  }

  return phones.length === 0 ? [DEFAULT_PHONE_ROW] : phones;
};

/**
 * Function to sort an array of phones. It places the default phone as first element of array.
 */

export const getPhonesSortedByDefaultPhone = (data, entityType) => {
  if (!data || !getFeatureFlags().includes(FeatureFlags.ConvertLegacyPhones)) return [];
  if (!data?.phones || !data?.phones.length) {
    const formattedLegacyPhones = formatLegacyPhones(data, entityType);
    return formattedLegacyPhones;
  }
  const phones = data.phones && data.phones.map(item => formatPhoneFromBack(item, data.phone_id));
  const defaultPhone = phones.find(item => item.id === data.phone_id);
  const orderedPhones = [defaultPhone, ...phones.filter(item => item !== defaultPhone)];
  return orderedPhones;
};

/**
 * Function to add an id to each phone item and sort them by default phone.
 * The id is used as key for rendering purposes.
 */

export const getPhonesWithId = (phones: []) => {
  if (!phones) return [];
  const uniquePhones = removeDuplicatePhones(phones);
  const sortedItems = uniquePhones.sort((a, b) => b.is_default - a.is_default);
  return sortedItems.map(item => ({ ...item, id: getId() }));
};

/**
 *
 * @param {String} phone string representing the phone number.
 * @param {String} extension string representing the phone extension.
 * @returns {String}
 */
const calculatePhoneKey = ({ countryCode = '+1', phone, extension }) => {
  const trimmedPhone = phone.trim();
  const parsedExtension = typeof extension === 'string' ? extension.trim() : '';
  return `${countryCode}-${trimmedPhone}-${parsedExtension}`;
};

export const checkDuplicatePhoneEntries = phones => {
  const phoneSet = new Set();

  const hasDuplicates = phoneObj => {
    if (phoneObj.phone !== '') {
      const key = calculatePhoneKey({
        countryCode: phoneObj.code,
        phone: phoneObj.phone,
        extension: phoneObj.extension
      });
      if (phoneSet.has(key)) {
        return true;
      }
      phoneSet.add(key);
    }
    return false;
  };

  return phones.some(hasDuplicates);
};

const convertDraftLegacyPhones = data => {
  const phones = [];

  if (data.phone) {
    const phone = createPhoneObject({
      phone: data.phone,
      use: PHONE_USE_MAP.Business,
      type: PHONE_TYPE_MAP[1],
      isDefault: true,
      ext: data.ext
    });
    phones.push(phone);
  }

  if (data.mobile) {
    const mobilePhone = createPhoneObject({
      phone: data.mobile,
      use: PHONE_USE_MAP.Personal,
      type: PHONE_TYPE_MAP[2],
      isDefault: false
    });
    phones.push(mobilePhone);
  }

  return phones.length === 0 ? [DEFAULT_PHONE_ROW] : phones;
};

/**
 * Function to format hiring authorities response from jo and company tab in profiles
 */
export const formatHiringAuthorityPhonesForProfileTables = input => {
  return input.phones_for_list.map(phoneItem => {
    const typeLabel = phoneItem?.type_id
      ? PHONE_TYPE_MAP[phoneItem.type_id].title
      : phoneItem?.type_id;
    return {
      phone: phoneItem?.phone,
      extension: phoneItem?.extension,
      country_code: phoneItem?.country_code,
      country_code_id: phoneItem?.country_code_id,
      use: phoneItem?.use,
      type_id: phoneItem?.type_id,
      type: typeLabel,
      is_default: phoneItem?.is_default
    };
  });
};

/**
 * Function to remove items with same phone value
 * Used for rendering phones dropdown in tables
 */

const removeDuplicatePhones = inputArray => {
  const uniquePhones = new Set<string>();

  return inputArray.reduce((result, item) => {
    if (!uniquePhones.has(item.phone)) {
      uniquePhones.add(item.phone);
      result.push(item);
    }
    return result;
  }, []);
};

export const getPhonesForProfileTable = ({ item, mainPhoneKey, secondaryPhoneKey }) => {
  let mainPhone = null;
  let secondaryPhone = null;

  if (item[mainPhoneKey] !== null && item[mainPhoneKey] !== '') {
    mainPhone = item[mainPhoneKey];
    secondaryPhone =
      item[secondaryPhoneKey] !== null && item[secondaryPhoneKey] !== ''
        ? item[secondaryPhoneKey]
        : null;
  } else if (item[secondaryPhoneKey] !== null && item[secondaryPhoneKey] !== '') {
    mainPhone = item[secondaryPhoneKey];
    secondaryPhone = null;
  }

  return {
    mainPhone,
    secondaryPhone
  };
};
