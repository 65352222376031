// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgArrowRight } from 'UI/res';
import { hasFeatureFlag, objectToArray } from 'UI/utils';

import { useStyles } from './styles';

type BulkStatsInfoCardProps = {
  onShowStatsDetail?: () => any,
  stats: object
};

const { cards: cardsCopies } = strings.bulkEmails.stats;

const METRIC_STATUSES = {
  bounce: 'bounce',
  click: 'click',
  delivered: 'delivered',
  dropped: 'dropped',
  fixable: 'fixable',
  invalid: 'invalid',
  open: 'open',
  pending: 'pending',
  quotaExceeded: 'quota-exceeded',
  reply: 'reply',
  spam: 'spam',
  undelivered: 'undelivered',
  unreachable: 'unreachable',
  unsubscribe: 'unsubscribe'
};

const STATS_KEYS = {
  pending: 'pending_emails',
  quota_exceeded: 'quota_exceeded_emails',
  sent: 'sent_emails',
  open: 'open_emails',
  spam: 'spam_emails',
  click: 'click_emails',
  reply: 'reply_emails',
  bounced: 'bounced_emails',
  unreachable: 'unreachable_emails',
  dropped: 'dropped_emails',
  fixable: 'fixable_emails',
  unsubscribe: 'unsubscribe_emails',
  original_meant_recipients: 'original_meant_recipients'
};

/**
 * @param {number} count
 * @returns {string}
 */
export const getItemLabel = count => {
  if (count === 0) return '- -';
  return count < 10 && count > 0 ? count.toString().padStart(2, '0') : count.toString();
};

const BulkStatsInfoCard = ({ onShowStatsDetail = null, stats }: BulkStatsInfoCardProps) => {
  const classes = useStyles();
  const statsCards = [
    {
      key: STATS_KEYS.pending,
      order: 0,
      status: METRIC_STATUSES.pending,
      shouldShow: stats[STATS_KEYS.pending] > 0
    },
    {
      key: STATS_KEYS.quota_exceeded,
      order: 1,
      status: METRIC_STATUSES.quotaExceeded,
      shouldShow: stats[STATS_KEYS.quota_exceeded] > 0
    },
    { key: STATS_KEYS.sent, order: 2, status: METRIC_STATUSES.delivered },
    { key: STATS_KEYS.open, isSubItem: true, order: 2.1, status: METRIC_STATUSES.open },
    { key: STATS_KEYS.spam, isSubItem: true, order: 2.2, status: METRIC_STATUSES.spam },
    { key: STATS_KEYS.click, isSubItem: true, order: 2, status: METRIC_STATUSES.click },
    {
      key: STATS_KEYS.reply,
      order: 3,
      status: METRIC_STATUSES.reply,
      shouldShow: hasFeatureFlag(FeatureFlags.BulkEmailRepliedEmailsStat)
    },
    { key: STATS_KEYS.bounced, order: 10, status: METRIC_STATUSES.bounce },
    { key: STATS_KEYS.unreachable, order: 10, status: METRIC_STATUSES.unreachable },
    { key: STATS_KEYS.dropped, order: 10, status: METRIC_STATUSES.dropped },
    { key: STATS_KEYS.fixable, order: 10, status: METRIC_STATUSES.fixable },
    { key: STATS_KEYS.unsubscribe, order: 10, status: METRIC_STATUSES.unsubscribe },
    {
      key: STATS_KEYS.original_meant_recipients,
      order: 10,
      status: objectToArray(METRIC_STATUSES).join(',')
    }
  ];
  const filteredStats =
    stats &&
    statsCards.filter(({ key, shouldShow }) => {
      if (!Object.keys(stats).includes(key)) return false;
      return typeof shouldShow !== 'undefined' ? shouldShow : true;
    });

  const orderedStatsCards = filteredStats.sort((a, b) => a.order - b.order);

  const handleCardClick =
    ({ title, status }) =>
    () =>
      onShowStatsDetail && onShowStatsDetail({ title, status });

  return stats ? (
    <Grid container direction="column">
      {orderedStatsCards.map(({ key, isSubItem, status }) => (
        <Grid
          key={key}
          item
          data-testid="bulk-stat-card"
          container
          className={clsx(
            classes.cardItem,
            isSubItem ? classes.subItemSpacing : classes.cardItemSpacing
          )}
          alignItems="center"
          onClick={handleCardClick({ title: cardsCopies[key].title, status })}
        >
          <Grid container item xs={8} direction="column">
            <Typography className={classes.title}>{cardsCopies[key].title}</Typography>
            <Typography className={classes.description}>{cardsCopies[key].description}</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center" justify="flex-end">
            <Typography className={classes.totalItems}>{getItemLabel(stats[key])}</Typography>
            <FPIconButton tooltipProps={{ title: cardsCopies.viewDetails }} icon={SvgArrowRight} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

export default BulkStatsInfoCard;
