import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  navigationControl: {
    right: theme.spacing(3),
    bottom: theme.spacing(4.5),
    position: 'fixed'
  },
  circularProgress: {
    width: '100%',
    height: '100%'
  }
}));
