export const formatResponse = response => {
  if (!response || response.length === 0) {
    return [
      ['Month', 'Count', 'Average'],
      ['', 0, 0]
    ];
  }

  const total = response.reduce((sum, item) => sum + item.total, 0);
  const average = total / response.length;

  const formattedData = [
    ['Month', 'Count', 'Average'],
    ...response.map(item => [`${item.month} ${item.year}`, item.total, average])
  ];

  return formattedData;
};
