import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    ...flexAlignCenter
  },
  recruiterInfoContainer: {
    marginLeft: theme.spacing(2)
  },
  titleName: {
    textAlign: 'left',
    margin: 0,
    fontSize: 18,
    fontWeight: 700
  },
  coach: {
    fontSize: 14,
    fontWeight: 400
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    fontSize: 14
  },
  copyButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    display: 'inline',
    verticalAlign: 'middle'
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  emailText: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
}));
