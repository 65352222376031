// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import {
  createSearchProjectFolder,
  getAllSearchProjectsFolders,
  getSearchProjectFolderById,
  updateSearchProjectFolder
} from 'services/searchProjects/Folders';
import strings from 'strings';
import type { SearchProjectsFolderFormDrawerPropTypes } from 'types/app';
import TextBox from 'UI/components/atoms/TextBox';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

const { title, sections, validations } = strings.searchProjects.folderForm;

const FORM_FIELDS_MAP = {
  folderName: {
    key: 'folderName'
  },
  parentFolder: {
    key: 'parentFolder'
  }
};

const DEFAULT_EXCLUDE_IDS = ['MyCollaborations', 'AllSearchProjects', 'Archived'];

const SearchProjectsFolderFormDrawer = ({
  initialFolder = null,
  isFolderBeingEdited = false,
  onCloseDrawer,
  onSubmitFolder = null
}: SearchProjectsFolderFormDrawerPropTypes) => {
  const dispatch = useDispatch();
  const drawerTitle = strings.formatString(title.generalTitle, {
    action: isFolderBeingEdited ? title.actions.edit : title.actions.create
  });
  const excludedIds = isFolderBeingEdited
    ? [DEFAULT_EXCLUDE_IDS, initialFolder.id]
    : DEFAULT_EXCLUDE_IDS;
  const [previousParentId, setPreviousParentId] = useState(null);
  const [uiState, setUiState] = useState({
    isFormLoading: false,
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues: {
      [FORM_FIELDS_MAP.folderName.key]: isFolderBeingEdited ? initialFolder.name : '',
      [FORM_FIELDS_MAP.parentFolder.key]: initialFolder
    }
  });
  const { errors, handleSubmit, register, setValue, watch } = form;
  const watchers = watch();

  useEffect(() => {
    register(
      { name: FORM_FIELDS_MAP.folderName.key },
      { required: validations.required.folderName }
    );
    register(
      { name: FORM_FIELDS_MAP.parentFolder.key },
      { required: validations.required.parentFolder }
    );
  }, [register]);

  useEffect(() => {
    if (!initialFolder || !isFolderBeingEdited) return;

    setUiState(prev => ({ ...prev, isFormLoading: true }));
    (async () => {
      const { success, alert, data } = await getSearchProjectFolderById(initialFolder.id);
      if (success) {
        setPreviousParentId(data.parent.id);
        setValue(FORM_FIELDS_MAP.parentFolder.key, {
          id: data.parent.id,
          name: data.parent.name
        });
      } else dispatch(showAlert(alert));
      setUiState(prev => ({ ...prev, isFormLoading: false }));
    })();
  }, [dispatch, initialFolder, isFolderBeingEdited, setValue]);

  const onSubmit = handleSubmit(async formData => {
    setUiState({ isSaving: true });
    const parentFolderId = formData[FORM_FIELDS_MAP.parentFolder.key].id;
    const payload = {
      name: formData[FORM_FIELDS_MAP.folderName.key],
      parentFolderId
    };
    const { success, data, alert } = await (isFolderBeingEdited
      ? updateSearchProjectFolder(initialFolder.id, payload)
      : createSearchProjectFolder(payload));

    dispatch(showAlert(alert));

    if (success) {
      const newData = {
        parentFoldersId: {
          current: parentFolderId
        }
      };

      if (isFolderBeingEdited) {
        if (parentFolderId !== previousParentId)
          newData.parentFoldersId.previous = previousParentId;
        newData.shouldRefreshSelectedFolder = true;
        newData.folderName = payload.name;
      }

      onSubmitFolder && onSubmitFolder({ ...data, ...newData });
      setUiState({ isSaving: false, isSuccess: true });
      onCloseDrawer();
    } else setUiState({ isSaving: false });
  });

  return (
    <DrawerContentLayout
      drawerProps={{ open: true }}
      title={drawerTitle}
      onClose={onCloseDrawer}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={sections.folderName.title}>
            <TextBox
              name={FORM_FIELDS_MAP.folderName.key}
              placeholder={sections.folderName.placeholder}
              onChange={setValue}
              error={!!errors.folderName}
              errorText={errors.folderName?.message}
              value={watchers.folderName}
            />
          </NumberedForm.Item>
          <NumberedForm.Item title={sections.parentFolder.title}>
            <TreeViewSelector
              formFieldName={FORM_FIELDS_MAP.parentFolder.key}
              placeholder={sections.parentFolder.placeholder}
              TreeViewLoaderProps={{
                enableIconClick: true,
                onlyShowFolders: true,
                service: {
                  method: getAllSearchProjectsFolders,
                  queryParams: { excludeIds: excludedIds },
                  childrenQueryParams: { excludeIds: initialFolder?.id }
                }
              }}
            />
          </NumberedForm.Item>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default SearchProjectsFolderFormDrawer;
