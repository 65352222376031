// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import copy from 'copy-to-clipboard';
import strings from 'strings';
import DigPopover from 'UI/components/organisms/DigPopover';
import InventoryPopover from 'UI/components/organisms/InventoryPopover';
import { EntityType } from 'UI/constants/entityTypes';
import { nestTernary } from 'UI/utils';

import './styles.css';

type PopUpContentProps = {
  isDigActive?: boolean,
  entityType?: string,
  info: any,
  onQuickViewClick: () => void,
  SearchProjectAction: any
};

const getIndustryHierarchy = (item: any) =>
  `${item.industry}: ${item.specialty}${item.subspecialty ? ` / ${item.subspecialty}` : ''}`;

const PopUpContent = ({
  isDigActive,
  entityType,
  info,
  onQuickViewClick,
  SearchProjectAction
}: PopUpContentProps) => {
  const dispatch = useDispatch();
  const specificInfo =
    entityType === EntityType.Candidate
      ? {
          title: info.full_name,
          subtitle: info.title,
          subtitle2: getIndustryHierarchy(info),
          type: entityType,
          industryLabel: strings.map.inventory.popover.industrySpecialityLabel
        }
      : nestTernary(
          entityType === EntityType.Company,
          {
            title: info.name,
            subtitle: getIndustryHierarchy(info),
            subtitle2: '',
            type: entityType
          },
          {
            title: info.title,
            subtitle: info.company_name,
            subtitle2: getIndustryHierarchy(info),
            type: entityType,
            industryLabel: strings.map.inventory.popover.industrySpecialityLabel
          }
        );

  const handleCopyClick = (textToCopy: string) => {
    copy(textToCopy);
    dispatch(
      showAlert({
        severity: 'success',
        body: 'Copied to Clipboard',
        autoHideDuration: 1000
      })
    );
  };

  return (
    <div>
      {isDigActive ? (
        <DigPopover
          initials={info.initials}
          titleName={info.full_name}
          recruiterEmail={info.email}
          recruiterExtension={info.ext}
          coach={info.coach}
          backgroundColor={info.color}
          onCopyClick={handleCopyClick}
        />
      ) : (
        <InventoryPopover
          id={info.id}
          title={specificInfo.title}
          subtitle={specificInfo.subtitle}
          description={specificInfo.subtitle2}
          entityType={entityType}
          recruiterName={info.recruiter}
          recruiterEmail={info.recruiter_email}
          type={info.type}
          initials={info.initials}
          emailLink={info.recruiter_email}
          onQuickViewClick={onQuickViewClick}
          SearchProjectAction={SearchProjectAction}
          markerType={info.type_detail?.id}
          onCopyClick={handleCopyClick}
        />
      )}
    </div>
  );
};

PopUpContent.defaultProps = {
  isDigActive: true,
  entityType: ''
};

export default PopUpContent;
