import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  HealthDistribution,
  InMailsGauge,
  LayoutSkeleton,
  LowHealthMembersByTeam,
  PDAByTenure,
  PeopleReachedGauge,
  PhoneTimeGauge,
  WeeklyPerformance
} from 'features/command-center';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import usePageTitle from 'hooks/usePageTitle';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';

export const Overview = () => {
  usePageTitle({ title: PageTitles.CommandCenterActivity });
  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  if (weeksCatalogState.status === UIStatus.Loading) {
    return <LayoutSkeleton />;
  }

  const weeks = weeksCatalogState?.results;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <HealthDistribution />
      </Grid>

      <Grid item xs={12} sm={6}>
        <PDAByTenure />
      </Grid>

      <Grid item xs={12} sm={6}>
        <LowHealthMembersByTeam weeks={weeks} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <PeopleReachedGauge weeks={weeks} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InMailsGauge weeks={weeks} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CallsGauge weeks={weeks} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <PhoneTimeGauge weeks={weeks} />
      </Grid>

      <Divider />

      <Grid item xs={12}>
        <WeeklyPerformance />
      </Grid>
    </Grid>
  );
};
