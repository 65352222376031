import React from 'react';

const SvgCommandCenterOverview = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        d="M10.4687 14.4252C10.4415 14.3929 10.4082 14.3664 10.3707 14.347C10.3331 14.3277 10.2921 14.3161 10.25 14.3127H7.74999C7.70482 14.3143 7.66045 14.3251 7.6196 14.3444C7.57876 14.3638 7.54231 14.3913 7.51249 14.4252L6.19999 16.0065C6.16949 16.0552 6.15332 16.1115 6.15332 16.169C6.15332 16.2265 6.16949 16.2828 6.19999 16.3315C6.22663 16.387 6.26971 16.433 6.32338 16.4632C6.37705 16.4934 6.43871 16.5063 6.49999 16.5002H11.5C11.5566 16.4985 11.6118 16.4816 11.6597 16.4514C11.7077 16.4212 11.7467 16.3788 11.7728 16.3285C11.799 16.2782 11.8112 16.2218 11.8083 16.1652C11.8053 16.1086 11.7874 16.0538 11.7562 16.0065L10.4687 14.4252Z"
        fill={fill}
      />
      <path
        d="M15.5625 1.50024H2.4375C2.18886 1.50024 1.9504 1.59902 1.77459 1.77483C1.59877 1.95065 1.5 2.1891 1.5 2.43774L1.5 12.4377C1.5 12.6864 1.59877 12.9248 1.77459 13.1007C1.9504 13.2765 2.18886 13.3752 2.4375 13.3752H15.5625C15.8111 13.3752 16.0496 13.2765 16.2254 13.1007C16.4012 12.9248 16.5 12.6864 16.5 12.4377V2.43774C16.5 2.1891 16.4012 1.95065 16.2254 1.77483C16.0496 1.59902 15.8111 1.50024 15.5625 1.50024V1.50024ZM15.25 10.5627C15.25 10.6456 15.2171 10.7251 15.1585 10.7837C15.0999 10.8423 15.0204 10.8752 14.9375 10.8752H3.0625C2.97962 10.8752 2.90013 10.8423 2.84153 10.7837C2.78292 10.7251 2.75 10.6456 2.75 10.5627V3.06274C2.75 2.97986 2.78292 2.90038 2.84153 2.84177C2.90013 2.78317 2.97962 2.75024 3.0625 2.75024H14.9375C15.0204 2.75024 15.0999 2.78317 15.1585 2.84177C15.2171 2.90038 15.25 2.97986 15.25 3.06274V10.5627Z"
        fill={fill}
      />
      <path
        d="M7.75 8.02518C7.77412 8.00311 7.79514 7.97788 7.8125 7.95018L9.49375 5.61893C9.54234 5.55251 9.57734 5.47714 9.59673 5.39717C9.61613 5.31719 9.61953 5.23416 9.60676 5.15286C9.59398 5.07157 9.56528 4.99359 9.52229 4.92341C9.4793 4.85324 9.42287 4.79224 9.35625 4.74393C9.28945 4.69565 9.21377 4.66107 9.13355 4.64216C9.05333 4.62325 8.97017 4.6204 8.88884 4.63376C8.80752 4.64712 8.72964 4.67643 8.65969 4.72001C8.58974 4.76359 8.52909 4.82057 8.48125 4.88768L7.23125 6.63768C7.20769 6.6683 7.17398 6.68952 7.13618 6.69754C7.09839 6.70556 7.05897 6.69985 7.025 6.68143L6.08125 6.11268C5.94614 6.03174 5.78545 6.0048 5.63133 6.03725C5.4772 6.0697 5.34101 6.15914 5.25 6.28768L3.8 8.32518C3.70386 8.4611 3.66565 8.62965 3.69378 8.79374C3.72191 8.95784 3.81408 9.10403 3.95 9.20018C4.08592 9.29632 4.25447 9.33453 4.41857 9.30639C4.58266 9.27826 4.72886 9.1861 4.825 9.05018L5.875 7.60018C5.89671 7.56722 5.93053 7.54414 5.96914 7.53595C6.00775 7.52776 6.04803 7.53512 6.08125 7.55643L7.025 8.11893C7.14039 8.18138 7.27251 8.20593 7.40264 8.1891C7.53276 8.17228 7.65429 8.11493 7.75 8.02518Z"
        fill={fill}
      />
      <path
        d="M11.5 4.00024H10.875C10.7024 4.00024 10.5625 4.14016 10.5625 4.31274V8.68774C10.5625 8.86033 10.7024 9.00024 10.875 9.00024H11.5C11.6726 9.00024 11.8125 8.86033 11.8125 8.68774V4.31274C11.8125 4.14016 11.6726 4.00024 11.5 4.00024Z"
        fill={fill}
      />
      <path
        d="M13.6875 5.25024H13.0625C12.8899 5.25024 12.75 5.39016 12.75 5.56274V8.68774C12.75 8.86033 12.8899 9.00024 13.0625 9.00024H13.6875C13.8601 9.00024 14 8.86033 14 8.68774V5.56274C14 5.39016 13.8601 5.25024 13.6875 5.25024Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgCommandCenterOverview;
