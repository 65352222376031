import { useCallback, useEffect, useState } from 'react';
import { RequestStatus } from 'UI/constants/status';

import { getRegionalCardsData } from './RegionalDirectorCards.utils';

export const useRegionalDirectorCards = () => {
  const [cardsState, setCardsState] = useState({
    data: null,
    status: RequestStatus.Idle
  });

  const getData = useCallback(async () => {
    setCardsState({ data: null, status: RequestStatus.Loading });
    try {
      const data = await getRegionalCardsData();
      setCardsState({ data, status: RequestStatus.Success });
    } catch (error) {
      setCardsState({ data: null, status: RequestStatus.Error });
    }
  }, []);

  useEffect(() => {
    getData();

    return () => {
      setCardsState({ data: null, status: RequestStatus.Idle });
    };
  }, [getData]);

  return {
    cardsState,
    getData
  };
};
