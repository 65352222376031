// @flow
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

import { makeRequest } from '../utils';

const { alerts: servicesAlerts } = strings.searchProjects.services;

const getGeneralErrorAlert = errorAction => ({
  title: strings.formatString(servicesAlerts.error.general.title, {
    action: errorAction
  }),
  body: servicesAlerts.error.general.body
});

const getGeneralSuccessTitle = successAction =>
  strings.formatString(servicesAlerts.success.generalTitle, { action: successAction });

const payloadMapper = ({ name, parentFolderId }) => ({
  name,
  parent_id: parentFolderId
});

/**
 * @typedef {Object} GetAllFoldersQueryParams
 * @property {string} includes
 * @property {number} parentId
 * @property {Array<Number>} excludeIds
 */

/**
 * @param {GetAllFoldersQueryParams} [queryParams = {}]
 * @returns {Promise<OperationResult>}
 */
export const getAllSearchProjectsFolders = (queryParams = {}): Promise<OperationResult> =>
  makeRequest({
    method: 'get',
    endpointOptions: {
      path: Endpoints.SearchProjectFolders,
      queryParams
    }
  });

/**
 * @param {Number} id
 * @returns {Promise<OperationResult>}
 */
export const getSearchProjectFolderById = (id): Promise<OperationResult> =>
  makeRequest({
    method: 'get',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: id,
      queryParams: {
        includes: 'parent'
      }
    }
  });

export const createSearchProjectFolder = payload =>
  makeRequest({
    method: 'post',
    url: Endpoints.SearchProjectFolders,
    data: payloadMapper(payload),
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(servicesAlerts.success.actions.create),
        body: payload.name
      },
      error: getGeneralErrorAlert(servicesAlerts.error.actions.create)
    }
  });

export const updateSearchProjectFolder = (folderId, payload) =>
  makeRequest({
    method: 'put',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: folderId
    },
    data: payloadMapper(payload),
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(servicesAlerts.success.actions.edit)
      },
      error: getGeneralErrorAlert(servicesAlerts.error.actions.edit)
    }
  });

export const archiveSearchProjectFolder = folderId =>
  makeRequest({
    method: 'delete',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: folderId
    },
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(servicesAlerts.success.actions.archive)
      },
      error: getGeneralErrorAlert(servicesAlerts.error.actions.archive)
    }
  });

export const restoreSearchProjectFolder = ({ folderId, parentId }) =>
  makeRequest({
    method: 'post',
    endpointOptions: {
      path: Endpoints.SearchProjectRestoreFolder,
      replaceId: folderId
    },
    data: { parentId },
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(servicesAlerts.success.actions.restore)
      },
      error: getGeneralErrorAlert(servicesAlerts.error.actions.restore)
    }
  });
