// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDatatable, {
  addRingCentralContactToStore,
  buildMultiplePhonesActionsRenderer,
  buildRowActionsRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection, { SearchProjectBar } from 'hooks/multipleSelection';
import { useSearchProject } from 'hooks/searchProject';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { EntityRoutes } from 'routes/constants';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DataTable from 'UI/components/organisms/DataTable';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { CommunicationActionPreset, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { ContactEntityMapping, ContactRole, EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { createCustomStaticRanges, getFinalColumns, hasFeatureFlag } from 'UI/utils';
import { DataTableLink } from 'UI/utils/renderers';

import {
  columnsContact,
  DateFilters,
  FiltersGroups,
  filtersInclude,
  PeriodColumnBySection,
  VirtualProps
} from './columns';

const ContactRoutes = {
  [ContactRole.HiringAuthority]: EntityRoutes.HiringAuthorityProfile,
  [ContactRole.Name]: EntityRoutes.ContactProfile,
  [ContactRole.Candidate]: EntityRoutes.CandidateProfile
};

const extraRenderers = {
  contactLink: value => {
    if (!value) return null;

    const { id, role_id: roleId, full_name: fullName } = value;
    const route = ContactRoutes[roleId];

    return id && route ? (
      <DataTableLink id={id} text={fullName} route={route} enableInTabRedirect />
    ) : null;
  }
};

const CONTACT_KEY = 'contacts';
const orderByOptions = {
  column: 'full_name',
  direction: 'asc'
};
const numberOfActionColumns = 1;

const ContactsList = () => {
  const dispatch = useDispatch();

  const [dateTypeFilter, setDateTypeFilter] = useState(DateFilters[0]);

  const apiVersion = hasFeatureFlag(FeatureFlags.MultiplePhonesColumn) ? 2 : 1;

  const multiplePhonesActionButtons = buildMultiplePhonesActionsRenderer({
    customName: 'phoneNumbers',
    handleAction
  });

  const CONTACT_COLUMNS = [...getFinalColumns(columnsContact), multiplePhonesActionButtons].filter(
    column => !!column
  );

  const initialPreferences = getColumnPreferences(
    CONTACT_KEY,
    0,
    orderByOptions,
    CONTACT_COLUMNS,
    numberOfActionColumns
  );
  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    handleSectionChange,
    listState,
    queryParams
  } = useDatatable({
    key: CONTACT_KEY,
    columnsDefinitions: CONTACT_COLUMNS,
    initialPreferences,
    endpoint: Endpoints.Names,
    apiVersion,
    entityType: EntityType.Contact,
    entityName: 'Contacts',
    periodDefaultValue: null,
    periodFilterColumn: PeriodColumnBySection,
    sendDateWithTimezone: true,
    numberOfActionColumns,
    virtualProps: VirtualProps,
    shouldScrollOnNavigate: true
  });

  const { columns, columnOrder } = columnPreferences;
  const { isLoading, page, perPage, showWholeSkeleton, isSideMenuOpen, keyword } = listState;

  function handleAction({ rowData, origin }) {
    const item = extractObjectFromDataTable(columns, ['role_id'], rowData);
    const roleId = item?.role_id;
    const itemRole = ContactEntityMapping[roleId];
    itemRole &&
      addRingCentralContactToStore({
        rowData,
        columns: CONTACT_COLUMNS,
        dispatch,
        role: itemRole,
        origin
      });
  }

  const { entityId, quickViewEntity, quickViewState, listViewPreset, toggleQuickViewPreview } =
    useQuickViewPreview({
      columns,
      data,
      quickViewEntityRoleProps: { roleKey: 'role_id' }
    });

  const phoneActionButtons = buildRowActionsRendererDefinition({
    actions: [...CommunicationActionPreset, listViewPreset],
    namespace: EntityType.Contact,
    handleAction
  });

  const dataForSelection = useMemo(
    () => data.map(item => ({ id: item.id, type: item.role, type_id: item.role_id })),
    [data]
  );
  const {
    handleRowSelection,
    selectedIndexesInPage,
    multiSelectComponents,
    totalSelected,
    filteredItems
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  const getCustomToolbar = useCallback(
    () => (
      <SearchProjectBar total={totalSelected}>
        <SearchProjectAction />
      </SearchProjectBar>
    ),
    [totalSelected]
  );

  useEffect(() => {
    document.title = PageTitles.Contact;
  }, []);

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const finalColumns = getColumnsToRender([...columns, phoneActionButtons], extraRenderers);

  const handleTabRefresh = async () => {
    await getData();
  };

  return (
    <FiltersLayout
      filters={filters}
      groups={FiltersGroups}
      includeFilters={filtersInclude}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      enableStore={false}
      title="Contacts"
      isSideMenuOpen={isSideMenuOpen}
      titleLabelProps={backNavigateListConfig}
      defaultRange={null}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={DateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      onPeriodChange={handlePeriodChange}
    >
      <DataTable
        isExpandable
        data={data}
        columns={finalColumns}
        page={page}
        count={count}
        loading={showWholeSkeleton}
        refreshing={isLoading}
        rowsPerPage={perPage}
        searchText={keyword}
        onToggleFilters={handleFiltersToggle}
        enableCustomFilters
        filter={false}
        draggableColumns={{
          enabled: true
        }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        onSearchTextChange={handleKeywordChange}
        onColumnSortChange={handleColumnSortChange}
        onPerPageClick={handlePerPageChange}
        onPageClick={handlePageChange}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        onRowSelectionChange={handleRowSelection}
        rowsSelected={selectedIndexesInPage}
        selectToolbarPlacement="none"
        customToolbar={getCustomToolbar}
        components={multiSelectComponents}
      />
      <SearchProjectForms />
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{ open: quickViewState.isQuickViewOpen }}
          entityType={quickViewEntity}
          id={entityId}
          itemsToNavigate={data}
          onClose={toggleQuickViewPreview}
          quickViewEntityRoleProps={{ roleKey: 'role_id' }}
          onEditionCompleted={handleTabRefresh}
        />
      </When>
    </FiltersLayout>
  );
};

export default ContactsList;
