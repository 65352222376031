// @flow
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import {
  CreatedFromVariants,
  ParamsTypes,
  removeItemsFromSearchProject
} from 'services/searchProjects';
import strings from 'strings';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import AddToSearchProjectDrawer from 'UI/components/organisms/AddToSearchProjectDrawer';
import SearchProjectFormDrawer from 'UI/components/organisms/SearchProjectFormDrawer';
import { SeverityOptions } from 'UI/constants/defaults';
import { TabKeys } from 'UI/constants/entityTypes';
import { SvgSearchProjects } from 'UI/res';

type SearchProjectFormsProps = {
  bulkEmailId?: number,
  searchProject?: Object
};

const FORM_MODES = {
  create: 'create',
  update: 'update',
  add: 'add'
};

const {
  menuItems: menuItemsCopies,
  profiles: {
    tabs: searchProjectsTabCopies,
    dialogs: { removeProfile: removeProfileDialogCopies }
  }
} = strings.searchProjects;

export const useSearchProject = ({
  createdFrom,
  filteredItems,
  queryParams,
  setSelectionToInitial,
  enableMoveActions,
  bulkId,
  onUpdateSearchProject,
  shouldRedirectToSearchProjectPreview = true,
  handleRefreshSearchProjectsTab
}) => {
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isAddToSearchProjectOpen: false,
    isCreateSearchProjectOpen: false,
    formMode: FORM_MODES.create,
    fetchingRemoveDialog: false,
    moveItemsFromSearchProject: false
  });
  const formCommonProps = useMemo(
    () => ({
      itemsToAdd: filteredItems,
      createdFrom,
      queryParams
    }),
    [queryParams, filteredItems, createdFrom]
  );

  /**
   * @param {'create' | 'update' | 'add'} type
   * @param {boolean} open
   * @param {Object} extraOptions
   * @param {boolean} [extraOptions.moveItems]
   * @returns {function}
   */
  const toggleSPForm =
    (type = FORM_MODES.create, open = false, { moveItems = false } = {}) =>
    () => {
      const openCreateForm = type === FORM_MODES.create || type === FORM_MODES.update;
      const chosenForm = openCreateForm ? 'isCreateSearchProjectOpen' : 'isAddToSearchProjectOpen';
      setUiState(prev => ({
        ...prev,
        [chosenForm]: open,
        formMode: openCreateForm ? FORM_MODES[type] : prev.formMode,
        moveItemsFromSearchProject: moveItems
      }));
    };

  /**
   * @param {'create' | 'add' | 'update'} event
   * @param {boolean} success - If the event was executed without errors 'success' will
   * @param {Object} [searchProject]
   * have a truthy value
   */
  const handleFormEvent = (success, event, searchProject) => {
    if (success) {
      if (event === FORM_MODES.update) {
        onUpdateSearchProject && onUpdateSearchProject(searchProject);
      }
      setSelectionToInitial && setSelectionToInitial();
      toggleSPForm(event, false)();
    }
  };

  const handleRemoveItemsFromSP =
    ({ searchProjectId, onRemove }) =>
    async ok => {
      if (!ok) return;
      const paramsType =
        createdFrom === CreatedFromVariants.BulkMetrics
          ? ParamsTypes.MetricsParams
          : ParamsTypes.SearchParams;
      const response = await removeItemsFromSearchProject({
        searchProjectId,
        itemsToRemove: filteredItems,
        queryParams,
        paramsType,
        bulkId
      });
      if (response.success) {
        onRemove && onRemove();
      } else {
        dispatch(showAlert(response.alert));
      }
    };

  const handleRemoveClick =
    ({ id: searchProjectId, dialogCopies, onRemove }) =>
    () =>
      dispatch(
        confirm({
          severity: SeverityOptions.warning,
          ...dialogCopies,
          mustComplete: true,
          closeDialogOnConfirm: false,
          onConfirm: handleRemoveItemsFromSP({ searchProjectId, onRemove })
        })
      );

  const menuItems = {
    add: {
      title: enableMoveActions ? menuItemsCopies.copy.toExisting.title : menuItemsCopies.add.title,
      action: toggleSPForm(FORM_MODES.add, true),
      visible: true
    },
    create: {
      title: enableMoveActions ? menuItemsCopies.copy.toNew.title : menuItemsCopies.create.title,
      action: toggleSPForm(FORM_MODES.create, true),
      visible: true
    },
    remove: {
      title: menuItemsCopies.remove.title,
      action: handleRemoveClick,
      visible: true
    },
    move: {
      toExisting: {
        title: menuItemsCopies.move.toExisting.title,
        action: toggleSPForm(FORM_MODES.add, true, { moveItems: true }),
        visible: true
      },
      toNew: {
        title: menuItemsCopies.move.toNew.title,
        action: toggleSPForm(FORM_MODES.create, true, { moveItems: true }),
        visible: true
      }
    }
  };

  const SearchProjectAction = ({ text, tooltipText }) => {
    const buttonProps = {
      iconBtn: {
        isIconButton: true,
        icon: SvgSearchProjects,
        iconProps: {
          onSetColor: ({ primary }) => primary.main
        },
        'data-testid': 'sp-action-btn'
      },
      textBtn: {
        text,
        size: 'small',
        variant: 'outlined'
      }
    };
    const defaultItems = [menuItems.add, menuItems.create];
    const allItems = [...defaultItems, menuItems.move.toExisting, menuItems.move.toNew];
    return (
      <FPButtonMenu
        menuItems={enableMoveActions ? allItems : defaultItems}
        title={tooltipText || 'Search Project'}
        {...(text ? buttonProps.textBtn : buttonProps.iconBtn)}
      />
    );
  };

  const handleAddItemsToSearchProject = (success, event) => {
    handleFormEvent(success, event);
    !shouldRedirectToSearchProjectPreview && handleRefreshSearchProjectsTab(true);
  };

  const SearchProjectForms = ({ bulkEmailId, searchProject }: SearchProjectFormsProps) => (
    <>
      {uiState.isAddToSearchProjectOpen && (
        <AddToSearchProjectDrawer
          bulkId={bulkEmailId}
          currentSearchProjectId={searchProject?.id}
          open={uiState.isAddToSearchProjectOpen}
          onClose={toggleSPForm(FORM_MODES.add, false)}
          onRefreshSearchProjectsTab={handleRefreshSearchProjectsTab}
          onAddItemsToSP={handleAddItemsToSearchProject}
          moveCopiedItems={uiState.moveItemsFromSearchProject}
          shouldRedirectToSearchProjectPreview={shouldRedirectToSearchProjectPreview}
          {...formCommonProps}
        />
      )}
      {uiState.isCreateSearchProjectOpen && (
        <SearchProjectFormDrawer
          bulkId={bulkEmailId}
          open={uiState.isCreateSearchProjectOpen}
          onClose={toggleSPForm(FORM_MODES.create, false)}
          onSubmitSearchProject={handleFormEvent}
          mode={uiState.formMode}
          moveCopiedItems={uiState.moveItemsFromSearchProject}
          searchProject={searchProject}
          {...formCommonProps}
        />
      )}
    </>
  );

  return {
    handleRemoveFromSearchProject: handleRemoveClick,
    SearchProjectMenuItems: menuItems,
    SearchProjectAction,
    SearchProjectForms,
    toggleSPForm
  };
};

export const useSearchProjectInProfiles = ({
  endpoint,
  entity,
  profile,
  tabKey,
  triggerTabRefresh,
  shouldRedirectToSearchProjectPreview
}) => {
  const [searchProjectsUiState, setSearchProjectsUiState] = useState({
    shouldRefreshSearchProjectsTab: false
  });

  const handleRefreshSearchProjectsTab = refresh => {
    setSearchProjectsUiState(prev => ({
      ...prev,
      shouldRefreshSearchProjectsTab: refresh
    }));
  };

  const formattedSPTabCopies = {
    ...searchProjectsTabCopies,
    emptyPlaceholderSubtitle: strings.formatString(
      searchProjectsTabCopies.emptyPlaceholderSubtitle,
      { entity }
    )
  };

  const getRemoveDialogCopies = searchProjectName => ({
    ...removeProfileDialogCopies,
    message: strings.formatString(removeProfileDialogCopies.message, {
      entity,
      itemName: profile.name,
      spName: searchProjectName
    })
  });

  const {
    SearchProjectForms,
    handleRemoveFromSearchProject,
    SearchProjectMenuItems,
    toggleSPForm
  } = useSearchProject({
    shouldRedirectToSearchProjectPreview,
    handleRefreshSearchProjectsTab,
    filteredItems: {
      type: 'include',
      data: [
        {
          id: parseInt(profile.id, 10),
          type: entity
        }
      ]
    }
  });

  const handleRemoveNewProfileFromSP = ({ searchProjectId, copies }) =>
    handleRemoveFromSearchProject({
      id: searchProjectId,
      dialogCopies: copies.removeDialog,
      onRemove: () => triggerTabRefresh(TabKeys.SearchProjects)
    })();

  const searchProjectTabPropsDefinition = {
    id: TabKeys.SearchProjects,
    tabProps: {
      copies: {
        tab: {
          actionText: formattedSPTabCopies.addButtonText,
          title: formattedSPTabCopies.emptyPlaceholderTitle,
          subtitle: formattedSPTabCopies.emptyPlaceholderSubtitle
        },
        getRemoveDialogCopies
      },
      endpoint,
      key: tabKey,
      onOpenAddToSearchProjectForm: toggleSPForm(FORM_MODES.add, true),
      onRemoveProfileFromSearchProject: handleRemoveNewProfileFromSP,
      profileId: profile?.id,
      shouldRefresh: searchProjectsUiState.shouldRefreshSearchProjectsTab
    }
  };

  const profileMenuItems = [
    {
      ...SearchProjectMenuItems.create,
      icon: null
    },
    {
      ...SearchProjectMenuItems.add,
      icon: null
    }
  ];

  return {
    SearchProjectForms,
    SearchProjectsMenuItems: profileMenuItems,
    searchProjectTabPropsDefinition
  };
};
