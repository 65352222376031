// @flow
import { FormSchema } from 'UI/constants/entityTypes';
import { getInitials, getQuestionsCounts } from 'utils';

type FormatDataSheetDataForAPIParams = {
  id?: string,
  formData: Object,
  schemaName: string,
  schemaVersion: number,
  progress: { answered: number, total: number },
  user: { id: string, email: string, personalInformation: { full_name: string } }
};

const ANSWER = {
  Positive: 'Yes',
  Negative: 'No'
};

export const getDataSheetCount = (formData: Object, questionsPaths: Object, schemaName: string) => {
  if (!formData) {
    return {
      total: 0,
      answered: 0
    };
  }

  let finalQuestionsPaths = { ...questionsPaths };

  if (formData.establishingUrgency.onAScaleOf1To10?.question === '10') {
    const {
      'establishingUrgency.onAScaleOf1To10.whatKeepsYouFromBeingA10': coverDetails,
      ...rest
    } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (
    schemaName !== FormSchema.CandidateDataSheetQuick &&
    formData.establishingUrgency.onAScaleOf1To10?.haveYouMadeSomething?.question === ANSWER.Negative
  ) {
    const {
      'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.whatStepsHaveYouTaken':
        stepsHaveYouTaken,
      'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.whatCompaniesHaveYouAppliedTo':
        companiesHaveYouAppliedTo,
      ...rest
    } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (formData.workPreferences.relocation.areYouWillingToRelocate === ANSWER.Negative) {
    const { 'workPreferences.relocation.relocationDestinations': relocationDestinations, ...rest } =
      finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  return getQuestionsCounts(formData, finalQuestionsPaths);
};

export const formatDataSheetDataForAPI = ({
  id,
  formData,
  schemaName,
  schemaVersion,
  progress,
  user
}: FormatDataSheetDataForAPIParams) => {
  return {
    ...(id && { id }),
    data: formData,
    schema: {
      key: schemaName,
      version: schemaVersion
    },
    progress: {
      current: progress.answered,
      total: progress.total
    },
    trace: {
      user: {
        id: user.id,
        fullName: user.personalInformation.full_name,
        email: user.email,
        initials: getInitials(user.personalInformation.full_name)
      }
    }
  };
};

export const convertDraftToBlueSheetFormat = draftData => {
  return {
    ...draftData,
    time_looking: draftData.timeLooking,
    reason_leaving: draftData.reasonLiving,
    minimum_salary: draftData.minimumSalary,
    no_brainer_salary: draftData.noBrainerSalary,
    good_salary: draftData.goodSalary
  };
};
