import React from 'react';
import {
  FinancialProductivityStatsSummary,
  HealthDistribution,
  Layout,
  ProductionCashInByMonth
} from 'features/command-center';
import { RegionalDirectorCards } from 'features/command-center/components/finance/RegionalDirectorCards';
import { useRefreshStats } from 'features/command-center/hooks';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import { PageTitles } from 'UI/constants/defaults';

import { ContentWrapper } from './Overview.styled';

export const Overview = () => {
  usePageTitle({ title: PageTitles.CommandCenterOverview });
  const { refreshData, refreshKey } = useRefreshStats();

  return (
    <Layout
      title={strings.commandCenter.overview.title}
      path={strings.commandCenter.title}
      onRefresh={refreshData}
    >
      <ContentWrapper key={refreshKey}>
        <HealthDistribution />
        <FinancialProductivityStatsSummary />
        <ProductionCashInByMonth />
        <RegionalDirectorCards />
      </ContentWrapper>
    </Layout>
  );
};
