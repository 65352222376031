// @flow
import React, { useContext } from 'react';
import { ChipProps } from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { AssignableEntities, TeamworkEntities, useAccountability } from 'hooks/accountability';
import { useCreateProfileView } from 'hooks/createProfileView';
import { UserProviderContext } from 'providers/UserProvider';
import {
  canUserEditEntity,
  Permissions,
  userHasPermission,
  userHasRole,
  userHasRoles
} from 'services/Authorization';
import strings from 'strings';
import type { ContentPageLayoutProps, InventoryTypeProps, TitleLabelProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import ProfileRecruitersView from 'UI/components/organisms/inventoryProfiles/ProfileRecruitersView';
import SkeletonWrapper from 'UI/components/organisms/inventoryProfiles/SkeletonWrapper';
import { SHARED_SKELETONS_PROPS } from 'UI/components/organisms/inventoryProfiles/SkeletonWrapper/constants';
import { EntityType } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { nestTernary } from 'UI/utils';
import { accountablePermissionsManagement, getEntityType } from 'UI/utils/inventory';

import FullWidthCardHeader from '../FullWidthCardHeader';

import ProfileSummary from './ProfileSummary';
import ProfileTabsWrapper from './ProfileTabsWrapper';
import { useStyles } from './styles';

type HeaderCardProps = {
  sectionName: string,
  title: string,
  titleLabelProps?: TitleLabelProps
};

type MenuProps = {
  customMenuItems: array,
  onEditClick: () => void,
  onReassignClick: () => {},
  writeUpActions: Array
};

type InventoryProfileTabsProps = {
  endpoint: string,
  profileTabProps: Object
};

type InventoryProfileLayoutProps = {
  activityLabel: string,
  addItemLabel?: string,
  additionalHeaderActions?: any,
  company?: string,
  entityType?: any,
  headerCardProps?: HeaderCardProps,
  contentPageLayoutProps: ContentPageLayoutProps,
  inventoryType?: InventoryTypeProps,
  inventoryTypeChipProps?: ChipProps,
  isLoading?: boolean,
  profileActionsProps?: Object,
  profileMenuProps?: MenuProps,
  ProfileTabsProps: InventoryProfileTabsProps,
  profileTextDisplay?: React.Node,
  recruiters?: Array<Object>,
  results?: Object,
  statusBar?: React.Node,
  summaryLabels?: Array<Object>,
  enableUrlNavigation: boolean
};

const { buttons } = SHARED_SKELETONS_PROPS;

const InventoryProfileLayout = ({
  activityLabel,
  additionalHeaderActions,
  entityType,
  contentPageLayoutProps,
  headerCardProps: { sectionName, title, titleLabelProps },
  inventoryType,
  inventoryTypeChipProps,
  isChangeLogsTabEnabled,
  isLoading,
  profileActionsProps,
  profileMenuProps: {
    onEditClick,
    customMenuItems = [],
    writeUpActions = [],
    onReassignClick,
    isEditionCheckIgnored = false
  },
  ProfileTabsProps: { profileTabProps, endpoint },
  results,
  statusBar,
  summaryLabels,
  enableUrlNavigation
}: InventoryProfileLayoutProps) => {
  const [user] = useContext(UserProviderContext);
  const classes = useStyles();

  const entityId = entityType?.id;
  const matchProfile = getEntityType(entityId);
  useCreateProfileView({ id: results?.id, endpoint: matchProfile?.trackingUrl });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C O L L A B O R A T I O N    P E R M I S S I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const userHasPermissionsToEdit = canUserEditEntity(user, results, {
    includeCollaborator: true
  });
  const canEdit =
    userHasPermissionsToEdit ||
    userHasRoles([Roles.Operations, Roles.DataCoordinator]) ||
    isEditionCheckIgnored;
  const isUserCoach = userHasRole(Roles.Coach);
  const isUserRecruiter = userHasRole(Roles.Recruiter);

  const {
    accountableRecruiter,
    assistantForAccountableRecruiter,
    assistantRecruiter,
    isAssistantRecruiter,
    isItemMine,
    isTeamworkEntity,
    isAccountableCoach,
    isAccountableRecruiter,
    isMainCoach,
    isMainRecruiter
  } = useAccountability(user, results, entityType);

  const isEntityAssignable = AssignableEntities.includes(entityType?.id);

  const enableRecruitersManagement =
    ((isUserCoach && canEdit) || userHasPermission(Permissions.Inventory.OverrideAssignment)) &&
    isEntityAssignable;

  const manageCollaboratorPermissions =
    isUserRecruiter && canEdit && TeamworkEntities.includes(entityType?.id);

  const showRecruiterAndAccountable =
    (isTeamworkEntity && results?.free_game && !isAssistantRecruiter) || !!accountableRecruiter;

  const requestButtonText = isUserCoach
    ? strings.inventoryProfiles.common.assignRecruiter
    : nestTernary(
        entityType.id === EntityType.Candidate,
        strings.inventoryProfiles.common.startMarketing,
        strings.inventoryProfiles.common.startRecruiting
      );

  const { isAssistantButtonEnabled, isAssistantForAccountableButtonEnabled } =
    accountablePermissionsManagement({
      assistantForAccountableRecruiter,
      assistantRecruiter,
      isAccountableCoach,
      isAccountableRecruiter,
      isMainCoach,
      isMainRecruiter,
      isTeamworkEntity
    });

  const menuItems = [
    {
      title: strings.inventoryProfiles.common.editProfile,
      action: onEditClick,
      visible: canEdit
    },
    ...writeUpActions,
    {
      title: `${enableRecruitersManagement ? 'Manage' : 'View Assigned'} Recruiters`,
      action: onReassignClick,
      visible: isEntityAssignable
    },
    {
      title: assistantRecruiter?.recruiter ? 'Manage my collaborator' : 'Add collaborator',
      action: onReassignClick,
      visible: manageCollaboratorPermissions
    },
    ...customMenuItems
  ];

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C O M P O N E N T   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const lastActivityLabel = activityLabel ? (
    <>
      <strong> Last Activity: </strong> {activityLabel}
    </>
  ) : (
    <>No recent activity</>
  );

  const isQuickViewMode = !enableUrlNavigation;

  return (
    <FullWidthCardHeader
      additionalHeaderActions={additionalHeaderActions}
      additionalCardClasses={isQuickViewMode && classes.containedProfile}
      headerCardProps={{
        title,
        sectionName,
        titleLabelProps: { ...titleLabelProps, backNavigation: !isQuickViewMode }
      }}
      isLoading={isLoading}
      menuItems={menuItems}
      statusBar={statusBar}
      subtitle={lastActivityLabel}
      headerContent={
        <>
          <ProfileSummary
            results={results}
            isLoading={isLoading}
            inventoryType={inventoryType}
            inventoryTypeChipProps={inventoryTypeChipProps}
            summaryLabels={summaryLabels}
          />

          <div className={classes.accountableBar}>
            <Grid
              alignItems="center"
              className={classes.wrapper}
              container
              justify="flex-start"
              spacing={1}
            >
              {!isLoading && showRecruiterAndAccountable ? (
                <>
                  {results?.recruiter && (
                    <Grid item>
                      <ProfileRecruitersView
                        assistant={assistantRecruiter}
                        coach={results?.coach}
                        isReassignButtonVisible={isAssistantButtonEnabled}
                        loading={isLoading}
                        onReassignClick={onReassignClick}
                        recruiter={results?.recruiter}
                      />
                    </Grid>
                  )}
                  {accountableRecruiter ? (
                    <>
                      <Grid item>
                        <Divider orientation="vertical" className={classes.divider} />
                      </Grid>
                      <Grid>
                        <ProfileRecruitersView
                          assistant={assistantForAccountableRecruiter}
                          coach={accountableRecruiter?.coach}
                          loading={isLoading}
                          onReassignClick={onReassignClick}
                          recruiter={accountableRecruiter?.recruiter}
                          isReassignButtonVisible={isAssistantForAccountableButtonEnabled}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      {!isItemMine ? (
                        <FPActionButton
                          iconPosition="none"
                          onClick={onReassignClick}
                          size="small"
                          style={{ whiteSpace: 'nowrap' }}
                          text={requestButtonText}
                          type="button"
                          variant="outlined"
                        />
                      ) : null}
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {results?.recruiter && (
                    <Grid item>
                      <ProfileRecruitersView
                        assistant={assistantRecruiter}
                        coach={results?.coach}
                        isReassignButtonVisible={isAssistantButtonEnabled}
                        loading={isLoading}
                        onReassignClick={onReassignClick}
                        recruiter={results?.recruiter}
                      />
                    </Grid>
                  )}
                </>
              )}

              {profileActionsProps ? (
                <Grid item xs={6} container justify="flex-end">
                  <SkeletonWrapper
                    skeletonsCount={1}
                    spacing={2}
                    skeletonProps={buttons}
                    gridProps={{
                      justify: 'flex-end'
                    }}
                  >
                    <FPActionButton size="small" {...profileActionsProps} />
                  </SkeletonWrapper>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </>
      }
      pageContent={
        <ProfileTabsWrapper
          endpoint={endpoint}
          entityId={entityId}
          entityType={entityType}
          isChangeLogsTabEnabled={isChangeLogsTabEnabled}
          isLoading={isLoading}
          profileTabProps={profileTabProps}
          profileMenuProps={{
            onEditClick
          }}
          results={results}
          enableUrlNavigation={enableUrlNavigation}
        />
      }
      contentPageLayoutProps={contentPageLayoutProps}
    />
  );
};

InventoryProfileLayout.defaultProps = {
  activityLabel: '',
  addItemLabel: '',
  additionalHeaderActions: null,
  company: undefined,
  entityType: [],
  enableUrlNavigation: true,
  headerCardProps: { sectionName: '', title: '', titleLabelProps: null },
  inventoryType: null,
  isLoading: false,
  profileActionsProps: undefined,
  profileMenuProps: { customMenuItems: [], onEditClick: null },
  profileTextDisplay: null,
  ProfileTabsProps: {
    tabsProps: {}
  },
  recruiters: [],
  results: {},
  statusBar: undefined,
  inventoryTypeChipProps: null,
  summaryLabels: []
};

export default InventoryProfileLayout;
