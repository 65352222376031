export const FORM_FIELDS_MAP = {
  ProjectName: {
    key: 'projectName'
  },
  Scope: {
    key: 'scope'
  },
  AddEmployees: {
    key: 'addEmployees'
  },
  AddHiringAuthorities: {
    key: 'addHiringAuthorities'
  },
  ExcludeCompanies: {
    key: 'excludeCompanies'
  }
};
