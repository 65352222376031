import strings from 'strings';
import { formatRatio } from 'UI/utils';

export const formatResponse = data => {
  if (!data || !data?.calls) return [];

  const { members, performance } = data.calls;

  const percentage = performance.total / performance.goal;

  return [
    {
      title: strings.commandCenter.activity.callsGauge.chart.goal,
      value: formatRatio(performance.total ?? 0, performance.goal ?? 0, 'scalar', {
        notation: 'compact'
      }),

      percentage,
      goal: performance.goal,
      valuePosition: 'top'
    },
    {
      title: strings.commandCenter.activity.callsGauge.chart.members,
      value: formatRatio(members.total ?? 0, members?.goal),
      valuePosition: 'top'
    }
  ];
};
