// @flow
import React from 'react';
import { Grid } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import { EntityRoutes } from 'routes/constants';
import type { InventoryTypeProps } from 'types/app';
import FPLink from 'UI/components/atoms/FPLink';
import Text from 'UI/components/atoms/Text';
import ProfileStatusChip from 'UI/components/molecules/ProfileStatusChip';
import { SHARED_SKELETONS_PROPS } from 'UI/components/organisms/inventoryProfiles/SkeletonWrapper/constants';

import EntitySummary from '../EntitySummary';

import { useStyles } from './styles';

const { text } = SHARED_SKELETONS_PROPS;

export const GridItems = ({ gridItems, loading }) =>
  gridItems.map(gridItem => {
    const { Component, componentProps, shouldRender, id } = gridItem;

    return (
      shouldRender && (
        <Grid item xs="auto" key={id}>
          {loading ? <Skeleton {...text} /> : <Component {...componentProps} />}
        </Grid>
      )
    );
  });

type ProfileSummaryProps = {
  results: Object,
  isLoading: boolean,
  inventoryType?: InventoryTypeProps,
  inventoryTypeChipProps?: ChipProps,
  summaryLabels?: Array<Object>
};

const ProfileSummary = ({
  results,
  isLoading,
  inventoryType,
  inventoryTypeChipProps,
  summaryLabels
}: ProfileSummaryProps) => {
  const classes = useStyles();

  const itemStatus = results?.status;

  const companyName = results?.company?.name || results?.current_company;
  const city = results?.address?.city?.title || results?.personalInformation?.address?.city?.title;
  const companyId = results?.company?.id || results?.company_id;
  const createdByName = results?.createdBy?.personalInformation?.full_name;
  const currentCompany = !isLoading && companyName;
  const itemCreationDate = results?.created_at;
  const slug =
    results?.address?.city?.state?.slug || results?.personalInformation?.address?.city?.state?.slug;

  const gridItems = [
    {
      id: 'companyLink',
      Component: FPLink,
      shouldRender: !isLoading && results?.id && companyId,
      componentProps: {
        itemId: companyId,
        'aria-label': 'Company Name',
        children: currentCompany,
        linkProps: {
          type: 'local',
          url: EntityRoutes.CompanyProfile,
          target: '_self'
        }
      }
    },
    {
      id: 'company',
      Component: () => (
        <span>
          <strong>{currentCompany}</strong>
        </span>
      ),
      shouldRender: !!currentCompany && !companyId
    },
    {
      id: 'location',
      Component: Text,
      shouldRender: city && slug,
      componentProps: { text: `${city}, ${slug}` }
    },
    {
      id: 'status',
      Component: ProfileStatusChip,
      shouldRender: !!results,
      componentProps: {
        inventoryType,
        itemStatus,
        optOutStatus: results?.optOutStatus,
        inventoryTypeChipProps,
        emailValidationStatus: results.emailValidation?.verdict?.name,
        otherEmailValidationStatus: results.personalEmailValidation?.verdict?.name
      }
    }
  ];

  return (
    <div className={classes.profileInfoBar}>
      <Grid alignItems="center" container justify="space-between" spacing={1}>
        <Grid item container spacing={2} alignItems="center" xs={7}>
          <GridItems gridItems={gridItems} loading={isLoading} />
        </Grid>
        <Grid item xs={5} container justify="flex-end">
          <EntitySummary
            isLoading={isLoading}
            summaryContentProps={{
              creationDate: itemCreationDate,
              creationBy: createdByName
            }}
            summaryLabels={summaryLabels}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileSummary;
