// @flow
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Checkbox, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import { useGlobalStyles } from 'GlobalStyles';

type CustomDataTableCheckboxTypes = {
  all: number,
  perPage: number,
  totalInPage: number,
  itemType: string,
  onSelectionChange: (type: string) => void
};

const CustomDataTableCheckbox = ({
  all,
  totalInPage,
  someSelected,
  itemType,
  onSelectionChange,
  ...rest
}: CustomDataTableCheckboxTypes) => {
  return rest['data-description'] !== 'row-select-header' ? (
    <Checkbox {...rest} />
  ) : (
    <SelectionMenu
      all={all}
      totalInPage={totalInPage}
      someSelected={someSelected}
      itemType={itemType}
      onSelectionChange={onSelectionChange}
    />
  );
};

const SelectionMenu = ({ all, totalInPage, someSelected, itemType, onSelectionChange }) => {
  const globalClasses = useGlobalStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = e => setAnchorEl(e.currentTarget);
  const handleClick = select => () => {
    handleClose();
    onSelectionChange && onSelectionChange(select);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton aria-label="open-select-menu" onClick={handleOpen}>
        {someSelected ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
      </IconButton>
      <Menu
        id="check-menu"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClick('page')}>
          Select&nbsp;
          <NumberFormat displayType="text" thousandSeparator value={totalInPage} />
          &nbsp;{itemType} on this page
        </MenuItem>
        <MenuItem onClick={handleClick('all')}>
          Select all&nbsp;
          <NumberFormat displayType="text" thousandSeparator value={all} />
          &nbsp;{itemType}
        </MenuItem>
        <Divider className={globalClasses.tightDivider} />
        <MenuItem onClick={handleClick('clear-all')}>Unselect All</MenuItem>
      </Menu>
    </>
  );
};

export default CustomDataTableCheckbox;
