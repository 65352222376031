import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getFinancialProductivityStatsSummary } from 'features/command-center/services';
import strings from 'strings';

import { LastUpdate } from './FinancialProductivityStatsSummary.styled';
import { getLastUpdateEvery30Minutes } from './FinancialProductivityStatsSummary.utils';

export const FinancialProductivityStatsSummary = () => {
  return (
    <StatsItemsCard
      header={strings.commandCenter.finance.financialProductivityStatsSummary.title}
      promise={getFinancialProductivityStatsSummary}
      subheader={
        <>
          {strings.commandCenter.finance.financialProductivityStatsSummary.subtitle}. Last Update:{' '}
          <LastUpdate component="span" variant="caption">
            {getLastUpdateEvery30Minutes()}
          </LastUpdate>
        </>
      }
      textAlign="center"
    />
  );
};
