// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showAlert } from 'actions/app';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
/** Components */
import DataTable from 'UI/components/organisms/DataTable';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { CommunicationActionPreset, PageTitles } from 'UI/constants/defaults';
/** API / EntityRoutes / Endpoints / EntityType */
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { createCustomStaticRanges, hasFeatureFlag } from 'UI/utils';
import { formatResponseBuilder } from 'UI/utils/inventory';

import {
  ColumnsDefinitions,
  FiltersGroups,
  ROLES_COLUMN,
  ROLES_NAME,
  RosterDateFilters,
  RosterPeriodColumnBySection
} from './columns';

const PAGE_KEY = 'roster-list';
const orderByOptions = {
  column: 'full_name',
  direction: 'asc'
};

const keywordsDefinition = [
  {
    entry: ROLES_NAME,
    valuesToAttach: [
      {
        name: 'id',
        value: 'id'
      },
      {
        name: 'roles',
        value: 'roles'
      },
      {
        name: 'full_name',
        value: 'full_name'
      }
    ]
  }
];

const handleResponseChange = response => formatResponseBuilder(response)(keywordsDefinition);

const NAME_KEY = 'full_name';

const RostersList = ({ location }) => {
  const initialDateFilter = RosterDateFilters[0];
  const [dateTypeFilter, setDateTypeFilter] = useState(initialDateFilter);

  const isRecruiterProfileActive = hasFeatureFlag(FeatureFlags.RecruiterProfile);

  const rosterColumns = ColumnsDefinitions.map(item =>
    isRecruiterProfileActive && item?.name === NAME_KEY ? ROLES_COLUMN : item
  );

  const initialPreferences = getColumnPreferences(
    PAGE_KEY,
    0,
    orderByOptions,
    rosterColumns,
    0,
    location.search
  );

  const {
    columnPreferences,
    count,
    data,
    filters,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    handleSectionChange,
    listState
  } = useDatatable({
    key: PAGE_KEY,
    columnsDefinitions: rosterColumns,
    endpoint: `${Endpoints.Users}/${Endpoints.Roster}`,
    entityName: 'Roster',
    entityType: EntityType.Roster,
    initialPreferences,
    initialSection: initialDateFilter.id,
    orderByOptions,
    periodDefaultValue: null,
    periodFilterColumn: RosterPeriodColumnBySection,
    shouldScrollOnNavigate: true,
    formatResponse: handleResponseChange
  });

  useEffect(() => {
    document.title = PageTitles.Roster;
  }, []);

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;

  const phoneActionButtons = buildRowActionsRendererDefinition({
    actions: CommunicationActionPreset,
    namespace: EntityType.Roster
  });

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const finalColumns = getColumnsToRender([...columns, phoneActionButtons]);

  return (
    <FiltersLayout
      title="Roster"
      section={EntityType.Roster}
      filters={filters}
      groups={FiltersGroups}
      isLoading={showWholeSkeleton}
      isSideMenuOpen={isSideMenuOpen}
      defaultRange={null}
      disableTimeRange
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={RosterDateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      enableStore={false}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      onSearch={handleFiltersApply}
      titleLabelProps={backNavigateListConfig}
    >
      <DataTable
        isExpandable
        loading={showWholeSkeleton}
        refreshing={isLoading}
        data={data}
        columns={finalColumns}
        count={count}
        filter={false}
        page={page}
        rowsPerPage={perPage}
        searchText={keyword}
        sortOrder={{ name: orderBy, direction }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        draggableColumns={{
          enabled: true
        }}
        enableCustomFilters
        onToggleFilters={handleFiltersToggle}
        onSearchTextChange={handleKeywordChange}
        onColumnSortChange={handleColumnSortChange}
        onPerPageClick={handlePerPageChange}
        onPageClick={handlePageChange}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        selectableRows="none"
      />
    </FiltersLayout>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

export default connect(null, mapDispatchToProps)(RostersList);
