// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { showAlert } from 'actions/app';
import { setBulkStatsOpen } from 'actions/bulkEmail';
import { THEME } from 'GlobalStyles';
import { useFetch } from 'hooks/fetch';
import moment from 'moment';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import FPLink from 'UI/components/atoms/FPLink';
import Text from 'UI/components/atoms/Text';
import BulkStatsInfoCards from 'UI/components/molecules/BulkStatsInfoCards';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { BulkScopes } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { dateFormatter, hasFeatureFlag } from 'UI/utils';

import StatsListing from './StatsListing';
import { useStyles } from './styles';

type BulkStatsDrawerProps = {
  onClose: () => any,
  emailId: number,
  sentDate: string
};

const RESUME_TITLE_FONT_SIZE = 24;

const {
  shared: sharedCopies,
  bulkEmails: {
    stats: {
      title,
      alerts: { info: infoAlerts },
      details: detailsCopies,
      resume: { openRate }
    }
  }
} = strings;

const EMAIL_FREQUENCY_URL =
  window.GPAC_ENV?.SUPPORT_EMAIL_FREQUENCY_URL || process.env.REACT_APP_SUPPORT_EMAIL_FREQUENCY_URL;

const getFormattedDate = date => moment(date).fromNow();

const BulkStatsDrawer = ({ onClose }: BulkStatsDrawerProps) => {
  const classes = useStyles();

  const {
    id: bulkId,
    emailConfig: { bulkType },
    userInfo
  } = useSelector(state => state.bulkEmail.domain.bulkEmailInPreview.data);
  const dispatch = useDispatch();
  const [metricToShow, setMetricToShow] = useState(null);

  const [uiState, setUiState] = useState({
    showDetails: false,
    isQuickViewActive: false
  });

  const [stats, statsError] = useFetch(Endpoints.BulkEmailMetrics.replace(/:id/, bulkId));

  const searchProject = stats?.sentSearchProjectInfo?.searchProject;
  const formattedDate = getFormattedDate(stats?.sentSearchProjectInfo?.send_date);

  useEffect(() => {
    statsError &&
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error retrieving stats data',
          body: statsError,
          autoHideDuration: 15000
        })
      );
  }, [dispatch, statsError]);

  const handleSPClick = () => dispatch(setBulkStatsOpen(false));

  const isQuickViewActionsEnabled = hasFeatureFlag(FeatureFlags.QuickViewProfiles);

  const handleGoBack = e => {
    e.preventDefault();

    const handleBulkStatsManager = () => {
      setUiState(prev => ({ ...prev, showDetails: false, isQuickViewActive: false }));
      setMetricToShow(null);
    };

    uiState.isQuickViewActive && !isQuickViewActionsEnabled
      ? setUiState(prev => ({
          ...prev,
          showDetails: !isQuickViewActionsEnabled,
          isQuickViewActive: false
        }))
      : handleBulkStatsManager();
  };

  const footerActionsProps = metricToShow
    ? {
        primaryProps: {
          text: sharedCopies.ui.goBack,
          onClick: handleGoBack
        },
        secondaryProps: {
          text: sharedCopies.ui.close,
          onClick: onClose
        }
      }
    : {
        hideSecondaryButton: true,
        primaryProps: {
          text: sharedCopies.ui.close,
          onClick: onClose
        }
      };

  const bulkDetails = [
    {
      section: detailsCopies.sections.from,
      value: userInfo?.email
    },
    {
      section: detailsCopies.sections.searchProject,
      component: (
        <FPLink
          itemId={searchProject?.id}
          linkProps={{
            url: EntityRoutes.SearchProjectPreview,
            type: 'local',
            isRoute: true,
            onClick: handleSPClick,
            className: classes.searchProjectLink
          }}
        >
          {searchProject?.name || ''}
        </FPLink>
      )
    },
    {
      section: detailsCopies.sections.bulkType,
      value: BulkScopes.ById[bulkType?.id] || ''
    },
    {
      section: detailsCopies.sections.date,
      value: dateFormatter(
        stats?.sentSearchProjectInfo?.send_date,
        format => format.MonthDayYearTime
      )
    }
  ];

  const chartProps = useMemo(() => {
    const openPercentage = Number(stats?.metricsSummary?.open_ratio.toFixed(2));
    const unopenedPercentage = 100 - stats?.metricsSummary?.open_ratio?.toFixed(2);
    const hasOneSliceOnly = openPercentage === 100 || unopenedPercentage === 100;
    return {
      data: [
        ['Status', 'Emails'],
        ['Open', openPercentage],
        ['Unopened', unopenedPercentage]
      ],
      options: {
        pieHole: 0.4,
        pieSliceTextStyle: {
          color: hasOneSliceOnly && THEME.palette.primary.light
        },
        is3d: false,
        tooltip: { trigger: 'none' },
        backgroundColor: THEME.palette.secondary.FP100,
        slices: {
          0: { color: THEME.palette.primary.light },
          1: { color: THEME.palette.primary.FP100 }
        }
      }
    };
  }, [stats]);

  const handleMetricToShow = selectedMetric => {
    setUiState(prev => ({ ...prev, showDetails: true }));
    setMetricToShow(selectedMetric);
  };

  const toggleQuickViewActive = (isActive: boolean) =>
    setUiState(prev => ({ ...prev, isQuickViewActive: isActive }));

  return (
    <DrawerContentLayout
      title={uiState.showDetails ? metricToShow?.title : title}
      containerComponent="div"
      onClose={onClose}
      onBack={handleGoBack}
      isBottomToolbarNeeded={!uiState.isQuickViewActive}
      uiState={{
        isFormLoading: !stats,
        showBackNavigation: !!metricToShow
      }}
      drawerProps={{
        open: true,
        classes: { paper: classes.drawerRoot }
      }}
      contentProps={{
        style: uiState.showDetails ? { padding: 0 } : {}
      }}
      footerActionsProps={footerActionsProps}
    >
      {uiState.showDetails ? (
        <StatsListing
          status={metricToShow?.status}
          emailId={bulkId}
          quickViewHandlerProps={{
            onQuickViewClick: toggleQuickViewActive,
            enableNavigationControls: uiState.isQuickViewActive,
            shouldCloseQuickView: uiState.isQuickViewActive
          }}
        />
      ) : (
        <Grid container justify="space-around">
          <Grid item className={classes.infoSnackbar} xs={12}>
            <Alert
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  href={EMAIL_FREQUENCY_URL}
                  target="_blank"
                  className={classes.statsAlertAction}
                  variant="outlined"
                >
                  Learn More
                </Button>
              }
            >
              <Text
                text={strings.formatString(infoAlerts.resume.description, {
                  formattedDate
                })}
              />
            </Alert>
          </Grid>
          <Grid container item direction="column" xs={4} className={classes.resume}>
            <Grid container item direction="column" className={classes.details}>
              <Text variant="h2" text={detailsCopies.title} fontSize={RESUME_TITLE_FONT_SIZE} />
              {bulkDetails.map(({ section, value, component = null }) => (
                <Grid key={section} container item direction="column">
                  <Text variant="caption" text={section.toUpperCase()} />
                  {component || (
                    <Text variant="body1" text={value} className={classes.detailValue} cropped />
                  )}
                </Grid>
              ))}
            </Grid>
            <Grid container item direction="column" className={classes.details}>
              <Text variant="h2" text={openRate.title} fontSize={RESUME_TITLE_FONT_SIZE} />
              <Text variant="body1" text={openRate.description} />
              <Grid container item justify="center" className={classes.chart}>
                <Chart {...chartProps} chartType="PieChart" width="500px" height="auto" />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={8} className={classes.cardsContainer}>
            {stats && !statsError && (
              <BulkStatsInfoCards
                stats={stats.metricsSummary}
                onShowStatsDetail={handleMetricToShow}
              />
            )}
          </Grid>
        </Grid>
      )}
    </DrawerContentLayout>
  );
};

BulkStatsDrawer.defaultProps = {
  emailId: null,
  sentDate: null
};

export default BulkStatsDrawer;
