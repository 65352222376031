// @flow
import React from 'react';
import InfoLabelsGrid from 'UI/components/organisms/inventoryProfiles/InfoLabelsGrid';
import InventoryOverviewLayout from 'UI/components/organisms/inventoryProfiles/InventoryOverviewLayout';
import { AutoWidthGrid } from 'UI/constants/dimensions';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags, nestTernary, phoneNumberFormatter } from 'UI/utils';
import { buildLocationInfoLabel, buildPilLabels } from 'UI/utils/inventory';

import { getPhonesLabelsForProfile } from '../utils';

type CompanyOverviewProps = {
  profile?: Object,
  loading: boolean
};

const getInfoLabelsForProfile = profile => {
  const pilLabels =
    profile &&
    buildPilLabels({
      specialty: profile?.specialty,
      additionalSpecialties: profile?.specialties,
      subspecialty: profile?.subspecialty,
      additionalSubspecialties: profile?.subspecialties
    });

  const legacyPhoneLabels = [
    {
      title: 'Phone · EXT',
      description: ` ext: ${profile?.contact?.ext ?? ''} `,
      mode: 'ringCentral',
      linkProps: {
        url: phoneNumberFormatter(profile?.contact?.phone) ?? '',
        id: profile?.id,
        role: EntityType.Company
      }
    }
  ];

  const isMultiplePhonesEnabled = getFeatureFlags().includes(FeatureFlags.CompaniesMultiplePhones);
  const hasMultiplePhones = !!profile.phone_id;

  const phonesLabels = !isMultiplePhonesEnabled
    ? legacyPhoneLabels
    : nestTernary(
        hasMultiplePhones,
        getPhonesLabelsForProfile(profile, EntityType.Company),
        legacyPhoneLabels
      );

  const labels = [
    {
      title: 'Legal Name',
      description: profile?.legal_name ?? ''
    },
    {
      title: 'Industries',
      description: pilLabels?.industries
    },
    {
      title: 'Specialties',
      description: pilLabels?.specialties
    },
    {
      title: 'Subspecialties',
      description: pilLabels?.subspecialties
    },
    buildLocationInfoLabel({
      city: profile?.address?.city?.title,
      state: profile?.address?.city?.state?.slug,
      country: profile?.address?.city?.state?.country?.slug
    }),
    {
      title: 'Address',
      description: profile?.address?.address
    },
    {
      title: 'ZIP CODE',
      description: profile?.address?.zip
    },
    {
      title: 'Email',
      mode: 'externalLink',
      href: `mailto:${profile?.email}`,
      linkProps: {
        url: profile?.email ?? ''
      },
      config: AutoWidthGrid
    },
    {
      title: 'Website',
      description: profile?.website,
      mode: 'externalLink',
      linkProps: {
        url: profile?.website ?? ''
      },
      config: AutoWidthGrid
    },
    {
      title: 'LinkedIn Url',
      mode: 'externalLink',
      linkProps: {
        url: profile?.link_profile ?? ''
      }
    },
    {
      title: 'Accounts Payable Email',
      mode: 'externalLink',
      href: `mailto:${profile?.accounts_payable_email}`,
      linkProps: {
        url: profile?.accounts_payable_email ?? '',
        isLinkAnEmail: true
      },
      config: AutoWidthGrid
    },
    ...phonesLabels
  ];

  return labels;
};

const CompanyOverview = ({ loading, profile }: CompanyOverviewProps) => {
  const generalLabels = getInfoLabelsForProfile(profile);

  const cards = [
    {
      cardProps: { headerProps: { title: 'General Information' } },
      Component: InfoLabelsGrid,
      componentProps: { labels: generalLabels },
      className: 'generalCard'
    }
  ];

  return <InventoryOverviewLayout loading={loading} items={cards} />;
};

CompanyOverview.defaultProps = {
  profile: {}
};

export default CompanyOverview;
