// @flow
import React from 'react';
import { Typography } from '@material-ui/core';
import FPMap from 'UI/components/atoms/FPMap';
import { usCenterCoordinates } from 'UI/constants/defaults';

import { CountriesContainer, MapContainer, PILMapContainer } from './PILMap.styled';
import PILMapCountry from './PILMapCountry';

const PILMap = ({ countries, markers }) => {
  const statesCount = countries.reduce((acc, country) => {
    return acc + country.states.length;
  }, 0);

  return (
    <PILMapContainer>
      <Typography variant="overline" noWrap title="States">
        States ({statesCount})
      </Typography>
      <CountriesContainer>
        {countries.map(country => (
          <PILMapCountry key={country.name} country={country} />
        ))}
      </CountriesContainer>
      <MapContainer>
        <FPMap
          markers={markers}
          viewport={{
            latitude: usCenterCoordinates.latitude,
            longitude: usCenterCoordinates.longitude,
            zoom: 3,
            width: '100%',
            height: '100vh'
          }}
          isDigActive
          enableNavigationControls
        />
      </MapContainer>
    </PILMapContainer>
  );
};

export default PILMap;
