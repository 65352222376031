// @flow
import React from 'react';

import { StatusColor, useStyles } from './styles';

type ColorIndicatorProps = {
  size: number,
  color: string,
  onClick?: () => void,
  onMouseEnter?: () => void,
  status: string,
  style: Object,
  customClass: string
};

const ColorIndicator = ({
  color,
  size,
  status,
  style,
  customClass,
  ...rest
}: ColorIndicatorProps) => {
  const classes = useStyles({ size, backgroundColor: color || StatusColor[status] });

  return (
    <button
      aria-label="Show details"
      type="button"
      className={customClass || classes.colorIndicator}
      {...rest}
    />
  );
};

ColorIndicator.defaultProps = {
  size: 50,
  color: undefined,
  status: '',
  customClass: null,
  onClick: undefined,
  onMouseEnter: undefined,
  style: undefined
};

export default ColorIndicator;
