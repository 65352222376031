// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import type { FPMenuButtonProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { SvgVerticalMenu } from 'UI/res/icons/filled';

import { useStyles } from './styles';

const FPButtonMenu = ({
  children,
  className,
  icon,
  isIconButton,
  menuItems,
  menuProps,
  showDefaultIcon,
  text,
  title,
  ...rest
}: FPMenuButtonProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const isEmptyMenu = menuItems.every(item => !item.visible);
  const verticalMenuSize = 16;

  return (
    !isEmptyMenu && (
      <>
        {isIconButton ? (
          <FPIconButton
            data-testid="menu-icon-button"
            aria-haspopup="true"
            onClick={handleClick}
            icon={icon ?? SvgVerticalMenu}
            iconProps={!icon && { size: verticalMenuSize }}
            tooltipProps={{ placement: 'bottom', title }}
            {...rest}
          >
            {children}
          </FPIconButton>
        ) : (
          <FPActionButton
            text={text}
            icon={icon || (showDefaultIcon && SvgVerticalMenu)}
            aria-haspopup="true"
            disabled={isEmptyMenu}
            onClick={handleClick}
            className={clsx(classes.startIcon, className)}
            {...rest}
          >
            {children}
          </FPActionButton>
        )}
        {!isEmptyMenu && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            onClose={handleClose}
            {...menuProps}
          >
            {menuItems.map(
              item =>
                item.visible && (
                  <MenuItem
                    key={item.title}
                    aria-label={item.title}
                    className={clsx(
                      classes.root,
                      item.wrapper === 'a' && classes.communicationAction,
                      item.isDisabled && classes.disabledItem
                    )}
                    component={item.wrapper || 'li'}
                    href={item?.contactData?.href}
                    onClick={e => {
                      handleClose();
                      item.link ? history.push(item.link) : item.action && item.action(e);
                      item?.contactData?.onPhoneClick && item.contactData.onPhoneClick();
                    }}
                  >
                    {item.icon && <div className={classes.iconMargin}>{item.icon}</div>}
                    {item.title}
                  </MenuItem>
                )
            )}
          </Menu>
        )}
      </>
    )
  );
};

FPButtonMenu.defaultProps = {
  className: undefined,
  icon: undefined,
  isIconButton: false,
  menuItems: [],
  menuProps: {},
  showDefaultIcon: undefined,
  text: '',
  title: 'More options'
};

export default FPButtonMenu;
