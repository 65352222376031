import { EntityRoutes } from 'routes/constants';
import { DateFormats } from 'UI/constants/defaults';

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'full_name',
    label: 'Full Name',
    options: {
      sort: true,
      display: true,
      renderer: 'bold'
    }
  },
  {
    name: 'initials',
    label: 'Initials',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'manager',
    label: 'Manager',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'position',
    label: 'Position',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  {
    name: 'extension',
    label: 'Extension',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'location',
    label: 'Office Location',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'state',
    label: 'Office State',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'city',
    label: 'Office City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'industries',
    label: 'Industries',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialties',
    label: 'Specialties',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'subspecialties',
    label: 'Subspecialties',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'pilStates',
    label: 'PIL States',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'channel_partners',
    label: 'Channel Partners',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'department',
    label: 'Department',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'external_employee_id',
    label: 'Employee ID',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'legal_name',
    label: 'Legal Name',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'roles',
    label: 'Role',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true
    }
  },
  {
    name: 'start_date',
    label: 'Hire Date',
    options: {
      sort: true,
      display: false,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: DateFormats.SimpleDate
        }
      }
    }
  },
  {
    name: 'term_date',
    label: 'Term Date',
    options: {
      sort: true,
      display: false,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: DateFormats.SimpleDate
        }
      }
    }
  },
  {
    name: 'timezone',
    label: 'Time Zone',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'coach',
    label: 'Team',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'user_city',
    label: 'City',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'user_state',
    label: 'State',
    options: {
      sort: true,
      display: true
    }
  }
];

export const FiltersGroups = [
  {
    id: 'recruiter',
    name: 'Recruiter',
    filters: ['userStatus', 'userRoles']
  },
  {
    id: 'team',
    name: 'Team',
    filters: ['coaches', 'managers', 'channelPartners', 'departments']
  },
  {
    id: 'industries',
    name: 'PIL',
    filters: ['industries', 'specialties', 'subspecialties', 'pilStates']
  },
  { id: 'location', name: 'Location', filters: ['offices', 'userStates', 'userCities'] }
];

export const RosterSortColumnBySection = {
  0: 'start_date',
  1: 'term_date'
};

export const RosterPeriodColumnBySection = {
  ...RosterSortColumnBySection
};

export const HireDateFilter = { id: 0, title: 'Hire Date', column: 'start_date' };
export const TermDateFilter = { id: 1, title: 'Term Date', column: 'term_date' };

export const RosterDateFilters = [HireDateFilter, TermDateFilter];

export const ROLES_NAME = 'userRole';

export const ROLES_COLUMN = {
  name: ROLES_NAME,
  label: 'Full Name',
  options: {
    sort: true,
    display: true,
    renderer: {
      id: 'conditionalLinkBuilder',
      config: {
        route: EntityRoutes.RecruiterProfile,
        conditionalValue: 'roles',
        valueToCompare: 'Recruiter',
        textKey: 'full_name',
        idKey: 'id'
      }
    }
  }
};
