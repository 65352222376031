import { addRingCentralContactToStore, buildMultiplePhonesActionsRenderer } from 'hooks/datatable';
import { EntityType } from 'UI/constants/entityTypes';

export const createPhonesColumns = (finalColumns, dispatch) => {
  const handleCandidateAction = ({ rowData, origin }) => {
    addRingCentralContactToStore({
      rowData,
      columns: finalColumns,
      dispatch,
      role: EntityType.Candidate,
      origin
    });
  };

  const handleHiringAuthorityAction = ({ rowData, origin }) => {
    addRingCentralContactToStore({
      rowData,
      columns: finalColumns,
      dispatch,
      role: EntityType.HiringAuthority,
      origin
    });
  };

  const candidatePhonesColumn = buildMultiplePhonesActionsRenderer({
    customName: 'candidatePhones',
    handleAction: handleCandidateAction,
    label: 'Candidate Phones',
    phonesKey: 'candidatePhones',
    phoneKey: 'candidatePhone'
  });

  const hiringAuthorityPhonesColumn = buildMultiplePhonesActionsRenderer({
    customName: 'hiringAuthorityPhones',
    handleAction: handleHiringAuthorityAction,
    label: 'Hiring A. Phones',
    phonesKey: 'hiringAuthorityPhones',
    phoneKey: 'hiringAuthorityPhone'
  });

  return [
    { column: candidatePhonesColumn, afterColumn: 'candidateName' },
    { column: hiringAuthorityPhonesColumn, afterColumn: 'hiringAuthorityName' }
  ];
};
