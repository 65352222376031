import Divider from '@material-ui/core/Divider';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const TitleHeadline = styled(Typography)(() => ({
  fontFamily: '"Kanit", sans-serif',
  fontWeight: '600 !important',
  fontSize: 20,
  textTransform: 'capitalize',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 0)
}));

export const SummaryCardContainer = styled('div')(({ theme }) => ({
  '& .MuiCardContent-root': {
    paddingTop: theme.spacing(1),
    gap: 0
  },
  '& .MuiPaper-root.MuiCard-root:hover': {
    boxShadow:
      'rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px'
  }
}));
