// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useDrawer } from 'hooks/drawerHandler';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { useProfile } from 'hooks/profileActions';
import { useSearchProjectInProfiles } from 'hooks/searchProject';
import difference from 'lodash/difference';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import { userHasRole } from 'services/Authorization';
import { CompanyIncludes } from 'services/includes';
import CompanyEdit from 'UI/components/organisms/CompanyEdit';
import CompanyForm from 'UI/components/organisms/CompanyForm';
import { FormFieldsMap, FormFieldsMapKeys } from 'UI/components/organisms/CompanyForm/fields';
import CompanySignStatusForm from 'UI/components/organisms/CompanySignStatusForm';
import { noContactStatusChipPreset } from 'UI/components/organisms/inventoryProfiles/commons';
import { getSendoutActions } from 'UI/components/organisms/inventoryProfiles/SendoutsTab/sendoutsActions';
import { handleEntityEditionCompleted } from 'UI/components/organisms/inventoryProfiles/utils';
import ReassignRecruiterForm from 'UI/components/organisms/ReassignRecruiter';
import SendoutManager from 'UI/components/organisms/sendouts';
import InventoryProfileLayout from 'UI/components/templates/InventoryProfileLayout';
import ProfileStatusWrapper from 'UI/components/templates/ProfileStatusWrapper';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  CompanyEntity,
  EntityType,
  entityTypes,
  SearchProjectEntities,
  TabKeys
} from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { hasFeatureFlag, localTimeFormatter } from 'UI/utils';
import { updateUiState } from 'UI/utils/uiHelpers';

const DO_NOT_CONTACT_STATUS = 4;

type CompanyProfileProps = {
  enableUrlNavigation: boolean,
  layoutClassName: Object,
  match: any,
  profileId: number
};

const createTabsProps = ({
  companyId,
  endpoint,
  handleRefreshActivityStatus,
  handleSendoutClick,
  isLoading,
  itemName,
  results,
  searchProjectsProps,
  triggerTabRefresh,
  uiState
}) => [
  {
    id: TabKeys.JobOrders,
    tabProps: {
      profileId: results?.id,
      isLoading,
      itemName,
      mode: EntityType.Company
    }
  },
  {
    id: TabKeys.Sendouts,
    tabProps: {
      profile: results,
      profileModule: EntityType.Company,
      onSendoutClick: handleSendoutClick,
      shouldRefresh: uiState.shouldRefresh
    }
  },
  {
    id: TabKeys.Placements,
    tabProps: {
      profileId: companyId,
      profileModule: EntityType.Company,
      onTriggerTabRefresh: triggerTabRefresh,
      isLoading
    }
  },
  {
    id: TabKeys.FeeAgreements,
    tabProps: {
      search: false,
      profile: results,
      companyId,
      profileModule: EntityType.Company,
      endpoint
    }
  },
  {
    id: TabKeys.HiringAuthorities,
    tabProps: {
      profile: results,
      isCompanyProfile: true
    }
  },
  {
    id: TabKeys.AssociatedCompanies,
    tabProps: {
      profileId: companyId,
      itemName,
      accountableId: results?.recruiter?.id
    }
  },
  searchProjectsProps,
  {
    id: TabKeys.Employees,
    tabProps: {
      profile: results
    }
  },
  {
    id: TabKeys.ActivityLogs,
    tabProps: {
      itemName,
      onActivityComplete: handleRefreshActivityStatus,
      profileId: results?.id,
      profileModule: EntityType.Company,
      endpoint
    }
  },
  {
    id: TabKeys.Notes,
    tabProps: {
      onActivityComplete: handleRefreshActivityStatus,
      type: 'notes',
      profileId: results?.id,
      itemName,
      search: false,
      endpoint,
      profileModule: EntityType.Company
    }
  }
];

const DEFAULT_FIELDS_WHEN_EDITING_IMPORTED_COMPANIES = [
  FormFieldsMap.Specialty.key,
  FormFieldsMap.Specialties.key,
  FormFieldsMap.Subspecialty.key,
  FormFieldsMap.Subspecialties.key,
  FormFieldsMap.State.key,
  FormFieldsMap.City.key,
  FormFieldsMap.Zip.key,
  FormFieldsMap.Address.key,
  FormFieldsMap.Phone.key,
  FormFieldsMap.Ext.key,
  FormFieldsMap.Phones.key,
  FormFieldsMap.Website.key
];

const FPCompanyProfile = ({
  match,
  profileId,
  layoutClassName,
  enableUrlNavigation,
  onEditionCompleted
}: CompanyProfileProps) => {
  const companyId = profileId ?? match.params.id;

  const location = useLocation();

  const endpoint = `${Endpoints.Companies}/${companyId}`;
  const entityType = entityTypes.find(each => each.id === EntityType.Company);
  const history = useHistory();
  const isChangeLogsTabEnabled = hasFeatureFlag(FeatureFlags.ChangeLogCompany);

  const editionDrawer = useDrawer(location, history);
  const [tabKeys, setTabKeys] = useState({
    [TabKeys.SearchProjects]: 0
  });

  const {
    handleEditClick,
    handleEditClosed,
    handleEditCompleted,
    handleProfileStateUpdate,
    handleReassignClick,
    handleReassignClosed,
    handleReassignCompleted,
    isLoading,
    refreshData,
    results,
    setUiState,
    state,
    status,
    uiState
  } = useProfile({
    entity: EntityType.Company,
    profileId: companyId,
    includes: CompanyIncludes,
    shouldLoadTitle: enableUrlNavigation
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   R O L E S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const recruiter = results?.recruiter ?? {};
  const {
    state: { results: recruiterRoles }
  } = useFetchWithStatus(recruiter?.id ? Endpoints.UserRoles.replace(':id', recruiter.id) : '');

  const isAssignedUserFromDataCoordination = (recruiterRoles || []).some(
    ({ id }) => id === Roles.DataCoordinator
  );

  const isCurrentUserFromDataCoordination = userHasRole(Roles.DataCoordinator);
  const shouldEditBasicData =
    !isCurrentUserFromDataCoordination && isAssignedUserFromDataCoordination;

  const disabledEditKeys = shouldEditBasicData
    ? difference(FormFieldsMapKeys, DEFAULT_FIELDS_WHEN_EDITING_IMPORTED_COMPANIES)
    : [];

  const itemName = results?.name ?? '';
  const lastActivity = results?.last_activity_date;

  const handleRefreshActivityStatus = data =>
    handleProfileStateUpdate({ last_activity_date: data?.updated_at });
  const formattedDate = localTimeFormatter(lastActivity, DateFormats.SimpleDateTime);

  const triggerTabRefresh = (nameTab: string) =>
    setTabKeys(prevState => ({ ...prevState, [nameTab]: new Date().getTime() }));

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E N D O U T S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const {
    handleSendoutClick,
    handleSendoutClosed,
    handleSendoutCompleted,
    handleSendoutNavigation
  } = getSendoutActions({ setUiState, history, triggerTabRefresh });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E A R C H   P R O J E C T S   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const { SearchProjectForms, SearchProjectsMenuItems, searchProjectTabPropsDefinition } =
    useSearchProjectInProfiles({
      entity: SearchProjectEntities.Company,
      profile: {
        id: results?.id,
        name: itemName
      },
      tabKey: tabKeys.searchProjects,
      triggerTabRefresh,
      endpoint,
      shouldRedirectToSearchProjectPreview: enableUrlNavigation
    });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C O M P A N Y   S T A T U S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const [verifyType, setVerifyType] = useState({
    followUpId: null,
    opsVerification: null
  });

  const reassureCompany = results?.companyTypeReassure;
  const dialogProps = reassureCompany?.shouldAskRecruiter
    ? {}
    : {
        showCloseButton: true,
        mustComplete: false,
        isOpened: uiState.companyTypeOpen,
        onClose: () => updateUiState({ companyTypeOpen: false }, setUiState)
      };

  const handleProfileUpdate = updatedProfile => {
    if (updatedProfile?.files?.length > 0)
      handleProfileStateUpdate({ files: [...updatedProfile.files] });
  };

  useEffect(() => {
    const { followupid, showopsverificationmodal: showModal } = queryString.parse(location?.search);
    if ((verifyType.opsVerification && verifyType.followUpId) || !followupid || !showModal) return;
    setVerifyType({
      followUpId: followupid,
      opsVerification: showModal
    });
    updateUiState({ opsVerificationOpen: showModal === 'true' }, setUiState);
  }, [location.search, verifyType, setUiState]);

  const handleVerifyClose = useCallback(() => {
    history.replace(location.pathname);
    setVerifyType({ followUpId: null, opsVerification: null });
  }, [history, location.pathname]);

  const tabsProps = createTabsProps({
    companyId,
    endpoint,
    handleRefreshActivityStatus,
    handleSendoutClick,
    isLoading,
    itemName,
    results,
    triggerTabRefresh,
    uiState,
    searchProjectsProps: searchProjectTabPropsDefinition
  });

  const headerProps = {
    sectionName: 'Company',
    title: itemName
  };

  const customMenuItems = [
    ...SearchProjectsMenuItems,
    {
      title: 'Update Company Type',
      action: () => updateUiState({ companyTypeOpen: true }, setUiState),
      visible: reassureCompany?.recruiterCanRequest
    }
  ];

  return (
    <ProfileStatusWrapper
      status={status}
      responseStatusCode={state.responseStatusCode}
      entityRoute={EntityRoutes.Companies}
      onRefreshProfileClick={refreshData}
      entity={CompanyEntity}
      profileStatusMode={{
        loading: (
          <InventoryProfileLayout
            entityType={entityType}
            isLoading={isLoading}
            isChangeLogsTabEnabled={isChangeLogsTabEnabled}
            contentPageLayoutProps={{ className: layoutClassName }}
            enableUrlNavigation={enableUrlNavigation}
            results={results}
            ProfileTabsProps={{
              endpoint,
              profileTabProps: tabsProps
            }}
          />
        ),
        success: (
          <>
            <InventoryProfileLayout
              activityLabel={formattedDate}
              addItemLabel="Add a Collaborator"
              entityType={entityType}
              headerCardProps={headerProps}
              contentPageLayoutProps={{ className: layoutClassName }}
              inventoryType={results?.type}
              inventoryTypeChipProps={
                results?.type?.id === DO_NOT_CONTACT_STATUS && noContactStatusChipPreset(results)
              }
              isLoading={isLoading}
              isChangeLogsTabEnabled={isChangeLogsTabEnabled}
              results={results}
              profileMenuProps={{
                onReassignClick: handleReassignClick,
                onEditClick: handleEditClick,
                customMenuItems,
                isEditionCheckIgnored: shouldEditBasicData
              }}
              enableUrlNavigation={enableUrlNavigation}
              ProfileTabsProps={{
                endpoint,
                profileTabProps: tabsProps
              }}
            />
            {!isLoading && (
              <>
                {(reassureCompany?.shouldAskRecruiter || uiState.companyTypeOpen) && (
                  <CompanySignStatusForm
                    type="dialog"
                    loading={uiState.isLoading}
                    companyId={companyId}
                    company={results}
                    dialogProps={dialogProps}
                    reassure={reassureCompany?.inProgress}
                    onUpdateCompany={handleProfileUpdate}
                  />
                )}
                {verifyType.followUpId && verifyType.opsVerification && (
                  <Drawer open={uiState.opsVerificationOpen} onClose={handleVerifyClose}>
                    <div role="presentation">
                      <CompanySignStatusForm
                        type="drawer"
                        loading={uiState.isLoading}
                        company={results}
                        companyId={companyId}
                        onUpdateCompany={handleProfileUpdate}
                        followUpId={verifyType.followUpId}
                        onCloseDrawer={handleVerifyClose}
                      />
                    </div>
                  </Drawer>
                )}

                {uiState.isSendoutOpen && uiState.selectedSendoutId && (
                  <Drawer open onClose={handleSendoutClosed}>
                    <SendoutManager
                      id={uiState.selectedSendoutId}
                      onEdit={handleSendoutCompleted}
                      onDelete={handleSendoutCompleted}
                      onClose={handleSendoutClosed}
                      onNavigate={handleSendoutNavigation}
                    />
                  </Drawer>
                )}

                {uiState.isEditOpen && results && (
                  <Drawer open onClose={handleEditClosed}>
                    <div role="presentation">
                      <CompanyEdit
                        company={results}
                        onEditCompleted={handleEntityEditionCompleted({
                          handleEditCompleted,
                          enableUrlNavigation,
                          onEditionCompleted
                        })}
                        onEditClosed={handleEditClosed}
                        CompanyForm={<CompanyForm disabledEditKeys={disabledEditKeys} />}
                      />
                    </div>
                  </Drawer>
                )}
                {uiState.isReassignOpen && (
                  <Drawer
                    open={uiState.isReassignOpen}
                    onClose={editionDrawer.toggleDrawer('isReassignOpen', false)}
                  >
                    <div role="presentation">
                      <ReassignRecruiterForm
                        baseEndpoint={Endpoints.Companies}
                        item={results}
                        entityType={entityType}
                        onReassignCompleted={handleReassignCompleted}
                        onReassignClosed={handleReassignClosed}
                      />
                    </div>
                  </Drawer>
                )}
                <SearchProjectForms />
              </>
            )}
          </>
        )
      }}
    />
  );
};

FPCompanyProfile.defaultProps = {
  enableUrlNavigation: true
};

export default FPCompanyProfile;
