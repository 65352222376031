import { makeStyles } from '@material-ui/core/styles';
import { input } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

export const useStyles = makeStyles(theme => ({
  root: {
    ...theme.input,
    margin: ({ noMargin }) => (noMargin ? 'unset' : theme.spacing(1.25, 0)),
    height: 'unset',
    width: ({ width }) => width ?? '100%',
    minWidth: ({ minWidth }) => minWidth ?? 'unset',
    '& input': {
      height: 3,
      borderRadius: input.borderRadius,
      backgroundColor: ({ outPutValue }) =>
        outPutValue ? `${colors.appBackground} !important` : 'unset',
      color: ({ outPutValue }) => outPutValue && `${colors.black} !important`
    },
    '& label': {
      color: ({ outPutValue }) => outPutValue && `${colors.darkGrey} !important `,
      ...theme.input['& label']
    },
    '& fieldset': {
      ...theme.input['& fieldset'],
      borderColor: ({ outPutValue }) => outPutValue && `${colors.darkGrey} !important`
    },
    '& > div': {
      '& > textarea': {
        width: ({ rows }) => rows === 1 && '90%'
      }
    },
    '& .MuiOutlinedInput-adornedEnd': {
      borderRadius: input.borderRadius,
      backgroundColor: props => (props.outPutValue ? `${colors.appBackground} !important` : 'unset')
    }
  }
}));
