import moment from 'moment';

export const getLastUpdateEvery30Minutes = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const roundedMinutes = Math.floor(minutes / 30) * 30;
  const roundedDate = new Date(now);
  roundedDate.setMinutes(roundedMinutes);
  return moment(roundedDate).format('dddd Do, h:mm A');
};
