import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  label: {
    paddingLeft: theme.spacing(1)
  }
}));
