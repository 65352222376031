import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { styled } from '@material-ui/core/styles';
import { container } from 'UI/constants/dimensions';

export const StyledCard = styled(Card)(() => ({
  borderRadius: 12,
  boxShadow: container.boxShadow,
  height: '100%'
}));

export const StatsCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  fontFamily: '"Kanit", sans-serif',
  '& .MuiCardHeader-title.MuiTypography-h6': {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: `${theme.typography.headerWeights.bold} !important`
  },
  '& .MuiCardHeader-title': {
    color: theme.palette.grey[900]
  },
  '& .MuiCardHeader-action': {
    display: 'contents'
  },
  '& .MuiCardHeader-subheader': {
    fontFamily: 'Roboto, sans-serif',
    fontSize: theme.typography.caption.fontSize
  }
}));

export const StatsCardContent = styled(CardContent)(({ theme, padding }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'left',
  padding: padding ?? theme.spacing(3, 2)
}));

export const ImgContainer = styled(CardMedia)({
  height: '160px',
  width: '100%',
  overflow: 'hidden',
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)'
    }
  }
});
