export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    assignedTo: item.recruiter.name,
    lastActivity: item.lastActivity.title,
    lastActivityBy: item.lastActivity.userName,
    lastActivityDate: item.lastActivity.date,
    verifiedAt: item.verified.date,
    verifiedBy: item.verified.userName
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
