import React from 'react';
import { Typography } from '@material-ui/core';

import { CountryName, StatesNames } from './PILMap.styled';

const PILMapCountry = ({ country }) => {
  return (
    <Typography key={country.name}>
      <CountryName>{country.name.toUpperCase()}</CountryName>:&nbsp;
      <StatesNames>{country.states.join(', ').toUpperCase()}</StatesNames>
    </Typography>
  );
};

export default PILMapCountry;
